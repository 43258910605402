import { baseurl } from "./constant";
let baseUrl: string;
if (process.env.REACT_APP_PORT === '6001') {
    baseUrl = baseurl.DEV;
} else if (process.env.REACT_APP_PORT === '6002') {
    baseUrl = baseurl.UAT;
} else if (process.env.REACT_APP_PORT === '6000') {
    baseUrl = baseurl.PROD;
} else {
    baseUrl = baseurl.LOCAL;
}
export default baseUrl;