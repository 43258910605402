import React, { useState, useEffect } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid";
import PheonixTable from "../Components/PheonixTable";
import PheonixPaper from "../Components/PheonixPaper";
import PhoenixModal from "../Components/PheonixModal";
import { TEXT_MESSAGES } from "../constant";
import { ModalFormData } from "../Interfaces/ModalFormdataProps";
import {
  GET_BORROWERS,
  GET_BORROWER_BY_ID,
  GET_LOAN_DETAILS,
} from "../graphql/queries";
import EditIcon from "../assets/EditFilled.png";
import VisibleIcon from "../assets/visibility.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMediaQuery, useTheme } from "@mui/material";
import { ApolloError } from "@apollo/client";
import themestyle from "../theme";
import {
  ADD_BORROWER,
  EDIT_BORROWER,
  DELETE_BORROWER,
} from "../graphql/mutation";
import { useNavigate, useLocation } from "react-router-dom";
import PheonixSnackBar from "../Components/PheonixSnackBar";
import DeleteModal from "../Components/DeleteModal";
const Borrowers: React.FC = () => {
  const location = useLocation();
  let pagedetails = location.state?.page;
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isTextEditable, setIsTextEditable] = useState(false);
  const [selectedBorrowerId, setSelectedBorrowerId] = useState<string | null>(
    null
  );
  const [tableData, setTableData] = useState<ModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [title, settitle] = useState("");
  const [dataLoading, setdataLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [succMessage, setSuccMessage] = useState("");
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("BorrowerpaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });

  useEffect(() => {
    localStorage.setItem("BorrowerpaginationModel", JSON.stringify(paginationModel));
    return () => {
      localStorage.removeItem("BorrowerpaginationModel");
    };
  }, [paginationModel]);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [addBorrowerMutation] = useMutation(ADD_BORROWER);
  const [editBorrowerMutation] = useMutation(EDIT_BORROWER);
  const [deleteBorrowerMutation] = useMutation(DELETE_BORROWER);
  const navigate = useNavigate();
  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: borrowerRefetch,
  } = useQuery(GET_BORROWERS, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery || "",
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, borrower } = data.getBorrowerperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const borrowersWithSerialNo = borrower.map(
        (borrower: ModalFormData, index: number) => ({
          ...borrower,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(borrowersWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });
  useEffect(() => {
    if (pagedetails) {
      setPaginationModel({
        pageSize: pagedetails.pageSize,
        page: pagedetails.page,
      });
    }
  }, [pagedetails]);
  useEffect(() => {
    setdataLoading(true);
    if (!customerLoading && !customerError && customerData) {
      const { totalPages, totalCount, borrower } =
        customerData.getBorrowerperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const borrowersWithSerialNo = borrower.map(
        (borrower: ModalFormData, index: number) => ({
          ...borrower,
          serial_no: (startIndex + index).toString(),
        })
      );

      setTableData(borrowersWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    customerData,
    customerLoading,
    customerError,
    paginationModel.page,
    paginationModel.pageSize,
    searchQuery,
  ]);
  useEffect(() => {
    borrowerRefetch();
  }, [borrowerRefetch]);
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel:any) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const [borrowerFormData1, setBorrowerFormData1] = useState<ModalFormData>({
    "Borrower Name": "",
    Occupation: "",
    "Primary Number": "",
    "Secondary Number": "",
    "Address 1": "",
    "Address 2": "",
    Remarks: "",
  });
  const handleOpenModal = (selectedRowData: ModalFormData | null = null) => {
    setModalOpen(true);
    setFieldErrors({});
    if (selectedRowData) {
      setSelectedBorrowerId(selectedRowData.id);
      setIsEditing(true);
      setIsTextEditable(false);
      setBorrowerFormData1({
        "Borrower Name": selectedRowData.borrower_name,
        Occupation: selectedRowData.occupation,
        "Primary Number": selectedRowData.phone1,
        "Secondary Number": selectedRowData.phone2,
        "Address 1": selectedRowData.address1,
        "Address 2": selectedRowData.address2,
        Remarks: selectedRowData.remarks,
      });
      settitle(TEXT_MESSAGES.EDIT_BORROWER_title);
    } else {
      setSelectedBorrowerId(null);
      setIsEditing(false);
      setIsTextEditable(false);
      setBorrowerFormData1({
        "Borrower Name": "",
        Occupation: "",
        "Primary Number": "",
        "Secondary Number": "",
        "Address 1": "",
        "Address 2": "",
        Remarks: "",
      });
      settitle(TEXT_MESSAGES.ADD_BORROWER_title);
    }
  };
  const handleViewModal = (selectedRowData: ModalFormData | null = null) => {
    setModalOpen(true);
    setFieldErrors({});
    if (selectedRowData) {
      setSelectedBorrowerId(selectedRowData.id);
      setIsTextEditable(true);
      setBorrowerFormData1({
        "Borrower Name": selectedRowData.borrower_name,
        Occupation: selectedRowData.occupation,
        "Primary Number": selectedRowData.phone1,
        "Secondary Number": selectedRowData.phone2,
        "Address 1": selectedRowData.address1,
        "Address 2": selectedRowData.address2,
        Remarks: selectedRowData.remarks,
      });

      settitle(TEXT_MESSAGES.VIEW_BORROWER_TITLE);
    }
  };
  const handleCloseModal = () => {
    setFieldErrors({});
    setBorrowerFormData1({
      "Borrower Name": "",
      Occupation: "",
      "Primary Number": "",
      "Secondary Number": "",
      "Address 1": "",
      "Address 2": "",
      Remarks: "",
    });
    setModalOpen(false);
  };
  const handleAdd = async () => {
    setFieldErrors({});
    const phone1Int = parseInt(borrowerFormData1["Primary Number"], 10);
    const phone2Int = parseInt(borrowerFormData1["Secondary Number"], 10);
    const borrowerInput = {
      borrower_name: borrowerFormData1["Borrower Name"],
      occupation: borrowerFormData1.Occupation,
      phone1: borrowerFormData1["Primary Number"],
      phone2: borrowerFormData1["Secondary Number"],
      address1: borrowerFormData1["Address 1"],
      address2: borrowerFormData1["Address 2"],
      remarks: borrowerFormData1["Remarks"],
    };

    try {
      if (isEditing && selectedBorrowerId) {
        await editBorrowerMutation({
          variables: {
            id: selectedBorrowerId,
            ...borrowerInput,
          },
        });
        setSnackbarMessage(TEXT_MESSAGES.BORROWERUPDATE);
        setSnackbarSeverity("success");
        await getBorrowerDetails({ variables: { id: selectedBorrowerId } });
      } else {
        await addBorrowerMutation({
          variables: borrowerInput,
        });
        setSnackbarMessage(TEXT_MESSAGES.BORROWERCREATE);
        setSnackbarSeverity("success");
      }

      borrowerRefetch();
      setSnackbarOpen(true);
      handleCloseModal();
    } catch (error: any) {
      const apiErrorMessage =
        error?.graphQLErrors?.[0]?.message || TEXT_MESSAGES.ADD_EDIT_ERROR;
      setSnackbarMessage(apiErrorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error(error);
    }
  };

  const fields = [
    { label: "Borrower Name", type: "text" },
    { label: "Occupation", type: "text" },
    { label: "Primary Number", type: "text" },
    { label: "Secondary Number", type: "text" },
    { label: "Address 1", type: "text" },
    { label: "Address 2", type: "text" },
    { label: "Remarks", type: "text" },
  ];
  const requiredFields = [
    "Borrower Name",
    "Occupation",
    "Primary Number",
  ] as (keyof ModalFormData)[];
  const columns: GridColDef[] = [
    { field: "serial_no", headerName: "S.No", width: 100 },
    {
      field: "borrower_id",
      headerName: "Borrower ID",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Typography
          variant="body2"
          color="primary"
          style={{
            cursor: "pointer",
            textAlign: "start",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            height: "100%",
            textDecoration: "underline",
          }}
          onClick={() => handleViewDetails(params.row.id)}
        >
          {params.value}
        </Typography>
      ),
    },
    { field: "borrower_name", headerName: "Name", flex: 1, minWidth: 250 },
    { field: "phone1", headerName: "Primary Number", flex: 1, minWidth: 250 },
    {
      field: "phone2",
      headerName: "Secondary Number",
      flex: 1,
      minWidth: 250,
    },
    { field: "address1", headerName: "Address1", flex: 2, minWidth: 250 },

    { field: "address2", headerName: "Address2", flex: 2, minWidth: 250 },
    { field: "occupation", headerName: "Occupation", flex: 1, minWidth: 250 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginLeft: "-8px",
          }}
        >
          <IconButton onClick={() => handleViewModal(params.row)}>
            <img
              src={VisibleIcon}
              alt="View"
              style={{ width: "20px", height: "20px", marginTop: "7px" }}
            />
          </IconButton>
          <IconButton onClick={() => handleOpenModal(params.row)}>
            <img src={EditIcon} alt="Edit" style={{ marginTop: "7px" }} />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(params.row.id, params.row.borrower_id)}
          >
            <DeleteIcon
              style={{
                width: "20px",
                height: "20px",
                color: themestyle.colors.primary,
                marginTop: "7px",
              }}
            />
          </IconButton>
        </div>
      ),
      align: "center",
    },
  ];
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");
  const handleDelete = (borrowerId: string, collectionNo: string) => {
    setEntityToDelete(borrowerId);
    setEntityToDisplay(collectionNo);
    setDeleteModalOpen(true);
  };
  const [selectedBorrower, setSelectedBorrower] =
    useState<ModalFormData | null>(null);
  const [getBorrowerDetails, { loading: detailsLoading, data: detailsData }] =
    useLazyQuery(GET_BORROWER_BY_ID);
  const [
    getLoanDetails,
    { loading: loandetailsLoading, data: loandetailsData },
  ] = useLazyQuery(GET_LOAN_DETAILS);
  const handleViewDetails = async (id: string) => {
    try {
      const response = await getBorrowerDetails({
        variables: { id: id },
        fetchPolicy: "network-only",
      });

      if (response.data && response.data.getBorrowerDetails) {
        const borrowerDetails =
          response.data.getBorrowerDetails.borrowerDetails.borrower;
        const totalDetails =
          response.data.getBorrowerDetails.borrowerDetails.totals;
        setSelectedBorrower(borrowerDetails);
        navigate("/borrowerdetails", {
          state: {
            borrower: borrowerDetails,
            totalDetails: totalDetails,
            paginationModel,
          },
        });
      }
    } catch (err) {
      console.error("Error fetching depositor details:", err);
    }
  };
  const confirmDelete = async () => {
    if (!entityToDelete) return;
    try {
      const result = await deleteBorrowerMutation({
        variables: {
          _id: entityToDelete,
        },
      });
      if (result && result.data && result.data.deleteBorrower) {
        setSnackbarMessage(TEXT_MESSAGES.DELETE_SUCCESS_borrower);
        setSnackbarSeverity("error");
        borrowerRefetch();
      }
    } catch (error: any) {
      setSnackbarMessage(error.message || TEXT_MESSAGES.DELETE_ERROR1);
      setSnackbarSeverity("error");
      console.error("Error during deletion:", error);
    } finally {
      setSnackbarOpen(true);
      setDeleteModalOpen(false);
      setEntityToDelete(null);
      borrowerRefetch();
    }
  };
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      borrowerRefetch({ variables: { searchQuery: {} } });
    } else {
      borrowerRefetch();
    }
  };

  return (
    <div>
      <Box style={{ padding: "15px" }}>
        <PheonixSnackBar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
          vertical="top"
          horizontal="center"
        />
        {isWideScreen ? (
          <PheonixPaper
            style={{
              borderRadius: "20px",
              height: "70px",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "center",
                padding: isMobileSmall ? "15px 0" : "15px 30px",
              }}
            >
              <Typography
                style={{
                  color: themestyle.colors.black,
                  fontWeight: themestyle.fontWeight.sm,
                  fontSize: isMobileSmall
                    ? themestyle.fontSizes.sm
                    : themestyle.fontSizes.medium,
                }}
              >
                {TEXT_MESSAGES.BORROWER}
              </Typography>
              <Button
                style={{
                  background: themestyle.colors.primary,
                  color: themestyle.colors.white,
                  width: "160px",
                }}
                onClick={() => handleOpenModal(null)}
              >
                {TEXT_MESSAGES.ADD_BORROWER}
              </Button>
            </div>
          </PheonixPaper>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color: themestyle.colors.black,
                marginRight: "10px",
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {TEXT_MESSAGES.BORROWER}
            </Typography>
            <Button
              style={{
                background: themestyle.colors.primary,
                color: themestyle.colors.white,
                width: isWideScreen ? "142px" : "160px",
              }}
              onClick={() => handleOpenModal(null)}
            >
              {TEXT_MESSAGES.ADD_BORROWER}
            </Button>
          </div>
        )}
        <PheonixPaper style={{ borderRadius: "20px" }}>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <PheonixTable
              columns={columns}
              rows={tableData}
              rowCount={tableCount}
              paginationMode="server"
              paginationModel={paginationModel}
              loading={dataLoading}
              onPaginationModelChange={handlePaginationChange}
              searchQuery={searchQuery}
              onSearchQueryChange={handleSearchQueryChange}
            />
          </div>
        </PheonixPaper>
        <PhoenixModal
          open={modalOpen}
          onClose={handleCloseModal}
          onAdd={handleAdd}
          title={title}
          fields={fields}
          formData={borrowerFormData1}
          setFormData={setBorrowerFormData1}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
          requiredFields={requiredFields}
          context="Borrower"
          editable={isTextEditable}
        />
        <DeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          entity={entityToDisplay}
          entityname="Borrower"
        />
      </Box>
    </div>
  );
};

export default Borrowers;
