import React from 'react';
import Sidebar from './Sidebar';
import AppBar from '../Components/PheonixAppBar';
import LayoutProps from "../Interfaces/LayoutProps";
import PheonixFooter from '../Components/PheonixFooter';
import { useSidebar } from './SidebarContext';

const Layout: React.FC<LayoutProps> = ({ title, children }) => {
  const { isOpen, toggleSidebar } = useSidebar();
  return (
    <div>
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar title={title} isSidebarOpen={isOpen} />
      <div style={{ display: 'flex', flexGrow: 1,marginBottom:"40px" }}>
        <Sidebar isOpen={isOpen} onToggle={toggleSidebar} />
        <div style={{ flexGrow: 1, overflow: 'auto', backgroundColor: '#F2FFF9' }}>
          {children}
        </div>
      </div>
     
    </div>
    <PheonixFooter/>
    </div>
  );
};
export default Layout;