import React, { useState, useEffect } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid";
import PheonixTable from "../Components/PheonixTable";
import PheonixPaper from "../Components/PheonixPaper";
import PhoenixModal from "../Components/PheonixModal";
import { TEXT_MESSAGES, Payment_msg } from "../constant";
import { GET_PAYMENTS, GET_ALL_CATEGORIES,GET_ALL_DEPOSITORS } from "../graphql/queries";
import EditIcon from "../assets/EditFilled.png";
import VisibleIcon from "../assets/visibility.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMediaQuery, useTheme } from "@mui/material";
import themestyle from "../theme";
import { ApolloError } from '@apollo/client';
import {
  ADD_PAYMENT,
  EDIT_PAYMENT,
  DELETE_PAYMENT,
} from "../graphql/mutation";
import AddPaymentModal from "../Components/AddPaymentmodal";
import { PaymentModalFormData } from "../Interfaces/PaymentModalProps";
import PheonixSnackBar from "../Components/PheonixSnackBar";
import DeleteModal from "../Components/DeleteModal";

const Payment: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isTextEditable, setIsTextEditable] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState<string | null>(null);
  const [tableData, setTableData] = useState<PaymentModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [title, setTitle] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");


  const [entityToDisplay, setEntityToDisplay] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("error");
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("PaymentpaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });

  useEffect(() => {
    localStorage.setItem("PaymentpaginationModel", JSON.stringify(paginationModel));
    return () => {
      localStorage.removeItem("PaymentpaginationModel");
    };
  }, [paginationModel]);
  
  const [showCategoryInput, setShowCategoryInput] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [addPaymentMutation] = useMutation(ADD_PAYMENT);
  const [editPaymentMutation] = useMutation(EDIT_PAYMENT);
  const [deletePaymentMutation] = useMutation(DELETE_PAYMENT);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { loading: paymentLoading, error: paymentError, data: paymentData, refetch: paymentRefetch } = useQuery(GET_PAYMENTS, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery || ""
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, payment } = data.getPaymentperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const paymentsWithSerialNo = payment.map((payment: PaymentModalFormData, index: number) => ({
        ...payment,
        serial_no: (startIndex + index).toString(),
      }));
      setTableData(paymentsWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });
  const { loading: depositorLoading, error: depositorError, data: depositorData, refetch: depositorRefetch } = useQuery(GET_ALL_DEPOSITORS);
  const { loading: categoryLoading, error: categoryError, data: categoryData, refetch: categoryRefetch } = useQuery(GET_ALL_CATEGORIES);

  useEffect(() => {
    setDataLoading(true);
    if (!paymentLoading && !paymentError && paymentData) {
      const { totalPages, totalCount, payment } = paymentData.getPaymentperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const paymentsWithSerialNo = payment.map((payment: PaymentModalFormData, index: number) => ({
        ...payment,
        serial_no: (startIndex + index).toString(),
      }));

      setTableData(paymentsWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setDataLoading(false);
    }
  }, [paymentData, paymentLoading, paymentError, paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    paymentRefetch();
    categoryRefetch();
    depositorRefetch();

  }, [paymentRefetch, categoryRefetch,depositorRefetch]);

  const [fields, setFields] = useState([
    { label: "Category", type: "text" },
    { label: "Sub Category", type: "text" },
    { label: "Depositor Name", type: "text" },
    { label: "Amount", type: "text" },
    { label: "Remarks", type: "text" },
  ]);


  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel:any) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const [paymentFormData1, setPaymentFormData1] = useState<PaymentModalFormData>({
    "Category": "",
    "Sub Category Name": "",
    "Sub Category": "",
    "Depositor Name":"",
    "Amount": "",
    "Remarks": "",
  });

  const handleOpenModal = (selectedRowData: PaymentModalFormData | null = null) => {
    setModalOpen(true);
    setFieldErrors({});
    if (selectedRowData) {
      setSelectedPaymentId(selectedRowData.id);
      setIsEditing(true);
      setIsTextEditable(false);
      setPaymentFormData1({
        "Category": selectedRowData.category_name,
        "Sub Category": selectedRowData.sub_category ,
        "Sub Category Name": "",
        "Depositor Name":selectedRowData.depositor_id,
        "Amount": selectedRowData.amount,
        "Remarks": selectedRowData.remarks,
      });
      setTitle(Payment_msg.EDIT_PAYMENT);
    } else {
      setSelectedPaymentId(null);
      setIsEditing(false);
      setIsTextEditable(false);
      setPaymentFormData1({
        "Category":"",
        "Sub Category Name": "",
        "Sub Category": "",
        "Depositor Name":"",
        "Amount": "",
        "Remarks": ""
      });
      setTitle(Payment_msg.MODAL_TITLE);
    }
  };

  const handleCloseModal = () => {
    setFieldErrors({});
    setShowCategoryInput(false)
    setPaymentFormData1({
      "Category": "",
      "Sub Category Name": "",
      "Sub Category": "",
      "Depositor Name":"",
      "Amount": "",
      "Remarks": ""
    });
    setModalOpen(false);
  };
  const handleDeleteModal = (selectedRowData: PaymentModalFormData | null = null) => {
    setDeleteModalOpen(true);
    if (selectedRowData) {
      setEntityToDelete(selectedRowData.id);
      setEntityToDisplay(selectedRowData.payment_id );
    } else {
      setEntityToDelete(null);
      setEntityToDisplay("");
    }
  };
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      paymentRefetch({ variables: { searchQuery: {} } }); 
    } else {
      paymentRefetch();
    }
  };
  const handleDeleteEntity = async () => {
    try {
      await deletePaymentMutation({
        variables: {
          id: entityToDelete,
        },
      });
      setDeleteModalOpen(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(Payment_msg.DELETE_SUCCESS_receipt);
      paymentRefetch()
    } catch (error) {
      console.error("Error deleting receipt:", error);
      setDeleteModalOpen(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(Payment_msg.FAILED_PAYMENT);
    }
  };
  function formattedDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const currentDate = formattedDate(new Date());
  const handleAdd = async () => {
    setFieldErrors({});
    const amount = parseFloat(paymentFormData1["Amount"]);
    if (isNaN(amount)) {
      setSnackbarMessage(Payment_msg.INVALID);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const paymentInput = {
      category_name: paymentFormData1["Category"],
      sub_category: paymentFormData1["Sub Category"],
      sub_category_name: paymentFormData1["Sub Category Name"] || null ,
      depositor_id: paymentFormData1["Depositor Name"] ,
      date: currentDate,
      amount: amount,
      remarks: paymentFormData1["Remarks"],
    };

    try {
      if (isEditing && selectedPaymentId) {
        await editPaymentMutation({
          variables: {
            id: selectedPaymentId,
            ...paymentInput,
          },
        });
        setSnackbarMessage(Payment_msg.PAYMENTUPDATE);
        setSnackbarSeverity("success");

        setShowCategoryInput(false);
      } else {
        await addPaymentMutation({
          variables: paymentInput,
        });
        setSnackbarMessage(Payment_msg.PAYMENTCREATE);
        setSnackbarSeverity("success");
      }
      paymentRefetch();
      categoryRefetch();
      depositorRefetch();
      setSnackbarOpen(true);
      handleCloseModal();
    } catch (error) {
      const apolloError = error as ApolloError;
      const errorMessage = apolloError?.graphQLErrors?.[0]?.message || TEXT_MESSAGES.ADD_EDIT_ERROR;
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error(error);
    }
  };
  const handleDeleteClick = (paymentId: string, paymentNo: string) => {
    setEntityToDelete(paymentId);
    setEntityToDisplay(paymentNo);
    setDeleteModalOpen(true);
  };
  const handleDelete = async (paymentId: string) => {
    try {
      const result = await deletePaymentMutation({
        variables: {
          id: paymentId,
        },
      });
      if (result && result.data && result.data.deletePayment) {
        setSnackbarMessage(Payment_msg.DELETE_SUCCESS_receipt);
        setSnackbarOpen(true);
        paymentRefetch();
      }
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };
  const confirmDelete = async () => {
    if (!entityToDelete) return;

    try {
      const result = await deletePaymentMutation({
        variables: {
          id: entityToDelete,
        },
      });

      if (result && result.data && result.data.deletePayment) {
        setSnackbarMessage(Payment_msg.DELETE_SUCCESS_receipt);
        setSnackbarSeverity('error');
        paymentRefetch();
        categoryRefetch()
        depositorRefetch()
      }
    } catch (error) {
      setSnackbarMessage(TEXT_MESSAGES.DELETE_ERROR);
      setSnackbarSeverity('error');
      console.error("Error during deletion:", error);
    } finally {
      setSnackbarOpen(true);
      setDeleteModalOpen(false);
      setEntityToDelete(null);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "serial_no",
      headerName: "S.No",
      minWidth:120
    },
    {
      field: "date",
      headerName: "Date",
      minWidth:150
    },
    {
      field: "payment_id",
      headerName: "Payment ID",
      minWidth:150
    },
    {
      field: "category_name",
      headerName: "Category",
      flex: 1,minWidth:150
    },
  {
      field: "remarks",
      headerName: "Remarks",
      flex: 1,minWidth:150
    },

    {
      field: "amount",
      headerName: "Amount",
      flex: 1,minWidth:150
    },

    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleOpenModal(params.row as PaymentModalFormData)}
          >
            <img src={EditIcon} alt="EditIcon" style={{ width: '20px', height: '20px', marginTop: "7px" }} />
          </IconButton>

          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteModal(params.row as PaymentModalFormData)}
          >
            <DeleteIcon style={{ width: '20px', height: '20px', color: themestyle.colors.primary, marginTop: "7px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <Box style={{ padding: "15px" }}>
        <PheonixSnackBar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
          vertical="top"
          horizontal="center"
        />
        {isWideScreen ? (
          <PheonixPaper
            style={{
              borderRadius: "20px",
              height: "70px",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "center",
                padding: isMobileSmall ? "15px 0" : "15px 30px",
              }}
            >
              <Typography
                style={{
                  color: themestyle.colors.black1,
                  fontWeight: themestyle.fontWeight.sm,
                  fontSize: isMobileSmall
                    ? themestyle.fontSizes.sm
                    : themestyle.fontSizes.medium,
                }}
              >
                {Payment_msg.PAYMENT}
              </Typography>
              <Button
                style={{
                  background: themestyle.colors.primary,
                  color: themestyle.colors.white,
                  width: "160px",
                }}
                onClick={() => handleOpenModal(null)}
              >
                {Payment_msg.ADD_PAYMENT}
              </Button>
            </div>
          </PheonixPaper>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color: themestyle.colors.black1,
                marginRight: "10px",
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {Payment_msg.PAYMENT}
            </Typography>
            <Button
              style={{
                background: themestyle.colors.primary,
                color: themestyle.colors.white,
                width: isWideScreen ? "142px" : "160px",
              }}
              onClick={() => handleOpenModal(null)}
            >
              {Payment_msg.ADD_PAYMENT}
            </Button>
          </div>
        )}
        <PheonixPaper style={{ borderRadius: "20px" }}>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <PheonixTable
              columns={columns}
              rows={tableData}
              rowCount={tableCount}
              paginationMode="server"
              paginationModel={paginationModel}
              loading={dataLoading}
              onPaginationModelChange={handlePaginationChange}
              searchQuery ={searchQuery}
              onSearchQueryChange={handleSearchQueryChange}

            />
          </div>
        </PheonixPaper>
        <AddPaymentModal
          open={modalOpen}
          onClose={handleCloseModal}
          onAdd={handleAdd}
          title={title}
          fields={fields}
          showCategoryInput={showCategoryInput}
          setShowCategoryInput={setShowCategoryInput}
          formData={paymentFormData1}
          setFormData={setPaymentFormData1}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
          context="Category"
          editable={isTextEditable}
          categorydata={categoryData ? categoryData.getAllCategories : []}
          depositordata={depositorData ? depositorData.getAllDepositors : []}

        />
        <DeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          entity={entityToDisplay}
          entityname="Payment"
        />
      </Box>
    </div>
  );
};

export default Payment;