import React from 'react';
import { TEXT_MESSAGES } from '../constant';
import { Box, Container,Typography,useMediaQuery,useTheme } from '@mui/material';
import QuickViewGrid from '../Components/QuickViewGrid';
import loanimg from "../assets/icons/loan 1.svg";
import collectionimg from "../assets/icons/routine 1.svg";
import receiptimg from "../assets/icons/Group.svg";
import borrowerimg from "../assets/icons/borrowerimg.svg";
import voucherimg from "../assets/icons/voucherimg.svg";
import paymentimg from "../assets/icons/paymentimg.svg";
import accountsimg from "../assets/icons/Accounts 1.svg";
import depositorimg from "../assets/icons/depositorimg.svg"
import { useSidebar } from "./SidebarContext";
const Dashboard: React.FC = () => {
  const { isOpen } = useSidebar();
  const quickViewItemsTop = [
    { title: 'Loan Ledger', icon: loanimg, linkTo: "/loanledger" },
    { title: 'Receipts', icon: receiptimg ,  linkTo: "/receipt"},
    { title: 'Daily Collection', icon: collectionimg , linkTo: "/dailycollection"},
    { title: 'Borrowers', icon: borrowerimg, linkTo: "/borrowers" },
    { title: 'Payment', icon: paymentimg, linkTo: "/payment" },
    { title: 'Voucher', icon: voucherimg, linkTo: "/voucher" },
   { title: 'Accounts', icon: accountsimg, linkTo: "/accounts"},
    { title: 'Depositors', icon: depositorimg, linkTo: "/depositors" },
  ];
return (
    <Box style={{padding:"30px",height:"50px",}}>
    <Box
      sx={{ 
        padding: "30px" ,
        border: "1px solid rgba(129, 222, 247, 1)", 
        borderRadius: "8px",background: "rgba(238, 248, 255, 1)"

      }}
    >
      <Typography variant="h6" gutterBottom style={{fontWeight:700,fontSize:"18px",fontFamily:"Inter",color: "rgba(0, 0, 0, 1)",marginBottom:"20px"}}>
       {TEXT_MESSAGES.Quick_Views}
      </Typography>
      <QuickViewGrid items={quickViewItemsTop} />
    </Box>
  </Box>
  );
};

export default Dashboard;
