import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteModalProps from "../Interfaces/DeleteModalProps";
import { TEXT_MESSAGES } from "../constant";
import DeleteIcon from "../assets/DeleteIcon.png";

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  onConfirm,
  entity,
  entityname,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#fff",
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
          position: "relative",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          height: "219px",
          width: "343px",
          padding: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "Red Hat Display",
          fontWeight: 700,
          fontSize: "18px",
          padding: "0",
          marginBottom: "8px",
          position: "relative",
        }}
      >
        Are you sure you want to delete?
        
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "14px",
          color: "rgba(0, 0, 0, 0.87)",
          letterSpacing: "0.15px",
          fontWeight: 400,
          fontFamily: "Inter",
          padding: "0",
          marginBottom: "16px",
        }}
      >
        <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
          {entityname} - {entity}'s Information?
        </Typography>
        <img src={DeleteIcon} alt="Delete Icon" style={{ margin: "16px 0" }} />
        <Typography sx={{ fontWeight: 300, fontSize: "12px" }}>
          This action cannot be undone
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0",
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"

color="primary"
          sx={{
            color: "#208BEE",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            borderRadius: "8px",
            marginBottom: "8px",
            marginRight: "8px",
            padding: "6px 16px",
            width: "84px",
            height: "36px",
          }}
        >
          {TEXT_MESSAGES.CANCEL}
        </Button>
        <Button
          onClick={onConfirm}
          
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#208BEE",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            borderRadius: "8px",
            marginBottom: "8px",
            marginRight: "8px",
            padding: "6px 16px",
            width: "84px",
            height: "36px",
            "&:hover": {
              backgroundColor: "#208BEE", 
              color: "#FFFFFF", 
            },
          }}
        >
          {TEXT_MESSAGES.DELETE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
