import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Typography, Button, IconButton, TextField } from "@mui/material";
import { useQuery,useMutation } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid";
import PheonixTable from "../Components/PheonixTable";
import PheonixPaper from "../Components/PheonixPaper";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextFieldProps } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useSidebar } from "./SidebarContext";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import themestyle from "../theme";
import { TEXT_MESSAGES, LoanScreen_msg } from "../constant";
import {  GET_LOAN_AMOUNT,GET_LOAN_BALANCE } from "../graphql/queries";
import {ADD_BALANCE} from "../graphql/mutation";
const LoanScreen: React.FC = () => {
  const { isOpen } = useSidebar();

  const today = dayjs();
  const [tableData, setTableData] = useState<[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dataLoading, setdataLoading] = useState(false);
  const [balanceLoan, setBalanceLoan] = useState("");
  const [outstandingLoan, setOutstandingLoan] = useState("");
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("loanscreenpaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });
  function formattedDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const currentDate = formattedDate(new Date());
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [selectedDates, setSelectedDates] = useState<Dayjs | null>(null);
  const {
    loading: collectionLoading,
    error: collectionError,
    data: loanData,
    refetch: LoanRefetch,
  } = useQuery(GET_LOAN_AMOUNT, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: selectedDates ? selectedDates.format("DD/MM/YYYY") : "",
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, receipts,outstandingAmount } = data.getloandetailsperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const collectionWithSerialNo = receipts.map(
        (collection: any, index: number) => ({
          ...collection,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(collectionWithSerialNo.map((row:any, index:any) => ({ ...row, id: index + 1 })));
      setOutstandingLoan(outstandingAmount)
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });

  useEffect(() => {
    LoanRefetch({
      variables: {
        page: paginationModel.page,
        perPage: paginationModel.pageSize,
        searchQuery: selectedDates ? selectedDates.format("DD/MM/YYYY") : "",
      },
    });
  }, [selectedDates, LoanRefetch, today]);

  const {
    loading: balanceLoading,
    error: balanceError,
    data: balanceData,
    refetch: balanceRefetch,
  } = useQuery(GET_LOAN_BALANCE);


  useEffect(() => {
    if (balanceData && balanceData.getAmount) {
      setBalanceLoan(balanceData.getAmount.totalAmount.totalAmount); 
    }
  }, [balanceData]);
  useEffect(() => {
    localStorage.setItem(
      "loanscreenpaginationModel",
      JSON.stringify(paginationModel)
    );
    return () => {
      localStorage.removeItem("loanscreenpaginationModel");
    };
  }, [paginationModel]);


  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDates(newDate);
  };
  const [receiptData1, setReceiptData1] = useState<{
    date: Dayjs | null;
    receipt_number: number[];
    total_amount_paid: number;
  }>({
    date: today,
    receipt_number: [],
    total_amount_paid: 0,
  });

  useEffect(() => {
    setdataLoading(true);
    if (!collectionLoading && !collectionError && loanData) {
      const { totalPages, totalCount, receipts,outstandingAmount } =
      loanData.getloandetailsperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const collectionWithSerialNo = receipts.map(
        (collection: any, index: number) => ({
          ...collection,
          serial_no: (startIndex + index).toString(),
        })
      );

      setTableData(collectionWithSerialNo.map((row:any, index:any) => ({ ...row, id: index + 1 })));
      setOutstandingLoan(outstandingAmount)
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    loanData,
    collectionLoading,
    collectionError,
    paginationModel.page,
    paginationModel.pageSize,
  ]);

  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel: any) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
 
  };

  const columns: GridColDef[] = [
    { field: "serial_no", headerName: "S.No", width: 150 },
    { field: "date", headerName: "Date", width: 150 },
    { field: "remarks", headerName: "Particular", width: 200 },
    { field: "amount", headerName: "Receipt", flex: 1, minWidth: 200 },
    { field: "loan_amount", headerName: "Voucher", flex: 1, minWidth: 200 },
    { field: "balance_amount", headerName: "Balance", flex: 1, minWidth: 200 },
  ];
  const theme = useTheme();
  const Laptopsmall = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isWideScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <IconButton sx={{ width: "18px", height: "20px" }}>
                <ReceiptOutlinedIcon />
              </IconButton>
              <Typography
                style={{
                  color: themestyle.colors.loan,
                  fontWeight: themestyle.fontWeight.medium,
                  fontSize: isMobileSmall
                    ? themestyle.fontSizes.xsmall
                    : themestyle.fontSizes.small,
                  marginLeft: "10px",
                }}
              >
                {LoanScreen_msg.TitleText}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: isMobileSmall ? "column":'row',
                alignItems: "flex-start",
                
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: isMobileSmall ? "column":'row',
                 
                }}
              >
                <TextField
                  label="Opening Loan Amount"
                  variant="outlined"
                  value={balanceLoan}
                  style={{ marginTop: "8px",marginRight:'10px' }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Loan Outstanding Amount"
                  variant="outlined"
                  value={outstandingLoan}
                  style={{ marginTop: "8px" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>

              <div
                style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Select Date"
                      format="DD/MM/YYYY"
                      maxDate={today}
                      onChange={handleDateChange}
                      slotProps={{
                        field: {
                          readOnly: true,
                        },
                      }}
                      sx={{
                        width: "100%",
                        maxWidth: "300px",
                        marginBottom: "40px",
                        "& .MuiInputBase-root": { minWidth: "unset" },
                        "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            color: "black",
                          },
                        "& .Mui-error": {
                          color: "#bfbfbf",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-error fieldset": {
                            borderColor: "inherit",
                          },
                        },
                        "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error":
                          {
                            color: "#bfbfbf",
                          },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <PheonixTable
              columns={columns}
              rows={tableData}
              rowCount={tableCount}
              paginationMode="server"
              paginationModel={paginationModel}
              loading={dataLoading}
              onPaginationModelChange={handlePaginationChange}
              searchQuery={searchQuery}
              onSearchQueryChange={handleSearchQueryChange}
              screen="loanscreen"
            />
          </div>
    </div>
  );
};

export default LoanScreen;
