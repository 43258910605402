import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import PheonixPaper from "../Components/PheonixPaper";
import { TEXT_MESSAGES } from "../constant";
import themestyle from "../theme";
import AccountsTable from "../Components/AccountsTable";
import Accountssummary from "../Components/Accountssummary";
import { TableRowData } from "../Interfaces/AccountsProps";
import { GET_ACCOUNTS_DETAILS } from "../graphql/mutation";
import { GET_COLLECTION_RECEIPT } from "../graphql/queries";
import { useQuery } from '@apollo/client';
import {
  Borrower,
  LoanNo,
  DownloadReceiptDetails,
} from "../Interfaces/CollectionModalFormData";
const Accounts: React.FC = () => {
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [accountlist, setAccountlist] = useState<TableRowData[]>([]);
  const [totallist, setTotallist] = useState({
    totalPayment: 0,
    totalReceipt: 0,
    dayOpeningAmount: 0,
    dayClosingAmount: 0,
  });

  const today = dayjs();
  const [dataLoading, setdataLoading] = useState(false);
  const [receiptData1, setReceiptData1] = useState<{
    date: Dayjs | null;
    collection_receipt_number: number;
    loan_nos: number[];
    total_amount_collected: number;
    DownloadReceipt: {
      details: DownloadReceiptDetails[];
    };
  }>({
    date: today,
    collection_receipt_number: 0,
    loan_nos: [],
    total_amount_collected: 0,
    DownloadReceipt: {
      details: [],
    },
  });
  const {
    loading: collectionreceiptLoading,
    error: collectionreceiptError,
    data: collectionreceiptData,
    refetch: refetchcollectionreceipt,
  } = useQuery(GET_COLLECTION_RECEIPT, {
    variables: {
      date: selectedDate
        ? selectedDate.format("DD-MM-YYYY")
        : today.format("DD-MM-YYYY"),
    },
    onCompleted: (data) => {
      setReceiptData1({
        date: selectedDate,
        collection_receipt_number: data.getCollectionReceipt.collection_receipt_number,
        loan_nos: data.getCollectionReceipt.loan_nos,
        total_amount_collected: data.getCollectionReceipt.total_amount_collected,
        DownloadReceipt: data.getCollectionReceipt.DownloadReceipt,
      });
    },
  });

  useEffect(() => {
    if (receiptData1.loan_nos.length > 0) {
      const initialAccountData = {
        id: "temp",
        date: receiptData1.date ? receiptData1.date.format("DD/MM/YYYY") : today.format("DD/MM/YYYY"),
        particular:  `Daily collections for loans ${receiptData1.loan_nos.join(", ")} have been collected.`,
        receipt: receiptData1.total_amount_collected ,
        payment: null,
      };
    //@ts-ignore
      setAccountlist([initialAccountData]);
    }
    else {
      setAccountlist([]);
    }
  }, [receiptData1]);
  
  const [getAccountsDetails, { loading, error, data }] = useMutation(GET_ACCOUNTS_DETAILS, {
    variables: {
      date: selectedDate ? selectedDate.format("DD/MM/YYYY") : today.format("DD/MM/YYYY"),
    },
    onCompleted: (data) => {
      if (data && data.getAccountsDetails) {
        const accountsData = data.getAccountsDetails.accountsdata.map(
          (account: {
            id: string;
            sno: number;
            date: string;
            particular: string;
            receipt_amount: number | null;
            payment_amount: number | null;
          }) => ({
            id: account.id,
            date: account.date,
            particular: account.particular,
            receipt: account.receipt_amount,
            payment: account.payment_amount,
          })
        );
        const totals = {
          totalPayment: data.getAccountsDetails.totalPayment,
          totalReceipt: data.getAccountsDetails.totalReceipt + receiptData1.total_amount_collected,
          dayOpeningAmount: data.getAccountsDetails.day_opening_amt,
          dayClosingAmount: data.getAccountsDetails.day_closing_amt,
        };
        setAccountlist((prevList) => [...prevList, ...accountsData]);
        setTotallist(totals);
      }
    },
  });
  useEffect(() => {
    getAccountsDetails();
  }, [selectedDate]);

  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate(newDate);
  };
const summaryItems = [
    { title: "Day Opening Amount", value: totallist.dayOpeningAmount },
    { title: "Day Closing Amount", value: totallist.dayClosingAmount || 0 },
    { title: "Total Receipt", value: totallist.totalReceipt },
    { title: "Total Payment", value: totallist.totalPayment },
  ];
  
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const height = isMobileSmall
  ? "135vh"
  : isTablet
  ? "135vh"
  : "64vh";

return (
    <Box style={{ padding: "15px" }} >
      <PheonixPaper style={{ borderRadius: "5px", height: "60px", marginBottom: "20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center",
           padding: isMobileSmall ? "15px 10px" : "10px 30px" }}>
          <Typography style={{ color: themestyle.colors.black, fontWeight: themestyle.fontWeight.sm, fontSize: isMobileSmall ? themestyle.fontSizes.sm : themestyle.fontSizes.medium }}>
            {TEXT_MESSAGES.ACCOUNTS}
          </Typography>
        </div>
      </PheonixPaper>
  <PheonixPaper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ padding: 4,height:isMobileSmall?"60vh":isTablet? "60vh":"63vh", overflowY: "auto"  }}>
            <Box sx={{ display: "flex", justifyContent: "right", alignItems: "right", marginBottom: 2 }}>
              <DatePicker
                label="Date"
                format="DD/MM/YYYY"
                value={selectedDate}
                onChange={handleDateChange}
                maxDate={today}
                sx={{ width: 200 }}
              />
             </Box>
            <Box sx={{ flex: 1, overflowY: "auto" }}>
              <AccountsTable data={accountlist} loading={loading} />
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <Accountssummary items={summaryItems} />
            </Box>
          </Box>
        </LocalizationProvider>
  
      </PheonixPaper>
 </Box>
  );
};
export default Accounts;
