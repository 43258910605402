import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import PheonixButtonProps from '../Interfaces/PheonixButtonProps';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import themestyle from '../theme';

const PheonixButton: React.FC<PheonixButtonProps> = ({ label,disabled,onClick,style, ...props }) => {
    const theme = useTheme();

    const isMobileSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MuiButton disabled={disabled} onClick={onClick} variant="outlined"  
    style={{ backgroundColor:disabled ? 'lightgray' : themestyle.colors.primary,color: disabled ? 'darkgray' :'white', padding: '5px', width: isMobileSmall?'250px' :'350px', height: '42px',borderRadius:'5px',border:  '1px',boxSizing:'border-box'  }} {...props} >
      {label}
    </MuiButton>
  );
};
export default PheonixButton;