import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import PheonixPaper from "../Components/PheonixPaper";
import { VoucherFormData } from "../Interfaces/VoucherFormdataProps";
import { GET_MONTHLY_LOAN_HISTORY, GET_MONTHLY_LOAN_HISTORY_PER_PERSON } from "../graphql/queries";
import PheonixTable from "../Components/PheonixTable";
import themestyle from "../theme";
import { TEXT_MESSAGES } from "../constant";
import { useNavigate, useLocation } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import backarrow from "../assets/backarrow.svg";
import DetailRow from "./DetailsRow";
interface BorrowerDetails {
  borrower_name: string;
  borrower_id: string;
  address1: string;
  address2:  string;
  loan_type: string;
  loan_amt:  string;
  phoneNUmber: string;
  pending_amt:  string;
  interest_amount:  string;
  date:  string;
  endDate:  string;
  dailyAmount: string;
  days: string;
  occupation: string;
  secondaryNumber: string;


}
const MonthlyLoanDetails: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [tableData, setTableData] = useState<VoucherFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [dataLoading, setdataLoading] = useState(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [LoanNo, setLoanNo] = useState<number | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const location = useLocation();
  useEffect(() => {
    if (location.state?.loan_no) {
      setLoanNo(location.state.loan_no);
    }
  }, [location.state]);

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      loanHistoryRefetch({ variables: { loan_no: LoanNo, searchQuery: null } });
    } else {
      loanHistoryRefetch({
        variables: { loan_no: LoanNo, searchQuery: newQuery },
      });
    }
  };

  const {
    loading: loanHistoryLoading,
    error: loanHistoryError,
    data: loanHistoryData,
    refetch: loanHistoryRefetch,
  } = useQuery(GET_MONTHLY_LOAN_HISTORY, {
    variables: { loan_no: LoanNo, searchQuery },
    onCompleted: (data) => {
      const reversedData = data.getReceiptMonthlyHistory.slice().reverse();
      const rows = reversedData.map(
        (
          item: {
            id: any;
            date: any;
            receipt_id: any;
            remarks:any;
            principalAmount: any;
            interestAmount: any;
            voucherDetails: {
              collectedAmount: any;
              remainingAmount: any;
              totalAmountofcollectionandremaining: any;
            };
          },
          index: number
        ) => ({
          id: item.id,
          serial_no: (index + 1).toString(),
          date: item.date,
          receipt_id: item.receipt_id,
          remarks:item.remarks,
          principalAmount: item.principalAmount,
          interestAmount: item.interestAmount,
          remainingAmount: item.voucherDetails.remainingAmount,
          totalAmountofcollectionandremaining: item.voucherDetails.totalAmountofcollectionandremaining,
        })
      );
      setTableData(rows);
      setTableCount(rows.length);
    },
  });

  useEffect(() => {
    if(LoanNo){
      loanHistoryRefetch();
     }
    }, [ LoanNo, loanHistoryRefetch]);

  useEffect(() => {
    if (LoanNo !== null) {
      setColumns([
        { field: "serial_no", headerName: "S.No", width: 100 },
        { field: "date", headerName: "Date", flex: 1, minWidth: 150 },
        { field: "receipt_id", headerName: "Receipt No", flex: 1, minWidth: 150 },
        { field: "remarks", headerName: "Particulars", flex: 1, minWidth: 250 },
        { field: "interestAmount", headerName: "Interest Amount", flex: 1, minWidth: 150 },
        { field: "principalAmount", headerName: "Principal Return", flex: 1, minWidth: 150 },
        {
          field: "remainingAmount",
          headerName: "Loan Outstanding Amount",
          flex: 2,
          minWidth: 250,
        },
      ]);
    }
  }, [LoanNo]);
  const handleBack = () => {
    navigate(-1);
  };
  const [borrowerData, setBorrowerData] = useState<BorrowerDetails | null>(null);

  const {
    loading: loanHistoryLoadings,
    error: loanHistoryErrors,
    data: loanHistoryDatas,
    refetch: loanHistoryRefetchs,
  } = useQuery(GET_MONTHLY_LOAN_HISTORY_PER_PERSON, {
    variables: { loan_no: LoanNo },
    skip: LoanNo === null || isNaN(LoanNo),
    onCompleted: (data) => {
      const borrowerHistory = data.getMonthlyBorrowerhistoryByLoan;
      const borrowerData: BorrowerDetails = {
          borrower_name: borrowerHistory.borrower_name,
          borrower_id: borrowerHistory.borrower_id,
          address1: borrowerHistory.address1,
          address2: borrowerHistory.address2,
          loan_type: borrowerHistory.loan_type, 
          loan_amt: borrowerHistory.loan_amt,   
          phoneNUmber: borrowerHistory.phoneNUmber,
          pending_amt: borrowerHistory.pending_amt,
          interest_amount: borrowerHistory.interest_amount, 
          date: borrowerHistory.date,
          endDate: borrowerHistory.endDate, 
          dailyAmount: borrowerHistory.dailyAmount, 
          days: borrowerHistory.days, 
          occupation: borrowerHistory.occupation, 
          secondaryNumber: borrowerHistory.secondaryNumber
      };
  
      setBorrowerData(borrowerData); 
  },
})
  return (
    <Box style={{ padding: "15px" }}>
      {isWideScreen ? (
        <PheonixPaper
          style={{
            borderRadius: "10px",
            height: "70px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color: themestyle.colors.black,
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {`${TEXT_MESSAGES.LOAN_DETAILS_HEADER} - ${LoanNo}`}
            </Typography>
            <div>
              <img src={backarrow} alt="arrow" />
              <Button
                variant="text"
                onClick={handleBack}
                style={{
                  marginBottom: "10px",
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#208BEE",
                  textDecoration: "underline",
                }}
              >
                {TEXT_MESSAGES.MONTHLY_BACK_BUTTON}
              </Button>
            </div>
          </div>
        </PheonixPaper>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            padding: isMobileSmall ? "1px " : "15px 30px",
          }}
        >
          <Typography
            style={{
              color: themestyle.colors.black,
              marginRight: "10px",
              fontWeight: themestyle.fontWeight.sm,
              fontSize: isMobileSmall
                ? themestyle.fontSizes.sm
                : themestyle.fontSizes.medium,
            }}
          >
            {`${TEXT_MESSAGES.LOAN_DETAILS_HEADER} - ${LoanNo}`}
          </Typography>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img
              src={backarrow}
              alt="arrow"
              style={{
                width: "16px",
                height: "16px",
              }}
            />
            <Button
              variant="text"
              onClick={handleBack}
              style={{
                marginBottom: "10px",
                textTransform: "none",
                fontSize: "13px",
                fontWeight: 500,
                color: "#208BEE",
                textDecoration: "underline",
              }}
            >
              {TEXT_MESSAGES.MONTHLY_BACK_BUTTON}
            </Button>
          </div>
        </div>
      )}
      <Box
        style={{
          padding: isMobileSmall ? "10px" : "0px 20px 20px 0px",
          borderRadius: "8px",
          maxHeight: isMobileSmall ? "65vh" : isTablet ? "60vh" : "65vh",
          overflowX: "hidden",
          overflowY: "auto",
          backgroundColor: themestyle.colors.white,
        }}
      >

<Box
        style={{
          padding:"20px",
          backgroundColor: themestyle.colors.white,
        }}
      >   
{borrowerData && (
    <Grid container spacing={2}>
        <DetailRow label="Loan No" value={LoanNo || ""} />
        <DetailRow label="Borrower Name" value={borrowerData.borrower_name} />
        <DetailRow label="Borrower ID" value={borrowerData.borrower_id} />
        <DetailRow label="Address" value={borrowerData.address1 || "N/A" } />
        <DetailRow label="Occupation" value={borrowerData.occupation || "N/A"} />
        <DetailRow label="Phone Number" value={borrowerData.phoneNUmber || "N/A"} />
        <DetailRow label="Secondary Number" value={borrowerData.secondaryNumber || "N/A"} />
        <DetailRow label="Loan Type" value={borrowerData.loan_type} />
        <DetailRow label="Start Date" value={borrowerData.date} /> 
        <DetailRow label="Loan Amount" value={borrowerData.loan_amt} />
        <DetailRow label="Interest Amount" value={borrowerData.interest_amount} />  
    </Grid>
)}
</Box>
        <PheonixTable
          columns={columns}
          rows={tableData}
          rowCount={tableCount}
          paginationMode="server"
          paginationModel={paginationModel}
          loading={loanHistoryLoadings}
          searchQuery={searchQuery}
          onSearchQueryChange={handleSearchQueryChange}
        />
      </Box>
    </Box>
  );
};
export default MonthlyLoanDetails;
