import React, { createContext, useState, useEffect, useContext,useCallback } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import { SessionContextProviderProps } from '../Interfaces/ContextInterface';
import SessionContextType from '../Interfaces/SessionContextType';

const SessionContext = createContext<SessionContextType | undefined>(undefined);

const SessionContextProvider: React.FC<SessionContextProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
    if (!loggedIn || (loggedIn && location.pathname === '/')) {
      navigate('/');
    }
  }, [navigate]);

  const login = () => {
    localStorage.setItem('isLoggedIn', 'true');
    setIsLoggedIn(true);
    navigate('/dashboard'); 
  };

  const logout = () => {
    localStorage.setItem('isLoggedIn', 'false');
    setIsLoggedIn(false);
    navigate('/'); 
  };

  return (
    <SessionContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </SessionContext.Provider>
  );
};

const useSession = (): SessionContextType => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('error');
  }
  return context;
};

export { SessionContextProvider, useSession };
