import React from 'react';
import './App.css';
import Login from './Pages/login';

const App = () => {
  return (
    <div>
      <Login />
    </div>
  );
};
export default App;