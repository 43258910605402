import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Typography, Button, IconButton, TextField } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid";
import PheonixTable from "../Components/PheonixTable";
import PheonixPaper from "../Components/PheonixPaper";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextFieldProps } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useSidebar } from "./SidebarContext";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import themestyle from "../theme";
import { TEXT_MESSAGES, LoanScreen_msg } from "../constant";
import {  GET_DEPOSITOR_LEDGER } from "../graphql/queries";
import { useNavigate, useLocation } from "react-router-dom";
const DepositorScreen: React.FC = () => {
  const { isOpen } = useSidebar();

  const today = dayjs();
  const [tableData, setTableData] = useState<[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dataLoading, setdataLoading] = useState(false);
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("loanscreenpaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });
  function formattedDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const currentDate = formattedDate(new Date());
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const {
    loading: collectionLoading,
    error: collectionError,
    data: loanData,
    refetch: LoanRefetch,
  } = useQuery(GET_DEPOSITOR_LEDGER, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery || "",
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, receipt } = data.getDepositLedgerperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const collectionWithSerialNo = receipt.map(
        (collection: any, index: number) => ({
          ...collection,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(collectionWithSerialNo.map((row:any, index:any) => ({ ...row, id: index + 1 })));

      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });

  useEffect(() => {
    LoanRefetch();
  }, [paginationModel.page, paginationModel.pageSize, searchQuery, LoanRefetch]);

  useEffect(() => {
    localStorage.setItem(
      "loanscreenpaginationModel",
      JSON.stringify(paginationModel)
    );
    return () => {
      localStorage.removeItem("loanscreenpaginationModel");
    };
  }, [paginationModel]);


  const [selectedDate1, setSelectedDate1] = useState<Dayjs | null>(null);

  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate1(newDate);
  };
  const [receiptData1, setReceiptData1] = useState<{
    date: Dayjs | null;
    receipt_number: number[];
    total_amount_paid: number;
  }>({
    date: today,
    receipt_number: [],
    total_amount_paid: 0,
  });

  useEffect(() => {
    setdataLoading(true);
    if (!collectionLoading && !collectionError && loanData) {
      const { totalPages, totalCount, receipt } =
      loanData.getDepositLedgerperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const collectionWithSerialNo = receipt.map(
        (collection: any, index: number) => ({
          ...collection,
          serial_no: (startIndex + index).toString(),
        })
      );

      setTableData(collectionWithSerialNo.map((row:any, index:any) => ({ ...row, id: index + 1 })));
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    loanData,
    collectionLoading,
    collectionError,
    paginationModel.page,
    paginationModel.pageSize,
  ]);
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel: any) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
  };

  const handleloannoClick = (DepositerNO: number) => {
    navigate(`/dephistory/${DepositerNO}`, { state: { DepositerNO } });
  };

  const columns: GridColDef[] = [
    { field: "serial_no", headerName: "S.No", width: 100 },
    { field: "date", headerName: "Date", width: 150 },
    { field: "depositor_name", headerName: "Name", width: 150 },
    { field: "deposit_id", headerName: "Deposit ID", width: 150,
    renderCell: (params) => (
      <Typography
        variant="body2"
        color="primary"
        style={{
          cursor: "pointer",
          textAlign: "start",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          height: "100%",
          textDecoration: "underline",
        }}
        onClick={() => handleloannoClick(params.row.deposit_id)}
      >
        {params.row.deposit_id}
      </Typography>
    )
    },
    { field: "balance_amount", headerName: "Balance Amount", flex: 1, minWidth: 200 },
    { field: "interest_amount", headerName: "Interest Amount", flex: 1, minWidth: 200 },

  ];
  const theme = useTheme();
  const Laptopsmall = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isWideScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <PheonixTable
              columns={columns}
              rows={tableData}
              rowCount={tableCount}
              paginationMode="server"
              paginationModel={paginationModel}
              loading={dataLoading}
              onPaginationModelChange={handlePaginationChange}
              searchQuery={searchQuery}
              onSearchQueryChange={handleSearchQueryChange}
            />
          </div>
    </div>
  );
};

export default DepositorScreen;
