import React from 'react';
import { TEXT_MESSAGES } from '../constant';
import { Typography,useMediaQuery,useTheme } from '@mui/material';
const Report : React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
return (
  <div 
  style={{ 
       height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  }}
>
  <Typography
      style={{
          fontWeight: 400,
          fontFamily: "Roboto",
          fontSize: isSmallScreen ? "32px" : "48px",
          color: "black",
          textAlign: "center"
      }}
  >
      {TEXT_MESSAGES.TEXT_MEG}
  </Typography>
</div>
);
};

export default Report;
