import React from 'react';
import { TextField, TextareaAutosize, InputLabel, Box } from '@mui/material';

interface PheonixTextareaProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void; 
  error?: boolean;
  helperText?: string;
  required?: boolean;
  editable?: boolean;
  style?: React.CSSProperties;
  sx?: React.CSSProperties;
}

const PheonixTextarea: React.FC<PheonixTextareaProps> = ({
  label,
  style,
  sx,
  value,
  onChange,
  onBlur,
  error = false,
  helperText = '',
  required = false,
  editable = false,
}) => {
  return (
    <TextField
      fullWidth
      label={label}
      error={error}
      helperText={helperText}
      required={required}
      variant="outlined"
      InputProps={{
        inputComponent: TextareaAutosize as any,
        readOnly: editable,
        inputProps: {
          style: {
            resize: 'vertical',
            minHeight: '26px',
          },
        },
        
      }}
      sx={{
        '& .MuiInputLabel-root': {
          color: '#3D3D4E', 
        },
        "& label.Mui-focused": {
          color: "#208BEE",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#208BEE!important",
          },
        },
    }}
      value={value}
      onChange={(e) => onChange(e as React.ChangeEvent<HTMLTextAreaElement>)}
      onBlur={onBlur}
    />
  );
};

export default PheonixTextarea;
