import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid";
import PheonixTable from "../Components/PheonixTable";
import PheonixPaper from "../Components/PheonixPaper";
import PhoenixModal from "../Components/PheonixModal";
import { TEXT_MESSAGES, Collection_msg } from "../constant";
import { CollectionModalFormData } from "../Interfaces/CollectionModalFormData";
import {
  GET_COLLECTION,
  GET_LOAN_NUMBERS_BY_BORROWER,
  GET_COLLECTION_RECEIPT,
  GET_BORROWER_BY_LOAN_NO,
  GET_ALL_LOANNOS,
  GET_ALL_VOUCHERS,
} from "../graphql/queries";
import EditIcon from "../assets/EditFilled.png";
import VisibleIcon from "../assets/visibility.png";
import DeleteIcon from "@mui/icons-material/Delete";
import CollectionReceipt from "./CollectionReceipt";
import themestyle from "../theme";
import {
  ADD_COLLECTION,
  EDIT_COLLECTION,
  DELETE_COLLECTION,
} from "../graphql/mutation";
import PheonixSnackBar from "../Components/PheonixSnackBar";
import CollectionModal from "../Components/CollectionModal";
import DeleteModal from "../Components/DeleteModal";
import dayjs, { Dayjs } from "dayjs";
import {
  Borrower,
  LoanNo,
  DownloadReceiptDetails,
} from "../Interfaces/CollectionModalFormData";
import { useSidebar } from "./SidebarContext";

const Collection: React.FC = () => {
  const { isOpen } = useSidebar();
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isTextEditable, setIsTextEditable] = useState(false);
  const [selectedBorrowerId, setSelectedBorrowerId] = useState<string | null>(
    null
  );
  function formattedDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const currentDate = formattedDate(new Date());
  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [receiptNumber, setReceiptNumber] = useState("");
  const [loanNumber, setLoanNumber] = useState<string[]>([]);
  const [borrower_Name, setBorrower_Name] = useState<string[]>([]);
  const [borrower_Id, setBorrower_Id] = useState<string | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");
  const [totalAmount, setTotalAmount] = useState("200");
  const [tableData, setTableData] = useState<CollectionModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [title, settitle] = useState("");
  const [dataLoading, setdataLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [succMessage, setSuccMessage] = useState("");
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("CollectionpaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });

  useEffect(() => {
    localStorage.setItem(
      "CollectionpaginationModel",
      JSON.stringify(paginationModel)
    );
    return () => {
      localStorage.removeItem("CollectionpaginationModel");
    };
  }, [paginationModel]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [addCollectionMutation] = useMutation(ADD_COLLECTION);
  const [editCollectionMutation] = useMutation(EDIT_COLLECTION);
  const [deleteCollectionMutation] = useMutation(DELETE_COLLECTION);
  const [collectionFormData1, setCollectionFormData1] =
    useState<CollectionModalFormData>({
      "Borrower Name": "",
      "Loan No": "",
      Amount: "",
      Date: "",
    });
  const {
    loading: collectionLoading,
    error: collectionError,
    data: collectionData,
    refetch: collectionRefetch,
  } = useQuery(GET_COLLECTION, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery || "",
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, borrower } = data.getCollectionperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const collectionWithSerialNo = borrower.map(
        (collection: CollectionModalFormData, index: number) => ({
          ...collection,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(collectionWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });
  const {
    loading: voucherLoading,
    error: voucherError,
    data: voucherData,
    refetch: voucherRefetch,
  } = useQuery(GET_ALL_VOUCHERS);

  useEffect(() => {
    voucherRefetch();
  }, []);

  const {
    loading: loannoLoading,
    error: loannoError,
    data: loannoData,
    refetch: refetchLoanNumbers,
  } = useQuery(GET_LOAN_NUMBERS_BY_BORROWER, {
    variables: {
      borrowerName: collectionFormData1["Borrower Name"],
      borrowerId: collectionFormData1["Borrower ID"],
    },
    skip:
      !collectionFormData1["Borrower Name"] ||
      !collectionFormData1["Borrower ID"],
    onCompleted: (data) => {
      setLoanNumber(data.getLoanNumbersByBorrower);
    },
  });
  const [borrowerName, setBorrowerName] = useState<Borrower[]>([]);
  const {
    loading: borrowerNameLoading,
    error: borrowerNameError,
    data: borrowerNameData,
    refetch: refetchBorrowerName,
  } = useQuery(GET_BORROWER_BY_LOAN_NO, {
    variables: { loanNo: parseInt(collectionFormData1["Loan No"], 10) },
    skip: !collectionFormData1["Loan No"],
    onCompleted: (data) => {
      const formattedBorrowers = data.getBorrowerByLoanNumbers.map(
        (item: any) => ({
          borrower_id: item.borrower_id,
          borrower_name: item.borrower_name,
        })
      );
      setBorrowerName(formattedBorrowers);
    },
  });
  const [selectedDate1, setSelectedDate1] = useState<Dayjs | null>(null);
  const [receiptData1, setReceiptData1] = useState<{
    date: Dayjs | null;
    collection_receipt_number: number;
    loan_nos: number[];
    total_amount_collected: number;
    DownloadReceipt: {
      details: DownloadReceiptDetails[];
    };
  }>({
    date: today,
    collection_receipt_number: 0,
    loan_nos: [],
    total_amount_collected: 0,
    DownloadReceipt: {
      details: [],
    },
  });

  const {
    loading: collectionreceiptLoading,
    error: collectionreceiptError,
    data: collectionreceiptData,
    refetch: refetchcollectionreceipt,
  } = useQuery(GET_COLLECTION_RECEIPT, {
    variables: {
      date: selectedDate1
        ? selectedDate1.format("DD-MM-YYYY")
        : today.format("DD-MM-YYYY"),
    },
    onCompleted: (data) => {
      setReceiptData1({
        date: selectedDate1,
        collection_receipt_number:
          data.getCollectionReceipt.collection_receipt_number,
        loan_nos: data.getCollectionReceipt.loan_nos,
        total_amount_collected:
          data.getCollectionReceipt.total_amount_collected,
        DownloadReceipt:
          collectionreceiptData.getCollectionReceipt.DownloadReceipt,
      });
    },
  });

  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate1(newDate);
    refetchcollectionreceipt({
      date: newDate ? newDate.format("DD-MM-YYYY") : today.format("DD-MM-YYYY"),
    });
  };
  useEffect(() => {
    if (
      collectionFormData1["Borrower Name"] &&
      collectionFormData1["Borrower ID"]
    ) {
      refetchLoanNumbers();
      refetchBorrowerName();
    }
    refetchcollectionreceipt();
    refetchBorrowerName();
  }, [
    collectionFormData1["Borrower Name"],
    collectionFormData1["Borrower ID"],
    refetchLoanNumbers,
    refetchcollectionreceipt,
    refetchBorrowerName,
  ]);

  const handleBorrowerChange = (
    _: React.ChangeEvent<{}>,
    value: Borrower | null
  ) => {
    setCollectionFormData1((prevData) => ({
      ...prevData,
      "Borrower Name": value ? value.borrower_name : "",
      "Borrower ID": value ? value.borrower_id : "",
    }));
  };

  const handleLoanNoChange = (
    _: React.ChangeEvent<{}>,
    value: LoanNo | null
  ) => {
    setCollectionFormData1((prevData) => ({
      ...prevData,
      "Loan No": value ? value.loan_no : "",
    }));
  };
  useEffect(() => {
    setdataLoading(true);
    if (!collectionLoading && !collectionError && collectionData) {
      const { totalPages, totalCount, collection } =
        collectionData.getCollectionperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const collectionWithSerialNo = collection.map(
        (collection: CollectionModalFormData, index: number) => ({
          ...collection,
          serial_no: (startIndex + index).toString(),
        })
      );

      setTableData(collectionWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    collectionData,
    collectionLoading,
    collectionError,
    paginationModel.page,
    paginationModel.pageSize,
  ]);
  const {
    loading: loanLoading,
    error: loanError,
    data: loanData,
    refetch: loanRefetch,
  } = useQuery(GET_ALL_LOANNOS);
  useEffect(() => {
    collectionRefetch();
    refetchLoanNumbers();
    loanRefetch();
  }, [collectionRefetch, refetchLoanNumbers, loanRefetch]);
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel: any) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const handleCloseModal = () => {
    setFieldErrors({});
    setCollectionFormData1({
      "Borrower Name": "",
      "Loan No": "",
      Amount: "",
      Date: "",
    });
    setBorrower_Name([]);
    setModalOpen(false);
  };
  const [selectedCollectionId, setSelectedCollectionId] = useState<
    string | null
  >(null);
  const handleOpenModal = (
    selectedRowData: CollectionModalFormData | null = null
  ) => {
    setModalOpen(true);
    setFieldErrors({});

    if (selectedRowData) {
      setSelectedBorrowerId(selectedRowData.id);
      setIsEditing(true);
      setIsTextEditable(false);
      const parsedDate = dayjs(selectedRowData.date, "DD-MM-YYYY");
      setCollectionFormData1({
        "Borrower Name": selectedRowData.borrower_name,
        "Loan No": selectedRowData.loan_no,
        Amount: selectedRowData.amount,
        Date: selectedRowData.date,
      });

      setSelectedDate2(parsedDate.isValid() ? parsedDate : dayjs());
      setBorrowerName([
        {
          id: selectedRowData.id,
          borrower_name: selectedRowData.borrower_name,
          borrower_id: selectedRowData.borrower_id,
        },
      ]);

      settitle(Collection_msg.EDIT_COLLECTION);
    } else {
      setSelectedBorrowerId(null);
      setIsEditing(false);
      setIsTextEditable(false);

      setCollectionFormData1({
        "Borrower Name": "",
        "Loan No": "",
        Amount: "",
        Date: "",
      });

      setSelectedDate2(dayjs());
      setBorrowerName([]);
      settitle(Collection_msg.MODAL_TITLE);
    }
  };
  const handleViewModal = (
    selectedRowData: CollectionModalFormData | null = null
  ) => {
    setModalOpen(true);
    setFieldErrors({});

    if (selectedRowData) {
      setSelectedBorrowerId(selectedRowData.id);
      setIsEditing(false);
      setIsTextEditable(true);
      const parsedDate = dayjs(selectedRowData.date, "DD-MM-YYYY");
      setCollectionFormData1({
        "Borrower Name": selectedRowData.borrower_name,
        "Loan No": selectedRowData.loan_no,
        Amount: selectedRowData.amount,
        Date: selectedRowData.date,
      });

      setSelectedDate2(parsedDate.isValid() ? parsedDate : dayjs());
      setBorrowerName([
        {
          id: selectedRowData.id,
          borrower_name: selectedRowData.borrower_name,
          borrower_id: selectedRowData.borrower_id,
        },
      ]);

      settitle(Collection_msg.VIEW_COLLECTION_TITLE);
    } else {
      setSelectedBorrowerId(null);
      setIsEditing(false);
      setIsTextEditable(false);

      setCollectionFormData1({
        "Borrower Name": "",
        "Loan No": "",
        Amount: "",
        Date: "",
      });

      setSelectedDate2(dayjs());
      setBorrowerName([]);
      settitle(Collection_msg.MODAL_TITLE);
    }
  };
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      collectionRefetch({ variables: { searchQuery: {} } });
    } else {
      collectionRefetch();
    }
  };
  const [selectedDate2, setSelectedDate2] = useState<Dayjs | null>(dayjs());
  const handleAdd = async () => {
    setFieldErrors({});
    const amount = parseFloat(collectionFormData1["Amount"]);
    const loanNo = parseInt(collectionFormData1["Loan No"], 10);
    const { borrower_id, borrower_name } = borrowerName?.[0] || {};
    const collectionInput = {
      borrower_name: borrower_name || "",
      borrower_id: borrower_id || "",
      amount: amount,
      loan_no: loanNo,
      date: selectedDate2 ? dayjs(selectedDate2).format("DD-MM-YYYY") : null,
    };

    const loans = voucherData.getAllVouchers.filter(
      (loan: any) => loan.loan_no === loanNo
    );
    let loanDateStr = loans[0]?.date;
    let receiptDateStr = dayjs(selectedDate2).format("DD-MM-YYYY");
    const parsedLoanDate = dayjs(loanDateStr, "DD/MM/YYYY");
    const parsedReceiptDate = dayjs(receiptDateStr, "DD/MM/YYYY");

    if (parsedReceiptDate < parsedLoanDate) {
      setSnackbarMessage(Collection_msg.DATE_INVALID);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } else {
      try {
        let response;
        if (isEditing && selectedBorrowerId) {
          response = await editCollectionMutation({
            variables: {
              id: selectedBorrowerId,
              ...collectionInput,
            },
          });

          setSnackbarMessage(Collection_msg.COLLECTIONUPDATE);
          setSnackbarSeverity("success");
        } else {
          response = await addCollectionMutation({
            variables: collectionInput,
          });

          setSnackbarMessage(Collection_msg.COLLECTIONCREATE);
          setSnackbarSeverity("success");
        }
        refetchcollectionreceipt();
        collectionRefetch();
        setSnackbarOpen(true);
        handleCloseModal();
      } catch (error: any) {
        setSnackbarMessage(error.message || TEXT_MESSAGES.ADD_EDIT_ERROR);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.error(error);
      }
    }
  };
  useEffect(() => {
    if (collectionreceiptData) {
      setReceiptData1({
        date: selectedDate1,
        collection_receipt_number:
          collectionreceiptData.getCollectionReceipt.collection_receipt_number,
        loan_nos: collectionreceiptData.getCollectionReceipt.loan_nos,
        total_amount_collected:
          collectionreceiptData.getCollectionReceipt.total_amount_collected,
        DownloadReceipt:
          collectionreceiptData.getCollectionReceipt.DownloadReceipt,
      });
    }
  }, [collectionreceiptData]);
  const handleDelete = (collectionId: string, collectionNo: string) => {
    setEntityToDelete(collectionId);
    setEntityToDisplay(collectionNo);
    setDeleteModalOpen(true);
  };
  const confirmDelete = async () => {
    if (!entityToDelete) return;
    try {
      const result = await deleteCollectionMutation({
        variables: {
          id: entityToDelete,
        },
      });
      if (result && result.data && result.data.deleteCollection) {
        setSnackbarMessage(Collection_msg.DELETE_SUCCESS_receipt);
        setSnackbarSeverity("error");
        collectionRefetch();
        refetchcollectionreceipt();
      }
    } catch (error) {
      setSnackbarMessage(TEXT_MESSAGES.DELETE_ERROR);
      setSnackbarSeverity("error");
      console.error("Error during deletion:", error);
    } finally {
      setSnackbarOpen(true);
      setDeleteModalOpen(false);
      setEntityToDelete(null);
    }
  };
  const fields = [
    { label: "Loan No", type: "text" },
    { label: "Date", type: "text" },
    { label: "Borrower Name", type: "text" },
    { label: "Amount", type: "text" },
  ];
  const columns: GridColDef[] = [
    { field: "serial_no", headerName: "S.No", width: 100 },
    {
      field: "collection_no",
      headerName: "Collection No",
      flex: 1,
      minWidth: 200,
    },
    { field: "borrower_name", headerName: "Name", flex: 1, minWidth: 200 },
    { field: "amount", headerName: "Amount", flex: 1, minWidth: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginLeft: "-8px",
          }}
        >
          <IconButton onClick={() => handleViewModal(params.row)}>
            <img
              src={VisibleIcon}
              alt="View"
              style={{ width: "20px", height: "20px", marginTop: "7px" }}
            />
          </IconButton>
          <IconButton onClick={() => handleOpenModal(params.row)}>
            <img src={EditIcon} alt="Edit" style={{ marginTop: "7px" }} />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() =>
              handleDelete(params.row.id, params.row.collection_no)
            }
          >
            <DeleteIcon
              style={{
                width: "20px",
                height: "20px",
                color: themestyle.colors.primary,
                marginTop: "7px",
              }}
            />
          </IconButton>
        </div>
      ),
      align: "center",
    },
  ];
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Box style={{ padding: "15px" }}>
        <PheonixSnackBar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
          vertical="top"
          horizontal="center"
        />
        {isWideScreen ? (
          <PheonixPaper
            style={{
              borderRadius: "20px",
              height: "70px",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "center",
                padding: isMobileSmall ? "15px 0" : "15px 30px",
              }}
            >
              <Typography
                style={{
                  color: themestyle.colors.black,
                  fontWeight: themestyle.fontWeight.sm,
                  fontSize: isMobileSmall
                    ? themestyle.fontSizes.sm
                    : themestyle.fontSizes.medium,
                }}
              >
                {Collection_msg.COLLECTION}
              </Typography>
              <Button
                style={{
                  background: themestyle.colors.primary,
                  color: themestyle.colors.white,
                  width: "200px",
                }}
                onClick={() => handleOpenModal(null)}
              >
                {Collection_msg.ADD_COLLECTION}
              </Button>
            </div>
          </PheonixPaper>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color: themestyle.colors.black,
                marginRight: "10px",
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {Collection_msg.COLLECTION}
            </Typography>
            <Button
              style={{
                background: themestyle.colors.primary,
                color: themestyle.colors.white,
                width: isWideScreen ? "142px" : "160px",
              }}
              onClick={() => handleOpenModal(null)}
            >
              {Collection_msg.ADD_COLLECTION}
            </Button>
          </div>
        )}
        <Box
          style={{
            display: "flex",
            flexDirection: isWideScreen ? "row" : "column",
            gap: "20px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <PheonixPaper
            style={{
              width: isWideScreen ? "60%" : "100%",
              borderRadius: "20px 0 0 20px ",
            }}
          >
            <div style={{ overflowX: "auto" }}>
              <PheonixTable
                columns={columns}
                rows={tableData}
                rowCount={tableCount}
                paginationMode="server"
                paginationModel={paginationModel}
                loading={dataLoading}
                onPaginationModelChange={handlePaginationChange}
                isCollectionScreen={true}
                searchQuery={searchQuery}
                onSearchQueryChange={handleSearchQueryChange}
              />
            </div>
          </PheonixPaper>

          <CollectionReceipt
            selectedDate={selectedDate1}
            setSelectedDate={setSelectedDate1}
            receiptNumber={receiptData1.collection_receipt_number}
            loanNumbers={receiptData1.loan_nos}
            totalAmount={receiptData1.total_amount_collected}
            handleDateChange={handleDateChange}
            DownloadReceipt={receiptData1.DownloadReceipt}
            isOpen={isOpen}
          />
        </Box>
        <CollectionModal
          open={modalOpen}
          onClose={handleCloseModal}
          onAdd={handleAdd}
          fields={fields}
          title={title}
          formData={collectionFormData1}
          setFormData={setCollectionFormData1}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
          context="Collection"
          editable={isTextEditable}
          loanNos={loanData ? loanData.getAllLoanNumbers : []}
          refetchBorrowerName={refetchBorrowerName}
          handleLoanNoChange={handleLoanNoChange}
          borrowerName={borrowerName}
          selectedDate={selectedDate2}
          setSelectedDate={setSelectedDate2}
        />
      </Box>
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDelete}
        entity={entityToDisplay}
        entityname="Collection"
      />
    </div>
  );
};

export default Collection;
