import React, { useState, useEffect, useMemo } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Box,
  IconButton,
  Typography,
  MenuItem,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  TEXT_MESSAGES,
  Receipt_msg,
  labels,
  required_fields,
} from "../constant";
import themestyle from "../theme";
import { ReceiptModalFormData } from "../Interfaces/ReceiptModalform";
import {
  ReceiptModalProps,
  Borrower,
  Depositor,
  Shareholder,
  Voucher,
  RoleType,
} from "../Interfaces/ReceiptModalProps";
import { useMediaQuery, useTheme } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextFieldProps } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import PheonixTextarea from "./PheonixTextArea";
import { receiptTypes } from "../constant";
const ReceiptModal: React.FC<ReceiptModalProps> = ({
  open,
  add,
  onClose,
  onAdd,
  fields,
  formData,
  setFormData,
  fieldErrors,
  setFieldErrors,
  title,
  roleTypes,
  editable = false,
  borrowers,
  depositors,
  shareholders,
  vouchers,
  onRoleTypeChange,
  loanNumbers = [],
  refetchLoanNumbers,
  selectedDate,
  setSelectedDate,
  amountdata,
  refetchAmount,
  depositamountdata,
  refetchDepositamount,
}) => {
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [initialFormData, setInitialFormData] = useState<ReceiptModalFormData>({
    ...formData,
  });
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const width = isMobileSmall ? "100%" : isTablet ? "318px" : "330px";
  const today = dayjs();
  const twoDaysAgo = dayjs().subtract(2, "day");
  useEffect(() => {
    if (open) {
      const updatedFormData = { ...formData };
      setInitialFormData(updatedFormData);
      setFormData(updatedFormData);
    }
  }, [open]);

  useEffect(() => {
    if (
      amountdata &&
      formData["Loan No"] !== null &&
      formData["Loan No"] !== "" &&
      formData["Receipt Type"] !== "Monthly Loan Principal amount" && (title === Receipt_msg.MODAL_TITLE )
      && formData["Receipt Type"] !== "Penality interest"
    ) {
      setFormData((prevData) => ({
        ...prevData,
        Amount: amountdata.toString(),
      }));
    }
  }, [amountdata, formData["Loan No"]]);

  useEffect(() => {
    const normalizeData = (data: ReceiptModalFormData) => ({
      ...data,
      Amount:
        typeof data.Amount === "string" ? parseFloat(data.Amount) : data.Amount,
      Date: data.Date || "",
    });
    const initialNormalizedData = normalizeData(initialFormData);
    const currentNormalizedData = normalizeData({ ...formData });
    const isFormDataChanged =
      JSON.stringify(initialNormalizedData) !==
      JSON.stringify(currentNormalizedData);
    setIsConfirmDisabled(!isFormDataChanged);
  }, [formData, initialFormData]);

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => error);
    setIsButtonDisabled(hasErrors);
  }, [fieldErrors]);
  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => error);
    setIsButtonDisabled(hasErrors);
  }, [fieldErrors]);

  useEffect(() => {
    const requiredFields = getRequiredFields();
    const hasEmptyRequiredFields = requiredFields.some((key) => !formData[key]);  
    setIsConfirmDisabled(hasEmptyRequiredFields);
  }, [formData,initialFormData]);

  const getRequiredFields = () => {
    const requiredFields: (keyof ReceiptModalFormData)[] = [...required_fields];
    if (currentRoleType === labels.BORROWER) {
      requiredFields.push(labels.LOAN_NO);
      requiredFields.push(labels.Receipt_Type);
    }
    return requiredFields;
  };
  const validateField = (label: keyof ReceiptModalFormData, value: string) => {
    let error = "";
    const requiredFields = getRequiredFields();
    if (!value && requiredFields.includes(label)) {
      if (
        label === labels.B_NAME ||
        label === labels.D_NAME ||
        label === labels.S_NAME
      ) {
        error = Receipt_msg.NAME_REQ;
      } else {
        error = `${label} is required`;
      }
    } else if (label === "Amount" && value) {
      if (/[a-zA-Z]/.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR1;
      } else if (/\./.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR3;
      } else if (!/^\d+(\d{1,2})?$/.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR2;
      }

    }
     else {
      error = "";
    }
    setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: error }));
  };
  const handleBlur = (label: keyof ReceiptModalFormData) => {
    const value = formData[label];
    validateField(label, value);
  };
  const handleDateChange = (newDate: Dayjs | null) => {
    if (setSelectedDate) {
      setSelectedDate(newDate);
    }
    const formattedDate = newDate ? newDate.format("DD-MM-YYYY") : "";
    handleChange("Date", formattedDate);
  };
  const [currentLabel, setCurrentLabel] = useState<keyof ReceiptModalFormData>(
    labels.B_NAME
  );
  const [currentRoleType, setCurrentRoleType] = useState<string>("");
  const handleRoleTypeChange = (roleType: RoleType) => {
  
    const updatedFormData: ReceiptModalFormData = {
      ...formData,
      "Select Receipt role type": roleType,
      "Borrower ID":
        roleType === labels.BORROWER ? formData["Borrower ID"] : "",
      "Depositor ID":
        roleType === labels.DEPOSITOR ? formData["Depositor ID"] : "",
      "Shareholder ID":
        roleType === labels.SHAREHOLDER ? formData["Shareholder ID"] : "",
      [labels.B_NAME]:
        roleType === labels.BORROWER ? formData[labels.B_NAME] : "",
      [labels.D_NAME]:
        roleType === labels.DEPOSITOR ? formData[labels.D_NAME] : "",
      [labels.S_NAME]:
        roleType === labels.SHAREHOLDER ? formData[labels.S_NAME] : "",
      [labels.LOAN_NO]:
        roleType === labels.BORROWER ? formData[labels.LOAN_NO] : "",
    };

    setFormData(updatedFormData);
    setCurrentRoleType(roleType);
    
    switch (roleType) {
      case labels.BORROWER:
        setCurrentLabel(labels.B_NAME);
        break;
      case labels.DEPOSITOR:
        setCurrentLabel(labels.D_NAME);
        break;
      case labels.SHAREHOLDER:
        setCurrentLabel(labels.S_NAME);
        break;
      default:
        setCurrentLabel(labels.B_NAME);
    }
  };

  const handleCloseModal = () => {
    setFormData((prevData) => ({
      ...prevData,
      Amount: "",
    }));
    onClose();
  };

  const handleChange = async (
    label: keyof ReceiptModalFormData,
    value: string
  ) => {
    if (label === labels.Role_type) {
     
      handleRoleTypeChange(value as RoleType);
      setFormData((prevData) => ({
        ...prevData,
        Amount: "",
        "Receipt Type" : "",
      }));
      return;
    }

    if (label === labels.Receipt_Type) {
      loanNumbers = [];
      refetchLoanNumbers();
    }
 
    switch (label) {
      case labels.B_NAME:
        const selectedBorrower = borrowers.find(
          (borrower) => borrower.borrower_name === value
        );
        if (selectedBorrower) {
          setFormData((prevData) => ({
            ...prevData,
            [label]: selectedBorrower.borrower_name,
            "Borrower ID": selectedBorrower.borrower_id,
          }));
        } else {
          setFormData((prevData) => ({
            ...prevData,
            [label]: value,
            "Borrower ID": "",
          }));
        }
        break;
      case labels.D_NAME:
        const selectedDepositor = depositors.find(
          (depositor) => depositor.depositor_name === value
        );
        if (selectedDepositor) {
          setFormData((prevData) => ({
            ...prevData,
            [label]: selectedDepositor.depositor_name,
            "Depositor ID": selectedDepositor.depositor_id,
          }));
        } else {
          setFormData((prevData) => ({
            ...prevData,
            [label]: value,
            "Depositor ID": "",
          }));
        }
        break;
      case labels.S_NAME:
        const selectedShareholder = shareholders.find(
          (shareholder) => shareholder.shareholder_name === value
        );
        if (selectedShareholder) {
          setFormData((prevData) => ({
            ...prevData,
            [label]: selectedShareholder.shareholder_name,
            "Shareholder ID": selectedShareholder.shareholder_id,
          }));
        } else {
          setFormData((prevData) => ({
            ...prevData,
            [label]: value,
            "Shareholder ID": "",
          }));
        }
        break;
      default:
        setFormData((prevData) => ({ ...prevData, [label]: value }));
        break;
    }
    validateField(label, value);
  };
  const roleOptions = useMemo(() => {
    const roleType = formData[labels.Role_type];
    switch (roleType) {
      case labels.BORROWER:
        const borrowerOptions = borrowers.map((borrower) => ({
          label: `${borrower.borrower_id} - ${borrower.borrower_name}`,
          value: `${borrower.borrower_id}`,
        }));
        return borrowerOptions;
      case labels.DEPOSITOR:
        const depositorOptions = depositors.map((depositor) => ({
          label: `${depositor.depositor_id} - ${depositor.depositor_name}`,
          value: `${depositor.depositor_id}`,
        }));
        return depositorOptions;
      case labels.SHAREHOLDER:
        const shareholderOptions = shareholders.map((shareholder) => ({
          label: `${shareholder.shareholder_id} - ${shareholder.shareholder_name}`,
          value: `${shareholder.shareholder_id}`,
        }));
        return shareholderOptions;
      default:
        return [];
    }
  }, [formData[labels.Role_type], borrowers, depositors, shareholders]);

  const isLoanNumberDisabled =
    formData[labels.Role_type] === "Depositor" ||
    formData[labels.Role_type] === "Shareholder";
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseModal}
      PaperProps={{
        sx: {
          width: isMobileSmall ? "100%" : "700px",
          border: "none",
          boxShadow: 24,
          marginLeft: isMobileSmall ? "46px" : 0,
          marginRight: isMobileSmall ? "46px" : 0,
          position: isMobileSmall ? "relative" : "none",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            style={{
              fontWeight: 700,
              fontSize: isMobileSmall ? "18px" : "24px",
              fontFamily: "Inter",
            }}
          >
            {title}
          </Typography>
          <IconButton
            onClick={onClose}
            style={{
              marginLeft: isMobileSmall ? "46px" : 0,
              marginRight: isMobileSmall ? "46px" : 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          style={{ marginRight: isMobileSmall ? "46px" : 0 }}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => {
              const shouldRenderField =
                (field.label === labels.LOAN_NO && !isLoanNumberDisabled) ||
                (field.label === labels.Receipt_Type &&
                  !isLoanNumberDisabled) ||
                (field.label === labels.date && !isLoanNumberDisabled) ||
                (field.label !== labels.LOAN_NO &&
                  field.label !== labels.Receipt_Type &&
                  field.label !== labels.date);

              return (
                shouldRenderField && (
                  <Grid
                    item
                    xs={12}
                    sm={labels.required.includes(field.label) ? 12 : 6}
                    key={index}
                    style={{ display: shouldRenderField ? "block" : "none" }}
                  >
                    {field.label === Receipt_msg.SELECT_ROLE ? (
                      <TextField
                        select
                        fullWidth
                        label={`${field.label} *`}
                        value={
                          formData[
                            field.label as keyof ReceiptModalFormData
                          ] as string
                        }
                        onChange={(e) =>
                          handleChange(
                            field.label as keyof ReceiptModalFormData,
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          handleBlur(field.label as keyof ReceiptModalFormData)
                        }
                        error={
                          !!fieldErrors[
                            field.label as keyof ReceiptModalFormData
                          ]
                        }
                        helperText={
                          fieldErrors[field.label as keyof ReceiptModalFormData]
                        }
                        variant="outlined"
                        style={{ marginTop: "5px" }}
                        InputProps={{ readOnly: editable }}
                      >
                        {roleTypes.map((roleType, index) => (
                          <MenuItem key={index} value={roleType}>
                            {roleType}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : labels.NAMES.includes(field.label) ? (
                      <Autocomplete
                        fullWidth
                        value={
                          !add
                            ? {
                                label: `${formData["Borrower ID"]} - ${formData.Name}`,
                                value: formData["Borrower ID"],
                              }
                            : roleOptions.find(
                                (option) =>
                                  option.value ===
                                  formData[
                                    field.label as keyof ReceiptModalFormData
                                  ]
                              ) || null
                        }
                        readOnly={editable}
                        onChange={(event, newValue) => {
                          handleChange(
                            field.label as keyof ReceiptModalFormData,
                            newValue ? newValue.value : ""
                          );
                        }}
                        onBlur={() =>
                          handleBlur(field.label as keyof ReceiptModalFormData)
                        }
                        options={roleOptions}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Name *"
                            variant="outlined"
                            error={
                              !!fieldErrors[
                                field.label as keyof ReceiptModalFormData
                              ]
                            }
                            helperText={
                              fieldErrors[
                                field.label as keyof ReceiptModalFormData
                              ]
                            }
                            InputProps={{
                              ...params.InputProps,
                              readOnly: editable,
                            }}
                          />
                        )}
                        style={{ marginTop: "5px" }}
                      />
                    ) : field.label === labels.LOAN_NO ? (
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        label={`${labels.LOAN_NO} *`}
                        value={formData[labels.LOAN_NO]}
                        onChange={(e) =>
                          handleChange(labels.LOAN_NO, e.target.value)
                        }
                        onBlur={() => handleBlur(labels.LOAN_NO)}
                        error={Boolean(fieldErrors[labels.LOAN_NO])}
                        helperText={fieldErrors[labels.LOAN_NO]}
                        disabled={isLoanNumberDisabled}
                        InputProps={{ readOnly: editable }}
                        style={{ marginTop: "6px" }}
                      >
                        {loanNumbers.map((loanNo) => (
                          <MenuItem key={loanNo} value={loanNo}>
                            {loanNo}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : field.label === labels.Receipt_Type ? (
                      <TextField
                        select
                        fullWidth
                        label={`${field.label} *`}
                        value={
                          formData[
                            field.label as keyof ReceiptModalFormData
                          ] as string
                        }
                        onChange={(e) =>
                          handleChange(
                            field.label as keyof ReceiptModalFormData,
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          handleBlur(field.label as keyof ReceiptModalFormData)
                        }
                        error={
                          !!fieldErrors[
                            field.label as keyof ReceiptModalFormData
                          ]
                        }
                        helperText={
                          fieldErrors[field.label as keyof ReceiptModalFormData]
                        }
                        variant="outlined"
                        style={{ marginTop: "5px" }}
                        InputProps={{ readOnly: editable }}
                      >
                        {receiptTypes.map((receiptType, index) => (
                          <MenuItem key={index} value={receiptType}>
                            {receiptType}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : field.label === labels.date ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label={`${labels.up_to_date} *`}
                            format="DD/MM/YYYY"
                            value={selectedDate}
                            onChange={handleDateChange}
                            minDate={twoDaysAgo}
                            maxDate={today}
                            disabled={editable}
                            slotProps={{
                              field: {
                                readOnly: true,
                              },
                            }}
                            sx={{ width }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    ) : field.label === labels.Remarks ? (
                      <PheonixTextarea
                        label={field.label}
                        value={
                          formData[
                            field.label as keyof ReceiptModalFormData
                          ] as string
                        }
                        onChange={(e) =>
                          handleChange(
                            field.label as keyof ReceiptModalFormData,
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          handleBlur(field.label as keyof ReceiptModalFormData)
                        }
                        error={
                          !!fieldErrors[
                            field.label as keyof ReceiptModalFormData
                          ]
                        }
                        helperText={
                          fieldErrors[field.label as keyof ReceiptModalFormData]
                        }
                        editable={editable}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        label={`${field.label} *`}
                        value={
                          formData[
                            field.label as keyof ReceiptModalFormData
                          ] as string
                        }
                        onChange={(e) =>
                          handleChange(
                            field.label as keyof ReceiptModalFormData,
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          handleBlur(field.label as keyof ReceiptModalFormData)
                        }
                        error={
                          !!fieldErrors[
                            field.label as keyof ReceiptModalFormData
                          ]
                        }
                        helperText={
                          fieldErrors[field.label as keyof ReceiptModalFormData]
                        }
                        variant="outlined"
                        style={{ marginTop: "5px" }}
                        type="text"
                        InputProps={{ readOnly: editable }}
                      />
                    )}
                  </Grid>
                )
              );
            })}
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions style={{ marginRight: isMobileSmall ? "46px" : 0 }}>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{
            backgroundColor: !editable
              ? themestyle.colors.white
              : themestyle.colors.primary,
            color: !editable
              ? themestyle.colors.primary
              : themestyle.colors.white,
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            marginBottom: "15px",
            marginRight: "15px",
            padding: "6px 16px",
            width: "76px",
            height: "36px",
          }}
        >
          {TEXT_MESSAGES.CLOSE}
        </Button>
        {!editable && (
          <Button
            onClick={onAdd}
            disabled={(isConfirmDisabled || isButtonDisabled)}
            style={{
              backgroundColor: (isConfirmDisabled || isButtonDisabled) ? "lightgray" : "#208BEE",
              color: "#ffffff",
              fontWeight: 500,
              textTransform: "uppercase",
              fontSize: "14px",
              marginBottom: "15px",
              marginRight: "15px",
              padding: "6px 16px",
              width: "76px",
              height: "36px",
            }}
            variant="contained"
          >
            {TEXT_MESSAGES.SAVE}
          </Button>
        )}
      </DialogActions>
    </Drawer>
  );
};
export default ReceiptModal;
