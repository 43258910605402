import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";
import PheonixPaper from "../Components/PheonixPaper";
import themestyle from "../theme";
import { TEXT_MESSAGES } from "../constant";
import { useNavigate, useLocation } from "react-router-dom";
import DetailRow from "./BorrowerDetailsRow";
import DetailsRowdata from "./DetailsRow";
import backarrow from "../assets/backarrow.svg";
import personIcon from "../assets/personicon.svg";
import idIcon from "../assets/borroweridicon.svg";
import phoneIcon from "../assets/contacticon.svg";
import loandetailsicon from "../assets/loandetailsicon.svg";
import locationicon from "../assets/locationicon.svg";
import { GET_LOAN_DETAILS } from "../graphql/queries";
import { useQuery } from "@apollo/client";
import {
  LoanDetails,
  OtherDetails,
  LoanResponse,
} from "../Interfaces/Loandetails";
const BorrowerDetails: React.FC = () => {
  const location = useLocation();
  const borrower = location.state?.borrower;
  const totaldetails = location.state?.totalDetails;
  const pagedetails = location.state?.paginationModel;
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [loanDetails, setLoanDetails] = useState<LoanDetails | null>(null);
  const [otherDetails, setOtherDetails] = useState<OtherDetails | null>(null);
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();
  const totalLoanNos = totaldetails?.total_loan_nos || [];
  const selectedLoanNumber =
    selectedTab > 0 ? totalLoanNos[selectedTab - 1] : "N/A";
  const [receiptNo, setReceiptNo] = useState(null);
  const {
    loading: loannoLoading,
    error: loannoError,
    data: loannoData,
    refetch: refetchLoanNumbers,
  } = useQuery(GET_LOAN_DETAILS, {
    variables: { loan_no: selectedLoanNumber },
    skip: !selectedLoanNumber,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const { loanDetails, receipt_nos2 } = data.getLoanDetails;
      setLoanDetails(loanDetails.loan);
      setOtherDetails(loanDetails.others);
      setReceiptNo(receipt_nos2);
    },
  });

  useEffect(() => {
    if (refetchLoanNumbers && selectedLoanNumber) {
      refetchLoanNumbers({ loan_no: selectedLoanNumber });
    }
  }, [selectedTab, selectedLoanNumber, refetchLoanNumbers]);
  useEffect(() => {
    refetchLoanNumbers({ loan_no: selectedLoanNumber });
  }, [selectedLoanNumber]);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleBack = () => {
    navigate("/borrowers", { state: { page: pagedetails } });
  };

  if (!borrower) {
    return (
      <Box>
        <Typography variant="h6">{TEXT_MESSAGES.No_Borrower}</Typography>
      </Box>
    );
  }
  const loanNumbers = borrower.loan_numbers || [];
  const loanTabs = totalLoanNos.map((loan: number, index: number) => (
    <Tab
      key={index}
      label={`Loan - ${loan}`}
      style={{ textTransform: "none", fontSize: "18px", fontWeight: 500 }}
      sx={{
        color: "black",
        fontWeight: selectedTab === index + 1 ? "bold" : "normal",
        "&.Mui-selected": {
          color: "black",
          textTransform: "none",
        },
      }}
    />
  ));
  const hasNonEmptyStringValues = (obj: any) =>
    obj &&
    Object.values(obj).some(
      (value) => typeof value === "string" && value.trim() !== ""
    );

  const hasAddress1Details = hasNonEmptyStringValues(borrower?.address1);
  const hasAddress2Details = hasNonEmptyStringValues(borrower?.address2);
  const hasOtherDetails =
    otherDetails &&
    Object.values(otherDetails).some((value) => value?.trim() !== "");

  const isOtherDetailsEmpty =
    !otherDetails ||
    ((!otherDetails.guarantee_name ||
      otherDetails.guarantee_name.trim() === "") &&
      (!otherDetails.guarantee_address1 ||
        otherDetails.guarantee_address1.trim() === "") &&
      (!otherDetails.guarantee_address2 ||
        otherDetails.guarantee_address2.trim() === "") &&
      (!otherDetails.guarantee_phone1 ||
        otherDetails.guarantee_phone1.trim() === "") &&
      (!otherDetails.guarantee_phone2 ||
        otherDetails.guarantee_phone2.trim() === "") &&
      (!otherDetails.document_details ||
        otherDetails.document_details.trim() === ""));

  return (
    <Box style={{ padding: "15px" }}>
      {isWideScreen ? (
        <PheonixPaper
          style={{
            borderRadius: "10px",
            height: "70px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color: themestyle.colors.black,
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {TEXT_MESSAGES.BORROWER_DETAILS}
            </Typography>
            <div>
              <img src={backarrow} alt="arrow" />
              <Button
                variant="text"
                onClick={handleBack}
                style={{
                  marginBottom: "10px",
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#208BEE",
                  textDecoration: "underline",
                }}
              >
                {TEXT_MESSAGES.BACK_TO1}
              </Button>
            </div>
          </div>
        </PheonixPaper>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            padding: isMobileSmall ? "15px 0" : "15px 30px",
          }}
        >
          <Typography
            style={{
              color: themestyle.colors.black,
              marginRight: "10px",
              fontWeight: themestyle.fontWeight.sm,
              fontSize: isMobileSmall
                ? themestyle.fontSizes.sm
                : themestyle.fontSizes.medium,
            }}
          >
            {TEXT_MESSAGES.BORROWER_DETAILS}
          </Typography>
          <img src={backarrow} alt="arrow" />
          <Button
            variant="text"
            onClick={handleBack}
            style={{
              marginBottom: "10px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              color: "#208BEE",
              textDecoration: "underline",
            }}
          >
            {TEXT_MESSAGES.BACK_TO1}
          </Button>
        </div>
      )}
      <Box
        sx={{
          display: "flex",
          backgroundColor: themestyle.colors.white,
          marginBottom: "3px",
          padding: isMobileSmall ? "0px 10px" : "20px 20px 20px 40px",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="tabs"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              backgroundColor: "primary",
              height: "3px",
              textTransform: "none",
            },
          }}
        >
          <Tab
            label="Personal Details"
            sx={{
              color: "black",
              fontSize: "18px",
              fontWeight: selectedTab === 0 ? "bold" : "normal",
              textTransform: "none",
              "&.Mui-selected": {
                color: "black",
              },
            }}
          />
          {totalLoanNos.map((loan: any, index: any) => (
            <Tab
              key={index}
              label={`Loan - ${loan}`}
              sx={{
                color: "black",
                fontSize: "18px",
                fontWeight: selectedTab === index + 1 ? "bold" : "normal",
                textTransform: "none",
                "&.Mui-selected": {
                  color: "black",
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Box
        style={{
          padding: isMobileSmall ? "10px 40px" : "20px 20px 20px 50px",
          borderRadius: "8px",
          maxHeight: isMobileSmall ? "57vh" : isTablet ? "320px" : "48vh",
          overflowX: "hidden",
          overflowY: "auto",
          backgroundColor: themestyle.colors.white,
        }}
      >
        {selectedTab === 0 && borrower && (
          <>
            <Grid container spacing={2}>
              <DetailRow
                label="Borrower Name"
                value={borrower.borrower_name}
                iconSrc={personIcon}
              />
              <DetailRow
                label="Borrower ID"
                value={borrower.borrower_id}
                iconSrc={idIcon}
              />
              <DetailRow
                label="Contact Number"
                value={borrower.phone1}
                iconSrc={phoneIcon}
              />
              <DetailRow
                label="Secondary Number"
                value={borrower.phone2}
                iconSrc={phoneIcon}
              />
              {hasAddress1Details && (
                <DetailRow
                  label="Address 1"
                  value={borrower.address1}
                  iconSrc={locationicon}
                />
              )}
              {hasAddress2Details && (
                <DetailRow
                  label="Address 2"
                  value={borrower.address2}
                  iconSrc={locationicon}
                />
              )}
            </Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: isMobileSmall ? "" : "30px",
              }}
            >
              <img
                src={loandetailsicon}
                alt=""
                style={{ marginRight: 8, width: 28, height: 28 }}
              />
              <Typography
                variant="h5"
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: "18px",
                  color: "rgba(0, 0, 0, 0.87)",
                  lineHeight: "24.01px",
                }}
              >
                {TEXT_MESSAGES.LOAN_DETAILS}
              </Typography>
            </div>
            <Grid
              container
              spacing={2}
              style={{
                marginTop: isMobileSmall ? "10px" : "20px",
                marginLeft: "20px",
              }}
            >
              <DetailRow
                label="Loan Number"
                value={
                  Array.isArray(totaldetails.total_loan_nos)
                    ? totaldetails.total_loan_nos.join(", ")
                    : totaldetails.total_loan_nos
                }
              />
              <DetailRow
                label="Total Loan amount"
                value={totaldetails.total_loan_amt}
              />
              <DetailRow
                label="Total Paid Amount"
                value={totaldetails.total_principal_paid}
              />
              <DetailRow
                label="Total Pending Amount"
                value={
                  totaldetails.total_pending_amt
                    ? totaldetails.total_pending_amt
                    : 0
                }
              />
              <DetailRow
                label="Total Penalty  Amount"
                value={
                  totaldetails.total_penalty_interest
                    ? totaldetails.total_penalty_interest
                    : 0
                }
              />
              <DetailRow
                label="Total Interest Paid"
                value={
                  totaldetails.total_interest_paid
                    ? totaldetails.total_interest_paid
                    : 0
                }
              />
            </Grid>
          </>
        )}
        {selectedLoanNumber && selectedTab !== 0 && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <img
                src={loandetailsicon}
                alt=""
                style={{ marginRight: 8, width: 28, height: 28 }}
              />
              <Typography
                variant="h5"
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: "18px",
                  color: "#000000",
                  lineHeight: "24.01px",
                }}
              >
                {TEXT_MESSAGES.LOAN_DETAILS}
              </Typography>
            </div>
            <Box style={{ padding: "15px" }}>
              <>
                <Typography
                  style={{
                    marginBottom: "10px",
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  {TEXT_MESSAGES.BORROWER_LOAN_INFO}
                </Typography>
                {loanDetails ? (
                  <Grid
                    container
                    spacing={2}
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: 700,
                      fontSize: "16px",
                    }}
                  >
                    <DetailsRowdata
                      label="Loan Type"
                      value={loanDetails.loan_type}
                    />
                    <DetailsRowdata
                      label="Loan No"
                      value={loanDetails.loan_no}
                    />
                    <DetailsRowdata
                      label="Loan Amount"
                      value={loanDetails.loan_amt}
                    />
                    <DetailsRowdata
                      label="Loan Start Date"
                      value={loanDetails.loan_start_date}
                    />
                    {loanDetails.loan_type !== "Monthly" && (
                      <DetailsRowdata
                        label="Loan End Date"
                        value={loanDetails.loan_end_date}
                      />
                    )}
                    <DetailsRowdata
                      label="Interest Rate"
                      value={`${loanDetails.interest_rate}%`}
                    />
                    {loanDetails.loan_type !== "Monthly" ? (
                      <DetailsRowdata
                        label="Interest Amount"
                        value={`${loanDetails.interest_amount} ${
                          receiptNo ? `- ${receiptNo}` : ""
                        }`}
                      />
                    ) : (
                      <DetailsRowdata
                        label="Monthly Interest"
                        value={`${loanDetails.monthly_interest}`}
                      />
                    )}
                    {loanDetails.loan_type !== "Monthly" ? (
                      <>
                        <DetailsRowdata
                          label="No. of Days"
                          value={loanDetails.number_of_days}
                        />
                        <DetailsRowdata
                          label="Current Day"
                          value={loanDetails.current_day_count}
                        />
                        <DetailsRowdata
                          label="Excess Day"
                          value={loanDetails.excess_day_count}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <DetailsRowdata
                          label="Paid Interest"
                          value={loanDetails.paid_interest}
                        />
                      </>
                    )}
                    <DetailsRowdata
                      label="Paid Amount"
                      value={loanDetails.paid_amount}
                    />
                    <DetailsRowdata
                      label="Pending Amount"
                      value={loanDetails.pending_amount}
                    />
                    {loanDetails.loan_type !== "Monthly" ? (
                      <>
                        <DetailsRowdata
                          label="Penalty Paid"
                          value={loanDetails.penaltyPaid}
                        />
                        <DetailsRowdata
                          label="Penalty Unpaid"
                          value={loanDetails.penaltyUnPaid}
                        />
                      </>
                    ) : null}
                    {loanDetails.loan_type === "Monthly" ? (
                      <>
                        <DetailsRowdata
                          label="Pending interest amount"
                          value={loanDetails.pendingmonthly_interest}
                        />
                      </>
                    ) : null}
                  </Grid>
                ) : (
                  <Typography>{TEXT_MESSAGES.NO_LOANS}</Typography>
                )}
              </>
              {!isOtherDetailsEmpty && (
                <div style={{ padding: "20px 0px" }}>
                  <hr style={{ color: "#000000" }} />
                </div>
              )}

              {otherDetails && (
                <>
                  {hasOtherDetails && (
                    <>
                      {!isOtherDetailsEmpty && (
                        <Typography
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: 700,
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        >
                          {TEXT_MESSAGES.OTHERS}
                        </Typography>
                      )}
                      <Grid container spacing={2} style={{ marginTop: "20px" }}>
                        {otherDetails.guarantee_name && (
                          <DetailsRowdata
                            label="Guarantee Name"
                            value={otherDetails.guarantee_name}
                          />
                        )}

                        {otherDetails.guarantee_address1 && (
                          <DetailsRowdata
                            label="Guarantee Address 1"
                            value={otherDetails.guarantee_address1}
                            style={{
                              flex: 1,
                              marginRight: "10px",
                              wordWrap: "break-word",
                            }}
                          />
                        )}
                        {otherDetails.guarantee_address2 && (
                          <DetailsRowdata
                            label="Guarantee Address 2"
                            value={otherDetails.guarantee_address2}
                            style={{ flex: 1, wordWrap: "break-word" }}
                          />
                        )}

                        {otherDetails.guarantee_phone1 && (
                          <DetailsRowdata
                            label="Guarantee Phone 1"
                            value={otherDetails.guarantee_phone1}
                          />
                        )}
                        {otherDetails.guarantee_phone2 && (
                          <DetailsRowdata
                            label="Guarantee Phone 2"
                            value={otherDetails.guarantee_phone2}
                          />
                        )}
                        {otherDetails.document_details && (
                          <DetailsRowdata
                            label="Document Details"
                            value={otherDetails.document_details}
                          />
                        )}
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
export default BorrowerDetails;
