import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import PheonixPaper from "../Components/PheonixPaper";
import { VoucherFormData } from "../Interfaces/VoucherFormdataProps";
import {
  GET_DEPOSITOR_BY_ID,
  GET_DEPOISTER_HISTORY_PER_PERSON,
} from "../graphql/queries";
import PheonixTable from "../Components/PheonixTable";
import themestyle from "../theme";
import { useNavigate, useLocation } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import backarrow from "../assets/backarrow.svg";
import { TEXT_MESSAGES } from "../constant";
import DetailRow from "./DetailsRow";
interface DepositorData {
    address1: string;
    address2: string;
    depositor_id: string;
    depositor_name: string;
    phone1: string;
    phone2: string;
}

const DepoistorPayment: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [tableData, setTableData] = useState<VoucherFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [depoisterNo, setDepoisterNo] = useState<number | null>(null);
  const [depositorData, setDepositorData] = useState<DepositorData | null>(null);
  const location = useLocation();


  useEffect(() => {
    if (location.state?.DepositerNO) {
      setDepoisterNo(location.state.DepositerNO);
    }
  }, [location.state]);
  const {
    loading: depositLoading,
    error: depositError,
    data: depositData,
    refetch: refetchdeposit,
  } = useQuery(GET_DEPOSITOR_BY_ID, {
    variables: { depositor_id: depoisterNo },
    onCompleted: (data) => {
        const depositorDataHistory = data;
         const depositorData: DepositorData = {
            address1: depositorDataHistory.getDepositorbyIDDetails.address1,
            address2:  depositorDataHistory.getDepositorbyIDDetails.address2,
            depositor_id: depositorDataHistory.getDepositorbyIDDetails.depositor_id,
            depositor_name: depositorDataHistory.getDepositorbyIDDetails.depositor_name,
            phone1: depositorDataHistory.getDepositorbyIDDetails.phone1,
            phone2: depositorDataHistory.getDepositorbyIDDetails.phone2,
          };
          setDepositorData(depositorData);
    },
  });

  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("DepLoanpaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });

  useEffect(() => {
    localStorage.setItem("DepLoanpaginationModel", JSON.stringify(paginationModel));
    return () => {
      localStorage.removeItem("DepLoanpaginationModel");
    };
  }, [paginationModel]);
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel: any) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const {
    loading: loanHistoryLoading,
    error: loanHistoryError,
    refetch,
  } = useQuery(GET_DEPOISTER_HISTORY_PER_PERSON, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      depoist_no: depoisterNo,
      searchQuery,
    },
    onCompleted: (data) => {
      if (data) {
        const startIndex = paginationModel.page * paginationModel.pageSize + 1;
        const transformedData = data.getAllDepoisterIDPaymentHistory.data.map(
          (item: {
            particulars: any;
            date: any;
            receiptNo: any;
            interestAmount: any;
            depositAmount: any;
            balanceAmount: any;
            depositReturn: any;
          }, index: number) => ({
            id: item.receiptNo || index,
            serial_no: startIndex + index,
            dates: item.date,
            receipt_no: item.receiptNo,
            particulars: item.particulars,
            interestamount: item.interestAmount,
            amount: item.depositAmount,
            remainingAmount: item.balanceAmount,
            totalcollectedamount: item.depositReturn,
          })
        );
  
        setTableData(transformedData);
        setTableCount(data.getAllDepoisterIDPaymentHistory.totalCount);
      }
    },
  });

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    refetch({
      depoist_no: depoisterNo,
      searchQuery: newQuery.trim() === "" ? null : newQuery,
      page: paginationModel.page,
      perPage: paginationModel.pageSize, 
    });
  };
  
  useEffect(() => {
    refetch({
      depoist_no: depoisterNo, 
      searchQuery: searchQuery.trim() === "" ? null : searchQuery,
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
    });
  }, [paginationModel, searchQuery, refetch, depoisterNo]);
  
  useEffect(() => {
    if (depoisterNo !== null) {
      setColumns([
        { field: 'serial_no', headerName: 'S.No', width: 100 },
        { field: 'dates', headerName: 'Date', flex: 1, minWidth: 150 },
        {
          field: 'receipt_no',
          headerName: 'DOC No',
          flex: 1,
          minWidth: 200,
        },
        {
            field: 'particulars',
            headerName: 'Particulars',
            flex: 1,
            minWidth: 200,
          },
        {
          field: 'interestamount',
          headerName: 'Interest Amount',
          flex: 1,
          minWidth: 200,
        },
        { field: 'amount', headerName: 'Deposit Amount', flex: 1, minWidth: 200 },
        {
          field: 'totalcollectedamount',
          headerName: 'Deposit Return',
          flex: 2,
          minWidth: 200,
        },
        {
          field: 'remainingAmount',
          headerName: 'Balance Amount',
          flex: 2,
          minWidth: 200,
        },
      ]);
    }
  }, [depoisterNo]);

  const handleBack = () => {
    navigate("/generalledger", { state: { tab: 1 } });
  };

  return (
    <Box style={{ padding: "15px" }}>
      {isWideScreen ? (
        <PheonixPaper
          style={{
            borderRadius: "10px",
            height: "70px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color: themestyle.colors.black,
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {`${TEXT_MESSAGES.DEPOSITOR_DETAILS} - ${depoisterNo}`}
            </Typography>
            <div>
              <img src={backarrow} alt="arrow" />
              <Button
                variant="text"
                onClick={handleBack}
                style={{
                  marginBottom: "10px",
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#208BEE",
                  textDecoration: "underline",
                }}
              >
                {TEXT_MESSAGES.DEPOISTOR_BUTTON}
              </Button>
            </div>
          </div>
        </PheonixPaper>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            padding: isMobileSmall ? "1px " : "15px 30px",
          }}
        >
          <Typography
            style={{
              color: themestyle.colors.black,
              marginRight: "10px",
              fontWeight: themestyle.fontWeight.sm,
              fontSize: isMobileSmall
                ? themestyle.fontSizes.sm
                : themestyle.fontSizes.medium,
            }}
          >
            {`${TEXT_MESSAGES.DEPOSITOR_DETAILS} - ${depoisterNo}`}
          </Typography>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img
              src={backarrow}
              alt="arrow"
              style={{
                width: "16px",
                height: "16px",
              }}
            />
            <Button
              variant="text"
              onClick={handleBack}
              style={{
                marginBottom: "10px",
                textTransform: "none",
                fontSize: "13px",
                fontWeight: 500,
                color: "#208BEE",
                textDecoration: "underline",
              }}
            >
              {TEXT_MESSAGES.DEPOISTOR_BUTTON}
            </Button>
          </div>
        </div>
      )}
      <Box
        style={{
          padding: isMobileSmall ? "10px" : "0px 20px 20px 0px",
          borderRadius: "8px",
          maxHeight: isMobileSmall ? "65vh" : isTablet ? "60vh" : "65vh",
          overflowX: "hidden",
          overflowY: "auto",
          backgroundColor: themestyle.colors.white,
        }}
      >
        <Box
          style={{
            padding: "20px",
            backgroundColor: themestyle.colors.white,
          }}
        >
          {depositorData && (
    <Grid container spacing={2}>
        <DetailRow label="Depositor Name" value={depositorData.depositor_name} />
        <DetailRow label="Depositor ID" value={depositorData.depositor_id} />
        <DetailRow label="Address" value={depositorData.address1 || "N/A"} />
        <DetailRow label="Primary Number" value={depositorData.phone1 || "N/A"} />
        <DetailRow label="Secondary Number" value={depositorData.phone2 || "N/A"} />
    </Grid>
)}

        </Box>
        <PheonixTable
          columns={columns}
          rows={tableData}
          rowCount={tableCount}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          loading={loanHistoryLoading}
          searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
        />
      </Box>
    </Box>
  );
};
export default DepoistorPayment;
