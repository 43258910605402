import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import QuickViewCardProps from '../Interfaces/QuickViewCardProps';
import { useMediaQuery, useTheme } from "@mui/material";

const QuickViewCard: React.FC<QuickViewCardProps> = ({ title, icon, value, linkTo }) => {
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      component={Link}
      to={linkTo}
      sx={{
        width: isMobileSmall? '85px': '150px',
        height: isMobileSmall ? "78px" : '98px',
        padding: '8px 8px',
        gap: '16px',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
     opacity: 1,
        textDecoration: 'none', 
        color: 'inherit', position: 'relative'
      }}
    >
      <img src={icon} alt={`${title} icon`} style={{ width: '32px', height: '32px' }} />
      <Typography variant="body1" align="center" style={{fontWeight:600,fontSize:"14px",fontFamily:"Roboto"}}>
        {title}
      </Typography>
      {value && (
        <Typography variant="h6" align="center">
          {value}
        </Typography>
      )}
    </Card>
  );
};

export default QuickViewCard;
