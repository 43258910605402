import React, { useState, useEffect } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid";
import PheonixTable from "../Components/PheonixTable";
import PheonixPaper from "../Components/PheonixPaper";
import VoucherModal from "../Components/VoucherModal";
import { TEXT_MESSAGES } from "../constant";
import { VoucherFormData } from "../Interfaces/VoucherFormdataProps";
import { GET_VOUCHERS, GET_ALL_BORROWERS } from "../graphql/queries";
import EditIcon from "../assets/EditFilled.png";
import VisibleIcon from "../assets/visibility.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMediaQuery, useTheme } from "@mui/material";
import DeleteModal from "../Components/DeleteModal";
import themestyle from "../theme";
import { ADD_VOUCHER, EDIT_VOUCHER, DELETE_VOUCHER } from "../graphql/mutation";
import PheonixSnackBar from "../Components/PheonixSnackBar";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logo from "../assets/Dlogo.svg";

const Vouchers: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isTextEditable, setIsTextEditable] = useState(false);
  const [selectedVoucherId, setSelectedVoucherId] = useState<string | null>(
    null
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [tableData, setTableData] = useState<VoucherFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [title, settitle] = useState("");
  const [dataLoading, setdataLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("VoucherpaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });

  useEffect(() => {
    localStorage.setItem("VoucherpaginationModel", JSON.stringify(paginationModel));
    return () => {
      localStorage.removeItem("VoucherpaginationModel");
    };
  }, [paginationModel]);
  
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [addVoucherMutation] = useMutation(ADD_VOUCHER);
  const [editVoucherMutation] = useMutation(EDIT_VOUCHER);
  const [deleteVoucherMutation] = useMutation(DELETE_VOUCHER);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");

  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: voucherRefetch,
  } = useQuery(GET_VOUCHERS, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery || ""
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, voucher } = data.getVoucherperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const vouchersWithSerialNo = voucher.map(
        (voucher: VoucherFormData, index: number) => ({
          ...voucher,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(vouchersWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });

  const {
    loading: borrowerLoading,
    error: borrowerError,
    data: borrowerData,
    refetch: borrowerRefetch,
  } = useQuery(GET_ALL_BORROWERS);
  useEffect(() => {
    setdataLoading(true);
    if (!customerLoading && !customerError && customerData) {
      const { totalPages, totalCount, voucher } =
        customerData.getVoucherperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const vouchersWithSerialNo = voucher.map(
        (voucher: VoucherFormData, index: number) => ({
          ...voucher,
          serial_no: (startIndex + index).toString(),
        })
      );

      setTableData(vouchersWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    customerData,
    customerLoading,
    customerError,
    paginationModel.page,
    paginationModel.pageSize,
  ]);
  useEffect(() => {
    voucherRefetch();
    borrowerRefetch();
  }, [voucherRefetch]);
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel:any) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const [voucherFormData1, setVoucherFormData1] = useState<VoucherFormData>({
    "Voucher No": "",
    "Borrower ID": "",
    "Borrower Name": "",
    "Borrower Address1": "",
    "Borrower Address2": "",
    "Loan Type": "",
    "Loan No": "",
    "Interest Rate": "",
    "Interest Amount": "",
    "Loan Amount": "",
    "Guarantee Name": "",
    "Guarantee Primary Number": "",
    "Guarantee Secondary Number": "",
    "Guarantee Address1": "",
    "Guarantee Address2": "",
    "Document Details": "",
    Remarks: "",
  });
  const handleOpenModal = (selectedRowData: VoucherFormData | null = null) => {
    setModalOpen(true);
    setFieldErrors({});
    if (selectedRowData) {
      setSelectedVoucherId(selectedRowData.id);
      setIsEditing(true);
      setIsTextEditable(false);
      setVoucherFormData1({
        "Borrower Name": selectedRowData.borrower_name,
        "Borrower ID":selectedRowData.borrower_id,
        "Borrower Address1": selectedRowData.address1,
        "Borrower Address2": selectedRowData.address2,
        "Loan Type": selectedRowData.loan_type,
        "Loan Amount": selectedRowData.loan_amt,
        "Loan No": selectedRowData.loan_no,
        "Voucher No": selectedRowData.voucher_no,
        "Interest Rate": selectedRowData.interest_rate
          ? selectedRowData.interest_rate
          : "3",
        "Interest Amount": selectedRowData.interest_amount,
        "Guarantee Name": selectedRowData.guarantee_name,
        "Guarantee Primary Number": selectedRowData.guarantee_phone1,
        "Guarantee Secondary Number": selectedRowData.guarantee_phone2,
        "Guarantee Address1": selectedRowData.guarantee_address1,
        "Guarantee Address2": selectedRowData.guarantee_address2,
        "Document Details": selectedRowData.document_details,
        Remarks: selectedRowData.remarks,
      });
      settitle(TEXT_MESSAGES.EDIT_VOUCHER_title);
    } else {
      setSelectedVoucherId(null);
      setIsEditing(false);
      setIsTextEditable(false);
      setVoucherFormData1({
        "Voucher No": "",
        "Borrower ID": "",
        "Borrower Name": "",
        "Borrower Address1": "",
        "Borrower Address2": "",
        "Loan Type": "",
        "Interest Rate": "",
        "Interest Amount": "",
        "Loan No": "",
        "Loan Amount": "",
        "Guarantee Name": "",
        "Guarantee Primary Number": "",
        "Guarantee Secondary Number": "",
        "Guarantee Address1": "",
        "Guarantee Address2": "",
        "Document Details": "",
        Remarks: "",
      });
      settitle(TEXT_MESSAGES.ADD_VOUCHER_title);
    }
  };
  const handleViewModal = (selectedRowData: VoucherFormData | null = null) => {
    setModalOpen(true);
    setFieldErrors({});
    if (selectedRowData) {
      setSelectedVoucherId(selectedRowData.id);
      setIsTextEditable(true);
      setVoucherFormData1({
        "Borrower Name": selectedRowData.borrower_name,
        "Borrower ID":selectedRowData.borrower_id,
        "Borrower Address1": selectedRowData.address1,
        "Borrower Address2": selectedRowData.address2,
        "Loan Type": selectedRowData.loan_type,
        "Loan Amount": selectedRowData.loan_amt,
        "Loan No": selectedRowData.loan_no,
        "Interest Amount": selectedRowData.interest_amount,
        "Voucher No": selectedRowData.voucher_no,
        "Interest Rate": selectedRowData.interest_rate
          ? selectedRowData.interest_rate
          : "3",
        "Guarantee Name": selectedRowData.guarantee_name,
        "Guarantee Primary Number": selectedRowData.guarantee_phone1,
        "Guarantee Secondary Number": selectedRowData.guarantee_phone2,
        "Guarantee Address1": selectedRowData.guarantee_address1,
        "Guarantee Address2": selectedRowData.guarantee_address2,
        "Document Details": selectedRowData.document_details,
        Remarks: selectedRowData.remarks,
      });

      settitle(TEXT_MESSAGES.VIEW_VOUCHER_TITLE);
    }
  };
  const handleCloseModal = () => {
    setFieldErrors({});
    setVoucherFormData1({
      "Voucher No": "",
      "Borrower ID": "",
      "Borrower Name": "",
      "Borrower Address1": "",
      "Borrower Address2": "",
      "Loan Type": "",
      "Loan No": "",
      "Loan Amount": "",
      "Interest Rate": "",
      "Interest Amount": "",
      "Guarantee Name": "",
      "Guarantee Primary Number": "",
      "Guarantee Secondary Number": "",
      "Guarantee Address1": "",
      "Guarantee Address2": "",
      "Document Details": "",
      Remarks: "",
    });
    setModalOpen(false);
  };

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      voucherRefetch({ variables: { searchQuery: {} } }); 
    } else {
      voucherRefetch();
    }
  };
  const handleAdd = async () => {
    setFieldErrors({});
    const loanNo = parseInt(voucherFormData1["Loan No"], 10);
    const loanAmount = parseFloat(voucherFormData1["Loan Amount"]) || 0;
    const interestRate = parseFloat(voucherFormData1["Interest Rate"]) || 0;
    const interestAmountString: string | null = localStorage.getItem("interestamount");
    const interestAmount: number = interestAmountString ? parseFloat(interestAmountString) : 0;
    const voucherInput = {
      borrower_name: voucherFormData1["Borrower Name"],
      borrower_id: voucherFormData1["Borrower ID"],
      address1: voucherFormData1["Borrower Address1"],
      address2: voucherFormData1["Borrower Address2"],
      guarantee_name: voucherFormData1["Guarantee Name"],
      guarantee_phone1: voucherFormData1["Guarantee Primary Number"],
      guarantee_phone2: voucherFormData1["Guarantee Secondary Number"],
      guarantee_address1: voucherFormData1["Guarantee Address1"],
      guarantee_address2: voucherFormData1["Guarantee Address2"],
      loan_type: voucherFormData1["Loan Type"],
      loan_amt: loanAmount,
      loan_no: loanNo,
      voucher_no: voucherFormData1["Voucher No"],
      interest_rate: interestRate,
      interest_amount: interestAmount,
      remarks: voucherFormData1["Remarks"],
      document_details: voucherFormData1["Document Details"],
    };

    try {
      if (isEditing && selectedVoucherId) {
        await editVoucherMutation({
          variables: {
            id: selectedVoucherId,
            ...voucherInput,
          },
        });
        setSnackbarMessage(TEXT_MESSAGES.VOUCHERUPDATE);
        setSnackbarSeverity("success");
      } else {
        await addVoucherMutation({
          variables: voucherInput,
        });
        setSnackbarMessage(TEXT_MESSAGES.VOUCHERCREATE);
        setSnackbarSeverity("success");
      }

      voucherRefetch();
      setSnackbarOpen(true);
      handleCloseModal();
    } catch (error: any) {
      setSnackbarMessage(error.message || TEXT_MESSAGES.ADD_EDIT_ERROR);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error(error);
    }
  };
  const handleDelete = (voucherId: string, voucherNo: string) => {
    setEntityToDelete(voucherId);
    setEntityToDisplay(voucherNo);
    setDeleteModalOpen(true);
  };
  const confirmDelete = async () => {
    if (!entityToDelete) return;

    try {
      const result = await deleteVoucherMutation({
        variables: {
          id: entityToDelete,
        },
      });

      if (result && result.data && result.data.deleteVoucher) {
        setSnackbarMessage("Voucher deleted successfully");
        setSnackbarSeverity("error");
        voucherRefetch();
      }
    } catch (error) {
      setSnackbarMessage(TEXT_MESSAGES.DELETE_ERROR);
      setSnackbarSeverity("error");
      console.error("Error during deletion:", error);
    } finally {
      setSnackbarOpen(true);
      setDeleteModalOpen(false);
      setEntityToDelete(null);
    }
  };

  function numberToIndianWords(num: number): string {
    const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const teens = [
      "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen",
    ];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const thousands = ["", "Thousand", "Lakh", "Crore"];
  
    if (num === 0) return "Zero";
  
    const convert = (n: number): string => {
      if (n < 10) return units[n];
      if (n < 20) return teens[n - 10];
      if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 ? " " + units[n % 10] : "");
      if (n < 1000) return units[Math.floor(n / 100)] + " Hundred" + (n % 100 ? " and " + convert(n % 100) : "");
      if (n < 100000) return convert(Math.floor(n / 1000)) + " Thousand" + (n % 1000 ? " " + convert(n % 1000) : "");
      if (n < 10000000) return convert(Math.floor(n / 100000)) + " Lakh" + (n % 100000 ? " " + convert(n % 100000) : "");
      if (n < 1000000000000) {
        return convert(Math.floor(n / 10000000)) + " Crore" + (n % 10000000 ? " " + convert(n % 10000000) : "");
      }
      return convert(Math.floor(n / 100000000000)) + " Lakh Crore" + (n % 100000000000 ? " " + convert(n % 100000000000) : "");
    };
  
    return convert(num).trim()+" Rupees";
  }

  const handleDownload = (row: {
    [x: string]: any;
    loan_type: string | string[];
    date: string | string[];
    borrower_name: string | string[];
    address1: string | string[];
    voucher_no: string | string[];
    borrower_id: string | string[];
    loan_amt: string | string[];
  }) => {
    const doc = new jsPDF();
    const pngImage = new Image();
    pngImage.src = logo;
  
    pngImage.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = pngImage.width;
      canvas.height = pngImage.height;
      if (ctx) {
        ctx.drawImage(pngImage, 0, 0, canvas.width, canvas.height);
      }
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdfImageWidth = 50;
      const pdfImageHeight = (pngImage.height / pngImage.width) * pdfImageWidth;
      doc.addImage(imgData, "PNG", 14, 10, pdfImageWidth, pdfImageHeight);
      doc.setFont("Helvetica", "Bold");
      doc.setFontSize(14);
      doc.text("Voucher Copy of Borrower", 130, 20);
      doc.setLineWidth(0.5);
      doc.line(14, 35, 196, 35);
      let yOffset = 35; 
      yOffset += 8; 
      doc.setFont("Helvetica", "normal");
      doc.text("Voucher No : ", 15, yOffset);
      doc.setFont("Helvetica", "bold");
      doc.text(String(row.voucher_no), 45, yOffset); 
      doc.setFont("Helvetica", "normal");
      doc.text("Date:", 157, yOffset);
      doc.setFont("Helvetica", "bold");
      doc.text(String(row.date), 170, yOffset); 
      yOffset += 8;
      doc.setFont("Helvetica", "normal");
      doc.text("Name :", 15, yOffset);
      doc.setFont("Helvetica", "bold");
      doc.text(String(row.borrower_name), 33, yOffset);
      yOffset += 8;
      if (row.address1 && row.address1.length > 0) {
        doc.setFont("Helvetica", "normal");
        doc.text("Address :", 15, yOffset);
        doc.setFont("Helvetica", "bold");
        const address = Array.isArray(row.address1) ? row.address1.join('\n') : row.address1;
        const pageWidth = doc.internal.pageSize.width; 
        const marginLeft = 38;
        const textWidth = pageWidth - marginLeft - 15; 
        const wrappedAddress = doc.splitTextToSize(address, textWidth);
        wrappedAddress.forEach((line: string) => {
          doc.text(line, marginLeft, yOffset);
          yOffset += 8;
        });
      }
      doc.setFont("Helvetica", "normal");
      doc.text("Loan Type :", 15, yOffset);
      doc.setFont("Helvetica", "bold");
      doc.text(`${row.loan_type} Loan`, 43, yOffset);
  
      yOffset += 9;
  
      doc.setFont("Helvetica", "bold");
      const tableColumn = ["Borrower Loan No", "Particulars", "Amount"];
      const tableRows: any[] = [
        [
          row.loan_no,
          `${row.loan_type} Loan towards`,
          row.loan_amt,
        ],
      ];
      
      (doc as any).autoTable({
        startY: yOffset,
        head: [tableColumn],
        body: tableRows,
        theme: "plain",
        styles: {
          halign: "left",
          font: "Helvetica",
          fontStyle: "normal",
          textColor: [0, 0, 0],
          fontSize: 12,
          fillColor: [240, 240, 240],
        },
        alternateRowStyles: {
          fillColor: [255, 255, 255],
        },
        headStyles: {
          fillColor: [240, 240, 240],
          font: "Helvetica",
          fontStyle: "Bold",
          textColor: [0, 0, 0],
          fontSize: 14,
        },
        tableLineColor: [255, 255, 255],
        tableLineWidth: 0,
      });

      yOffset += 27; 
      doc.setFont("Helvetica", "normal");
      doc.text("Loan Amount :", 15, yOffset);
      doc.setFont("Helvetica", "bold");
      const loanAmountInWords = numberToIndianWords(Number(row.loan_amt));
      const pageWidth = doc.internal.pageSize.width; 
      const marginLeft = 50;
      const textWidth = pageWidth - marginLeft - 15; 
      const wrappedLoanAmount = doc.splitTextToSize(loanAmountInWords, textWidth);
      wrappedLoanAmount.forEach((line: string) => {
        doc.text(line, marginLeft, yOffset);
        yOffset += 8; 
      });
      doc.save("Voucher Copy of Borrower");
    };
  };
  
  const fields = [
    { label: "Borrower Name", type: "text" },
    { label: "Borrower Address1", type: "text" },
    { label: "Borrower Address2", type: "text" },
    { label: "Loan Type", type: "text" },
    { label: "Voucher No", type: "text" },
    { label: "Loan No", type: "text" },
    { label: "Loan Amount", type: "text" },
    { label: "Interest Rate", type: "text" },
    { label: "Interest Amount", type: "text" },
    { label: "Guarantee Name", type: "text" },
    { label: "Guarantee Primary Number", type: "text" },
    { label: "Guarantee Secondary Number", type: "text" },
    { label: "Guarantee Address1", type: "text" },
    { label: "Guarantee Address2", type: "text" },
    { label: "Document Details", type: "text" },
    { label: "Remarks", type: "text" },
  ];
  const requiredFields = [
    "Borrower Name",
    "Loan Amount",
    "Loan Type",
    "Voucher No",
    "Loan No",
    "Interest Amount",
  ] as (keyof VoucherFormData)[];
  const columns: GridColDef[] = [
    { field: "serial_no", headerName: "S.No", width: 100 },
    { field: "date", headerName: "Date", flex: 1, minWidth: 200 },
    {
      field: "voucher_no",
      headerName: "Voucher No",
      flex: 1,
      minWidth: 150,
    },
    { field: "borrower_id", headerName: "Borrower ID", flex: 1, minWidth: 150 },
    {
      field: "borrower_name",
      headerName: "Borrower Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "address1",
      headerName: "Borrower Address1",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "address2",
      headerName: "Borrower Address2",
      flex: 1,
      minWidth: 200,
    },
    { field: "loan_no", headerName: "Loan No", flex: 2, minWidth: 150 },
    { field: "loan_type", headerName: "Loan Type", flex: 2, minWidth: 200 },
    { field: "loan_amt", headerName: "Loan Amount", flex: 1, minWidth: 200 },
    {
      field: "interest_rate",
      headerName: "Interest Rate",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginLeft: "-8px",
            marginTop: "8px",
          }}
        >
          <IconButton onClick={() => handleViewModal(params.row)}>
            <img
              src={VisibleIcon}
              alt="View"
              style={{ width: "20px", height: "20px" }}
            />
          </IconButton>
          <IconButton onClick={() => handleOpenModal(params.row)}>
            <img
              src={EditIcon}
              alt="Edit"
            />
          </IconButton>
          <IconButton sx={{color:"#208BEE"}} onClick={() => handleDownload(params.row)}>
           <FileDownloadOutlinedIcon/>
          </IconButton>
          <IconButton
            onClick={() => handleDelete(params.row.id, params.row.voucher_no)}
          >
            <DeleteIcon
              style={{
                width: "20px",
                height: "20px",
                color: themestyle.colors.primary,
              }}
            />
          </IconButton>
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <div>
      <Box style={{ padding: "15px" }}>
        <PheonixSnackBar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
          vertical="top"
          horizontal="center"
        />
        {isWideScreen ? (
          <PheonixPaper
            style={{
              borderRadius: "20px",
              height: "70px",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "center",
                padding: isMobileSmall ? "15px 0" : "15px 30px",
              }}
            >
              <Typography
                style={{
                  color: themestyle.colors.black,
                  fontWeight: themestyle.fontWeight.sm,
                  fontSize: isMobileSmall
                    ? themestyle.fontSizes.sm
                    : themestyle.fontSizes.medium,
                }}
              >
                {TEXT_MESSAGES.VOUCHER}
              </Typography>
              <Button
                style={{
                  background: themestyle.colors.primary,
                  color: themestyle.colors.white,
                  width: "160px",
                }}
                onClick={() => handleOpenModal(null)}
              >
                {TEXT_MESSAGES.ADD_VOUCHER}
              </Button>
            </div>
          </PheonixPaper>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color: themestyle.colors.black,
                marginRight: "10px",
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {TEXT_MESSAGES.VOUCHER}
            </Typography>
            <Button
              style={{
                background: themestyle.colors.primary,
                color: themestyle.colors.white,
                width: isWideScreen ? "142px" : "160px",
              }}
              onClick={() => handleOpenModal(null)}
            >
              {TEXT_MESSAGES.ADD_VOUCHER}
            </Button>
          </div>
        )}
        <PheonixPaper style={{ borderRadius: "20px" }}>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <PheonixTable
              columns={columns}
              rows={tableData}
              rowCount={tableCount}
              paginationMode="server"
              paginationModel={paginationModel}
              loading={dataLoading}
              onPaginationModelChange={handlePaginationChange}
              searchQuery ={searchQuery}
              onSearchQueryChange={handleSearchQueryChange}

            />
          </div>
        </PheonixPaper>
        <VoucherModal
          open={modalOpen}
          onClose={handleCloseModal}
          onAdd={handleAdd}
          title={title}
          fields={fields}
          formData={voucherFormData1}
          setFormData={setVoucherFormData1}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
          requiredFields={requiredFields}
          editable={isTextEditable}
          borrowers={borrowerData ? borrowerData.getAllBorrowers : []}
        />
        <DeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          entity={entityToDisplay}
          entityname="Voucher"
        />
      </Box>
    </div>
  );
};
export default Vouchers;