import React, { useState, useEffect } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid";
import PheonixTable from "../Components/PheonixTable";
import PheonixPaper from "../Components/PheonixPaper";
import PhoenixModal from "../Components/PheonixModal";
import { TEXT_MESSAGES } from "../constant";
import { ModalFormData } from "../Interfaces/ModalFormdataProps";
import { GET_SHAREHOLDERS } from "../graphql/queries";
import EditIcon from "../assets/EditFilled.png";
import VisibleIcon from "../assets/visibility.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMediaQuery, useTheme } from "@mui/material";
import themestyle from "../theme";
import {
  ADD_SHAREHOLDER,
  EDIT_SHAREHOLDER,
  DELETE_SHAREHOLDER,
} from "../graphql/mutation";
import PheonixSnackBar from "../Components/PheonixSnackBar";
import DeleteModal from "../Components/DeleteModal";
const Shareholders: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [tableData, setTableData] = useState<ModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [title, settitle] = useState("");
  const [dataLoading, setdataLoading] = useState(false); 
  const [isEditing, setIsEditing] = useState(false);
  const [isTextEditable, setIsTextEditable] = useState(false);
  const [selectedShareholderId, setSelectedShareholderId] = useState<
    string | null
  >(null);
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("SharepaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });

  useEffect(() => {
    localStorage.setItem("SharepaginationModel", JSON.stringify(paginationModel));
    return () => {
      localStorage.removeItem("SharepaginationModel");
    };
  }, [paginationModel]);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [addShareholderMutation] = useMutation(ADD_SHAREHOLDER);
  const [editShareholderMutation] = useMutation(EDIT_SHAREHOLDER);
  const [deleteShareholderMutation] = useMutation(DELETE_SHAREHOLDER);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: shareholderRefetch,
  } = useQuery(GET_SHAREHOLDERS, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery || ""
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, shareholder } =
        data.getShareholderperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const shareholderWithSerialNo = shareholder.map(
        (shareholder: ModalFormData, index: number) => ({
          ...shareholder,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(shareholderWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });

  useEffect(() => {
    setdataLoading(true);
    if (!customerLoading && !customerError && customerData) {
      const { totalPages, totalCount, shareholder } =
        customerData.getShareholderperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const depositorsWithSerialNo = shareholder.map(
        (shareholder: ModalFormData, index: number) => ({
          ...shareholder,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(depositorsWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    customerData,
    customerLoading,
    customerError,
    paginationModel.page,
    paginationModel.pageSize,
  ]);

  useEffect(() => {
    shareholderRefetch();
  }, [shareholderRefetch]);

  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel:any) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const handleOpenModal = (selectedRowData: ModalFormData | null = null) => {
    setModalOpen(true);
    setFieldErrors({});
    if (selectedRowData) {
      setSelectedShareholderId(selectedRowData.id);
      setIsEditing(true);
      setIsTextEditable(false);
      setShareholderFormData1({
        "Shareholder Name": selectedRowData.shareholder_name,
        "Occupation": selectedRowData.occupation,
        "Primary Number": selectedRowData.phone1,
        "Secondary Number": selectedRowData.phone2,
        "Address 1": selectedRowData.address1,
        "Address 2": selectedRowData.address2,
        "Remarks": selectedRowData.remarks,
      });
      settitle(TEXT_MESSAGES.EDIT_SHAREHOLDER_title);
    } else {
      setSelectedShareholderId(null);
      setIsEditing(false);
      setIsTextEditable(false);
      setShareholderFormData1({
        "Depositor Name": "",
        Occupation: "",
        "Share Percent":"",
        "Primary Number": "",
        "Secondary Number": "",
        "Address 1": "",
        "Address 2": "",
        "Remarks":""
      });
      settitle(TEXT_MESSAGES.ADD_SHAREHOLDER_title);
    }
  };

  const handleViewModal = (selectedRowData: ModalFormData | null = null) => {
    setModalOpen(true);
    setFieldErrors({});
    if (selectedRowData) {
      setSelectedShareholderId(selectedRowData.id);
      setIsTextEditable(true);
      setShareholderFormData1({
        "Shareholder Name": selectedRowData.shareholder_name,
        Occupation: selectedRowData.occupation,
        "Primary Number": selectedRowData.phone1,
        "Secondary Number": selectedRowData.phone2,
        "Address 1": selectedRowData.address1,
        "Address 2": selectedRowData.address2,
        "Remarks": selectedRowData.remarks,
      });
      settitle(TEXT_MESSAGES.VIEW_SHAREHOLDER_TITLE);
    }
  };
  const handleCloseModal = () => {
    setFieldErrors({});
    setShareholderFormData1({
      "Shareholder Name": "",
      Occupation: "",
      "Share Percent":"",
      "Primary Number": "",
      "Secondary Number": "",
      "Address 1": "",
      "Address 2": "",
      "Remarks":""
    });
    setModalOpen(false);
  };


  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");
  const handleDelete = (shareholderId: string, collectionNo: string) => {
    setEntityToDelete(shareholderId);
    setEntityToDisplay(collectionNo);
    setDeleteModalOpen(true);

  };

  const confirmDelete = async () => {
    if (!entityToDelete) return;
    try {
      const result = await deleteShareholderMutation({
        variables: {
          id: entityToDelete,
        },
      });
      if (result && result.data && result.data.deleteShareholder) {
        setSnackbarMessage(TEXT_MESSAGES.DELETE_SHAREHOLDER);
        setSnackbarSeverity('error');
        shareholderRefetch();
       }
    } catch (error) {
      setSnackbarMessage(TEXT_MESSAGES.DELETE_ERROR);
      setSnackbarSeverity('error');
      console.error("Error during deletion:", error);
    } finally {
      setSnackbarOpen(true);
      setDeleteModalOpen(false);
      setEntityToDelete(null);
      shareholderRefetch();
    }
  };

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      shareholderRefetch({ variables: { searchQuery: {} } }); 
    } else {
      shareholderRefetch();
    }
  };
  const handleAdd = async () => {
    setFieldErrors({});
    const shareholderInput = {
      shareholder_name: shareholderFormData1["Shareholder Name"],
      occupation: shareholderFormData1.Occupation,
      phone1: shareholderFormData1["Primary Number"],
      phone2: shareholderFormData1["Secondary Number"],
      address1: shareholderFormData1["Address 1"],
      address2: shareholderFormData1["Address 2"],
      remarks:shareholderFormData1["Remarks"],
    };

    try {
      if (isEditing && selectedShareholderId) {
        await editShareholderMutation({
          variables: {
            id: selectedShareholderId,
            ...shareholderInput,
          },
        });
        setSnackbarMessage(TEXT_MESSAGES.SHAREUPDATE);
        setSnackbarSeverity("success");
      } else {
        await addShareholderMutation({
          variables: shareholderInput,
        });
        setSnackbarMessage(TEXT_MESSAGES.SHAREADD);
        setSnackbarSeverity("success");
      }
      shareholderRefetch();
      setSnackbarOpen(true);
      handleCloseModal();
    } catch (error) {
      setSnackbarMessage(TEXT_MESSAGES.ADD_EDIT_ERROR);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error(error);
    }
  };

  const fields = [
    { label: "Shareholder Name", type: "text" },
 { label: "Primary Number", type: "text" },
    { label: "Secondary Number", type: "text" },
    { label: "Address 1", type: "text" },
    { label: "Address 2", type: "text" },
    { label: "Remarks", type: "text" },
  ];
  const requiredFields = [
    "Shareholder Name",
    "Primary Number",
    "Secondary Number"
  ] as (keyof ModalFormData)[];
  const columns: GridColDef[] = [
    { field: "serial_no", headerName: "S.No", width: 100 },
    {
      field: "shareholder_id",
      headerName: "Shareholder ID",
      flex: 1,
      minWidth: 150,
    },
    { field: "shareholder_name", headerName: "Name", flex: 1, minWidth: 150 },
    { field: "phone1", headerName: "Primary Number", flex: 1, minWidth: 250 },
    {
      field: "phone2",
      headerName: "Secondary Number",
      flex: 1,
      minWidth: 250,
    },
    { field: "share_amount", headerName: "Share Amount", flex: 2, minWidth: 150 },
{
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginLeft: "-8px",
          }}
        >
          <IconButton onClick={() => handleViewModal(params.row)}>
            <img
              src={VisibleIcon}
              alt="View"
              style={{ width: "20px", height: "20px", marginTop: "7px" }}
            />
          </IconButton>
          <IconButton onClick={() => handleOpenModal(params.row)}>
            <img
              src={EditIcon}
              alt="Edit"
              style={{ marginTop: "7px" }}
            />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id,params.row.shareholder_id)}>
            <DeleteIcon
              style={{
                width: "20px",
                height: "20px",
                color: themestyle.colors.primary,
                marginTop: "7px",
              }}
            />
          </IconButton>
        </div>
      ),
      align: "center",
    },
  ];
  const [shareholderFormData1, setShareholderFormData1] =
    useState<ModalFormData>({
      "Shareholder Name": "",
      Occupation: "",
      "Primary Number": "",
      "Secondary Number": "",
      "Address 1": "",
      "Address 2": "",
      "Remarks":""
    });
  return (
    <div>
      <Box style={{ padding: "15px" }}>
        {isWideScreen ? (
          <PheonixPaper
            style={{
              borderRadius: "20px",
              height: "70px",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "center",
                padding: isMobileSmall ? "15px 0" : "15px 30px",
              }}
            >
              <Typography
                style={{
                  color: themestyle.colors.black,
                  fontWeight: themestyle.fontWeight.sm,
                  fontSize: isMobileSmall
                    ? themestyle.fontSizes.sm
                    : themestyle.fontSizes.medium,
                }}
              >
                {TEXT_MESSAGES.SHAREHOLDER}
              </Typography>

              <Button
                style={{
                  background: themestyle.colors.primary,
                  color: themestyle.colors.white,
                  width: "160px",
                }}
                onClick={() => handleOpenModal(null)}
              >
                {TEXT_MESSAGES.ADD_SHAREHOLDER}
              </Button>
            </div>
          </PheonixPaper>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color:themestyle.colors.black,
                marginRight: "10px",
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {TEXT_MESSAGES.SHAREHOLDER}
            </Typography>
            <Button
              style={{
                background: themestyle.colors.primary,
                color: themestyle.colors.white,
                width: isWideScreen ? "142px" : "160px",
              }}
              onClick={() => handleOpenModal(null)}
            >
              {TEXT_MESSAGES.ADD_SHAREHOLDER}
            </Button>
          </div>
        )}
        <PheonixPaper style={{ borderRadius: "20px" }}>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <PheonixTable
              columns={columns}
              rows={tableData}
              loading={dataLoading}
              rowCount={tableCount}
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              searchQuery ={searchQuery}
              onSearchQueryChange={handleSearchQueryChange}
            />
          </div>
        </PheonixPaper>
        <PhoenixModal
          open={modalOpen}
          onClose={handleCloseModal}
          onAdd={handleAdd}
          fields={fields}
          title={title}
          formData={shareholderFormData1}
          setFormData={setShareholderFormData1}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
          requiredFields={requiredFields}
          context="Shareholder"
          editable={isTextEditable}
        />
        <PheonixSnackBar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
          vertical="top"
          horizontal="center"
        />
        <DeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          entity={entityToDisplay}
          entityname="Shareholder"
        />
      </Box>
    </div>
  );
};

export default Shareholders;
