import React from 'react';
import { Grid,  useMediaQuery, useTheme,} from '@mui/material';
import QuickViewCard from './QuickViewCard';
import { QuickViewGridProps } from '../Interfaces/QuickViewCardProps';

const QuickViewGrid: React.FC<QuickViewGridProps> = ({ items }) => {
  const theme = useTheme();
  const isLaptopSmall = useMediaQuery(theme.breakpoints.between("md","lg"));

  return (
    <Grid container spacing={1} sx={{ gap: isLaptopSmall? "80px":0}}>
      {items.map((item, index) => (
        <Grid 
          item 
          xs={6}    
          sm={6}    
          md={2}  
          lg={2.3}     
          key={index}
        >
          <QuickViewCard title={item.title} icon={item.icon} value={item.value} linkTo={item.linkTo} />
        </Grid>
      ))}
    </Grid>
  );
};
export default QuickViewGrid;