import React from 'react';
import { Grid, Typography } from '@mui/material';

interface DetailRowProps {
  label: string;
  value: string | number;
  style?: React.CSSProperties;
}


const DetailRow: React.FC<DetailRowProps> = ({ label, value }) => {
  const displayValue = typeof value === 'string' ? value : String(value);

  return (
      <Grid item xs={12} sm={6} md={2.4} style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" style={{ fontFamily: "Roboto", fontWeight: 600, fontSize: "16px", color: "#000000", lineHeight: "24.01px" }}>
                  {label}
              </Typography>
          </div>
          <Typography style={{ fontFamily: "Roboto", fontWeight: 400, fontSize: "14px", color: "#000000", marginTop: 4, marginLeft:  0 }}>
              {displayValue.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                      {line}
                      {index < displayValue.split('\n').length - 1 && <br />} 
                  </React.Fragment>
              ))}
          </Typography>
      </Grid>
  );
};
export default DetailRow;
