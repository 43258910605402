import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import SidebarItem from "../Components/PheonixSideBar";
import SidebarProps from "../Interfaces/SideBarProps";
import logo from "../assets/Dlogo.svg";
import paymenticon from "../assets/paymenticon.svg";
import loanledgericon from "../assets/loanledgericon.svg";
import generalledgericon from "../assets/generalicon.png";
import collectionicon from "../assets/collectionicon.svg";
const Sidebar: React.FC<SidebarProps> = ({ isOpen, onToggle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      onToggle();
    }
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (to: string) => {
    navigate(to);
    handleMenuClose();
  };

  const iconComponent = isOpen ? (
    <ChevronLeftIcon style={{ width: "28px", height: "28px" }} />
  ) : (
    <MenuIcon />
  );

  const menuItems = [
    {
      icon: <HomeOutlinedIcon />,
      text: "Home",
      to: "/dashboard",
    },
    {
      icon: <ListAltOutlinedIcon />,
      text: "Borrowers",
      to: "/borrowers",
    },
    {
      icon: <CategoryOutlinedIcon/>,
      text: "Depositors",
      to: "/depositors",
    },
    {
      icon: <RequestQuoteOutlinedIcon />,
      text: "Shareholders",
      to: "/shareholders",
    },
    {
      icon: <ConfirmationNumberOutlinedIcon />,
      text: "Voucher",
      to: "/voucher",
    },
    {
      icon: <ReceiptLongOutlinedIcon />,
      text: "Receipt",
      to: "/receipt",
    },
    {
      icon: <AnalyticsOutlinedIcon />,
      text: "Accounts",
      to: "/accounts",
    },
    {
      icon: <img src={paymenticon} alt="Payment Icon" style={{ width: '28px', height: '28px',color:"#000000"}} />,
      text: "Payment",
      to: "/payment",
    },
    {
      icon:<img src={collectionicon} alt="Payment Icon" style={{ width: '24px', height: '24px' ,color:"#000000"}} />,
      text: "Daily Collection",
      to: "/dailycollection",
    },
    {
      icon: <img src={loanledgericon} alt="Payment Icon" style={{ width: '24px', height: '24px' ,color:"#000000"}} />,
      text: "Loan Ledger",
      to: "/loanledger",
    },
    {
      icon: <img src={generalledgericon} alt="Payment Icon" style={{ width: '24px', height: '24px' ,color:"#000000"}} />,
      text: "General Ledger",
      to: "/generalledger",
    },
    {
      icon: <ReceiptOutlinedIcon />,
      text: "Reports",
      to: "/report",
    },
  ];

  const listItemStyle = {
    backgroundColor: "#FFFFFF",
  };

  const selectedItemStyle = {
    backgroundColor: "#D5E3FF",
  };

  useEffect(() => {
    if (isMobile && isOpen) {
      const menuIconElement = document.getElementById("menuIcon");
      if (menuIconElement) {
        setMenuAnchorEl(menuIconElement);
      }
    }
  }, [isMobile, isOpen]);

  return (
    <>
      {isMobile ? (
        <>
          <div
            id="menuIcon"
            onClick={handleMenuClick}
            style={{
              position: "absolute",
              top: "15px",
              left: "15px",
              cursor: "pointer",
            }}
          >
            <MenuIcon style={{ color: "white" }} />
          </div>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            {menuItems.map((item, index) => (
            <MenuItem
            key={index}
            onClick={() => handleMenuItemClick(item.to)}
        
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                color: "#212121",
                fontWeight: 700,
              }}
            >
              {item.icon}
              <Typography style={{ color: "#212121", fontWeight: 700 }}>
                {item.text}
              </Typography>
            </div>
          </MenuItem>
          
            ))}
          </Menu>
        </>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: isOpen ? 225 : 65,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              background: "linear-gradient(to right,#74B1EA, #82E2F7)",
              fontSize: "16px",
              color: "white",
              width: isOpen ? 225 : 65,
              boxSizing: "border-box",
              transition: "width  ease-in-out, visibility 0.2s linear",
              overflow: "hidden",
              border: "none"
            },
          }}
          open={isOpen}
          onClose={onToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div
            onClick={handleMenuClick}
            style={
              isOpen
                ? {
                    position: "absolute",
                    top: "27px",
                    right: "0px",
                    left: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    position: "absolute",
                    top: "27px",
                    left: "15px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            {isOpen && <img src={logo} alt="Logo" style={{ top:"27px",height: '47px',width: "125px", marginRight: '20px' }} />}
            {iconComponent}
          </div>
          <List sx={{ top: "85px", left: 0 }}>
            {menuItems.map((item, index) => (
              <Tooltip
                key={index}
                title={isOpen ? '' : item.text}
                placement="bottom"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -15],
                      },
                    },
                  ],
                }}
              >
                <div style={{ height: "51px", display: "flex", alignItems: "center" }}>
                <SidebarItem
  icon={item.icon}
  text={item.text}
  to={item.to}
  isOpen={isOpen}
  selected={
    ((location.pathname.startsWith("/deposi") && item.text === "Depositors") ||
    (location.pathname.startsWith("/borrower") && item.text === "Borrowers")) ||
    location.pathname === item.to
  }
/>

                </div>
              </Tooltip>
            ))}
          </List>
        </Drawer>
      )}
    </>
  );
};


export default Sidebar;
