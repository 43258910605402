import React, { useState, useEffect } from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import PheonixSnackBarProps from '../Interfaces/PheonixSnackBarProps';
import { Box, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import themestyle from '../theme';

const PheonixSnackBar: React.FC<PheonixSnackBarProps> = ({
  open,
  onClose,
  message,
  vertical,
  horizontal,
  customAction,
  severity,
}) => {
  const anchorOrigin: SnackbarOrigin = { vertical, horizontal };
  const [snackbarOpen, setSnackbarOpen] = useState(open);
  const backgroundColor = severity === 'success' ? '#BBFBC5' : '#FBC6BB';
  const color = severity === 'success' ? '#1B7913' : '#B00E0E';

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setSnackbarOpen(false);
        onClose && onClose();
      }, 3000);
    }
  }, [open, onClose]);

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={() => {
        setSnackbarOpen(false);
        onClose && onClose();
      }}
      message={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', color }}>
          {severity === 'success' ||
          (severity === 'error' && message?.includes('deleted')) ? (
            <CheckCircleIcon />
          ) : (
            <CancelIcon />
          )}
          {message}
        </Box>
      }
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              setSnackbarOpen(false);
              onClose && onClose();
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
      sx={{
        '& .MuiSnackbarContent-root': {
          background: backgroundColor,
          color: color,
          justifyContent: 'center',
        },
      }}
    />
  );
};

export default PheonixSnackBar;
