import { gql } from "@apollo/client";
export const ADD_DEPOSITOR = gql`
  mutation addDepositor(
    $depositor_name: String!
    $occupation: String
    $phone1: String!
    $phone2: String!
    $interest_rate: Float!
    $deposit_amount: Float
    $address1: String
    $address2: String
    $remarks: String
  ) {
    addDepositor(
      depositor_name: $depositor_name
      occupation: $occupation
      phone1: $phone1
      phone2: $phone2
      interest_rate: $interest_rate
      deposit_amount: $deposit_amount
      address1: $address1
      address2: $address2
      remarks: $remarks
    ) {
      message
      depositor {
        id
        depositor_name
        depositor_id
        phone1
        phone2
        interest_rate
        deposit_amount
        address1
        address2
        occupation
        remarks
      }
    }
  }
`;
export const EDIT_DEPOSITOR = gql`
  mutation EditDepositor(
    $id: ID!
    $depositor_name: String
    $occupation: String
    $phone1: String
    $phone2: String
    $interest_rate: Float
    $deposit_amount: Float
    $address1: String
    $address2: String
    $remarks: String
  ) {
    editDepositor(
      id: $id
      depositor_name: $depositor_name
      occupation: $occupation
      phone1: $phone1
      phone2: $phone2
      interest_rate: $interest_rate
      deposit_amount: $deposit_amount
      address1: $address1
      address2: $address2
      remarks: $remarks
    ) {
      message
      depositor {
        id
        depositor_name
        depositor_id
        phone1
        phone2
        interest_rate
        deposit_amount
        address1
        address2
        occupation
        remarks
      }
    }
  }
`;
export const DELETE_DEPOSITOR = gql`
  mutation DELETE_DEPOSITOR($id: ID!) {
    deleteDepositor(id: $id) {
      message
    }
  }
`;
export const ADD_BORROWER = gql`
  mutation addBorrower(
    $borrower_name: String!
    $occupation: String
    $phone1: String!
    $phone2: String!
    $address1: String
    $address2: String
    $remarks: String
  ) {
    addBorrower(
      borrower_name: $borrower_name
      occupation: $occupation
      phone1: $phone1
      phone2: $phone2
      address1: $address1
      address2: $address2
      remarks: $remarks
    ) {
      message
      borrower {
        id
        borrower_name
        borrower_id
        phone1
        phone2
        address1
        address2
        occupation
        remarks
      }
    }
  }
`;
export const EDIT_BORROWER = gql`
  mutation EditBorrower(
    $id: ID!
    $borrower_name: String
    $occupation: String
    $phone1: String
    $phone2: String
    $address1: String
    $address2: String
    $remarks: String
  ) {
    editBorrower(
      id: $id
      borrower_name: $borrower_name
      occupation: $occupation
      phone1: $phone1
      phone2: $phone2
      address1: $address1
      address2: $address2
      remarks: $remarks
    ) {
      message
      borrower {
        id
        borrower_name
        borrower_id
        phone1
        phone2
        address1
        address2
        occupation
        remarks
      }
    }
  }
`;
export const DELETE_BORROWER = gql`
  mutation DeleteBorrower($_id: ID!) {
    deleteBorrower(_id: $_id) {
      message
    }
  }
`;

export const ADD_SHAREHOLDER = gql`
  mutation AddShareholder(
    $shareholder_name: String!
    $phone1: String!
    $phone2: String!
    $share_amount: Float
    $address1: String
    $address2: String
    $remarks: String
  ) {
    addShareholder(
      shareholder_name: $shareholder_name
      phone1: $phone1
      phone2: $phone2
      share_amount: $share_amount
      address1: $address1
      address2: $address2
      remarks: $remarks
    ) {
      message
      shareholder {
        id
        shareholder_name
        shareholder_id
        phone1
        phone2
        share_amount
        address1
        address2
        remarks
      }
    }
  }
`;

export const EDIT_SHAREHOLDER = gql`
  mutation EditShareholder(
    $id: ID!
    $shareholder_name: String
    $phone1: String
    $phone2: String
    $address1: String
    $address2: String
    $share_amount: Float
    $remarks: String
  ) {
    editShareholder(
      id: $id
      shareholder_name: $shareholder_name
      phone1: $phone1
      phone2: $phone2
      address1: $address1
      address2: $address2
      share_amount: $share_amount
      remarks: $remarks
    ) {
      message
      shareholder {
        id
        shareholder_name
        shareholder_id
        phone1
        phone2
        share_amount
        address1
        address2
        remarks
      }
    }
  }
`;

export const DELETE_SHAREHOLDER = gql`
  mutation DeleteShareholder($id: ID!) {
    deleteShareholder(id: $id) {
      message
    }
  }
`;
export const ADD_RECEIPT = gql`
  mutation addReceipt(
    $name_id: String!
    $role_type: String!
    $loan_no: Int
    $loan_id: String
    $uptodate: Date
    $receipt_type: String
    $amount: Float!
    $remarks: String
  ) {
    addReceipt(
      name_id: $name_id
      role_type: $role_type
      loan_no: $loan_no
      loan_id: $loan_id
      uptodate: $uptodate
      receipt_type: $receipt_type
      amount: $amount
      remarks: $remarks
    ) {
      message
      receipt {
        id
        role_type
        name
        name_id
        receipt_no
        loan_no
        loan_id
        receipt_type
        uptodate
        amount
        remarks
      }
    }
  }
`;
export const EDIT_RECEIPT = gql`
  mutation EditReceipt(
    $id: ID!
    $role_type: String
    $name: String
    $name_id: String
    $loan_no: Int
    $loan_id: String
    $uptodate: Date
    $receipt_type: String
    $amount: Float
    $remarks: String
  ) {
    editReceipt(
      id: $id
      role_type: $role_type
      name: $name
      name_id: $name_id
      loan_no: $loan_no
      loan_id: $loan_id
      uptodate: $uptodate
      receipt_type: $receipt_type
      amount: $amount
      remarks: $remarks
    ) {
      message
      receipt {
        id
        name
        name_id
        role_type
        receipt_no
        loan_no
        loan_id
        receipt_type
        uptodate
        amount
        remarks
      }
    }
  }
`;
export const DELETE_RECEIPT = gql`
  mutation DELETE_RECEIPT($id: ID!) {
    deleteReceipt(id: $id) {
      message
    }
  }
`;
export const ADD_VOUCHER = gql`
  mutation addVoucher(
    $borrower_name: String!
    $borrower_id: String!
    $address1: String
    $address2: String
    $loan_type: String!
    $loan_amt: Float!
    $voucher_no: String
    $loan_no: Int
    $interest_amount: Float
    $interest_rate: Float
    $guarantee_name: String
    $guarantee_phone1: String
    $guarantee_phone2: String
    $guarantee_address1: String
    $guarantee_address2: String
    $document_details: String
    $remarks: String
  ) {
    addVoucher(
      borrower_name: $borrower_name
      borrower_id: $borrower_id
      address1: $address1
      address2: $address2
      loan_type: $loan_type
      loan_amt: $loan_amt
      loan_no: $loan_no
      interest_amount: $interest_amount
      voucher_no: $voucher_no
      interest_rate: $interest_rate
      guarantee_name: $guarantee_name
      guarantee_phone1: $guarantee_phone1
      guarantee_phone2: $guarantee_phone2
      guarantee_address1: $guarantee_address1
      guarantee_address2: $guarantee_address2
      document_details: $document_details
      remarks: $remarks
    ) {
      message
      voucher {
        id
        date
        borrower_name
        borrower_id
        address1
        address2
        voucher_no
        loan_no
        loan_type
        loan_amt
        interest_rate
        interest_amount
        guarantee_name
        guarantee_phone1
        guarantee_phone2
        guarantee_address1
        guarantee_address2
        document_details
        remarks
      }
    }
  }
`;
export const EDIT_VOUCHER = gql`
  mutation EditVoucher(
    $id: ID!
    $borrower_name: String
    $borrower_id: String
    $address1: String
    $address2: String
    $loan_type: String
    $loan_amt: Float
    $voucher_no: String
    $loan_no: Int
    $interest_amount: Float
    $interest_rate: Float
    $guarantee_name: String
    $guarantee_phone1: String
    $guarantee_phone2: String
    $guarantee_address1: String
    $guarantee_address2: String
    $document_details: String
    $remarks: String
  ) {
    editVoucher(
      id: $id
      borrower_name: $borrower_name
      borrower_id: $borrower_id
      address1: $address1
      address2: $address2
      loan_type: $loan_type
      loan_amt: $loan_amt
      loan_no: $loan_no
      interest_amount: $interest_amount
      voucher_no: $voucher_no
      interest_rate: $interest_rate
      guarantee_name: $guarantee_name
      guarantee_phone1: $guarantee_phone1
      guarantee_phone2: $guarantee_phone2
      guarantee_address1: $guarantee_address1
      guarantee_address2: $guarantee_address2
      document_details: $document_details
      remarks: $remarks
    ) {
      message
      voucher {
        id
        date
        borrower_name
        borrower_id
        address1
        address2
        voucher_no
        loan_no
        loan_type
        loan_amt
        interest_rate
        interest_amount
        guarantee_name
        guarantee_phone1
        guarantee_phone2
        guarantee_address1
        guarantee_address2
        document_details
        remarks
      }
    }
  }
`;
export const DELETE_VOUCHER = gql`
  mutation DeleteVoucher($id: ID!) {
    deleteVoucher(id: $id) {
      message
    }
  }
`;
export const ADD_COLLECTION = gql`
  mutation addCollection(
    $borrower_name: String!
    $borrower_id: String!
    $loan_no: Int!
    $amount: Float!
    $date: Date!
  ) {
    addCollection(
      borrower_name: $borrower_name
      borrower_id: $borrower_id
      date: $date
      amount: $amount
      loan_no: $loan_no
    ) {
      message
      collection {
        id
        date
        borrower_name
        borrower_id
        collection_no
        loan_no
        amount
      }
    }
  }
`;
export const EDIT_COLLECTION = gql`
  mutation EditCollection(
    $id: ID!
    $borrower_name: String
    $borrower_id: String
    $loan_no: Int!
    $amount: Float
    $date: Date
  ) {
    editCollection(
      id: $id
      borrower_name: $borrower_name
      borrower_id: $borrower_id
      date: $date
      amount: $amount
      loan_no: $loan_no
    ) {
      message
      collection {
        id
        date
        borrower_name
        borrower_id
        collection_no
        loan_no
        amount
      }
    }
  }
`;
export const DELETE_COLLECTION = gql`
  mutation DeleteCollection($id: ID!) {
    deleteCollection(id: $id) {
      message
    }
  }
`;
export const ADD_PAYMENT = gql`
  mutation addPayment(
    $category_name: String!
    $sub_category: String
    $sub_category_name: String
    $depositor_id: String
    $amount: Float!
    $date: Date!
    $remarks: String
  ) {
    addPayment(
      category_name: $category_name
      sub_category: $sub_category
      sub_category_name: $sub_category_name
      depositor_id: $depositor_id
      amount: $amount
      date: $date
      remarks: $remarks
    ) {
      message
      payment {
        id
        category_name
        sub_category
        sub_category_name
        payment_id
        depositor_id
        amount
        date
        remarks
      }
    }
  }
`;
export const EDIT_PAYMENT = gql`
  mutation EditPayment(
    $id: ID!
    $category_name: String
    $sub_category: String
    $sub_category_name: String
    $depositor_id: String
    $amount: Float
    $date: Date
    $remarks: String
  ) {
    editPayment(
      id: $id
      category_name: $category_name
      sub_category: $sub_category
      sub_category_name: $sub_category_name
      depositor_id: $depositor_id
      amount: $amount
      date: $date
      remarks: $remarks
    ) {
      message
      payment {
        id
        category_name
        sub_category_name
        sub_category
        depositor_id
        payment_id
        amount
        date
        remarks
      }
    }
  }
`;
export const DELETE_PAYMENT = gql`
  mutation DeletePayment($id: ID!) {
    deletePayment(id: $id) {
      message
    }
  }
`;
export const CREATE_ACCOUNT_DETAILS = gql`
mutation CreateAccountByDate ($date: String!){
    createAccountByDate(date: $date) {
        totalPayment
        totalReceipt
        day_opening_amt
        day_closing_amt
        payments {
            id
            category_name
            sub_category
            sub_category_name
            depositor_id
            payment_id
            amount
            date
            remarks
        }
        receipts {
            id
            name
            name_id
            role_type
            receipt_no
            loan_no
            loan_id
            receipt_type
            uptodate
            date
            amount
            remarks
        }
    }
}`
export const GET_ACCOUNTS_DETAILS = gql`
  mutation getAccountsDetails($date: String!) {
    getAccountsDetails(date: $date) {
      accountsdata {
        id
        sno
        date
        particular
        receipt_amount
        payment_amount
      }
      totalPayment
      totalReceipt
      day_opening_amt
      day_closing_amt
    }
  }
`;
export const EDIT_VOUCHER_STATUS = gql`
  mutation EditVoucher(
    $id: ID!
    $borrower_name: String
    $borrower_id: String
    $address1: String
    $address2: String
    $loan_type: String
    $loan_amt: Float
    $voucher_no: String
    $loan_no: Int
    $interest_amount: Float
    $interest_rate: Float
    $guarantee_name: String
    $guarantee_phone1: String
    $guarantee_phone2: String
    $guarantee_address1: String
    $guarantee_address2: String
    $document_details: String
    $remarks: String
    $status: String
  ) {
    editVoucher(
      id: $id
      borrower_name: $borrower_name
      borrower_id: $borrower_id
      address1: $address1
      address2: $address2
      loan_type: $loan_type
      loan_amt: $loan_amt
      loan_no: $loan_no
      interest_amount: $interest_amount
      voucher_no: $voucher_no
      interest_rate: $interest_rate
      guarantee_name: $guarantee_name
      guarantee_phone1: $guarantee_phone1
      guarantee_phone2: $guarantee_phone2
      guarantee_address1: $guarantee_address1
      guarantee_address2: $guarantee_address2
      document_details: $document_details
      remarks: $remarks
      status: $status
    ) {
      message
      voucher {
        id
        date
        borrower_name
        borrower_id
        address1
        address2
        voucher_no
        loan_no
        loan_type
        loan_amt
        interest_rate
        interest_amount
        guarantee_name
        guarantee_phone1
        guarantee_phone2
        guarantee_address1
        guarantee_address2
        document_details
        remarks
        status
      }
    }
  }
`;

export const ADD_BALANCE = gql`
  mutation AddBalanceAmount($totalAmount: Float!) {
    addBalanceAmount(totalAmount: $totalAmount) {
      message
        totalAmount {
            id
            totalAmount
        }
    }
  }
`;
export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      success
      token
    }
  }
`;