import React from 'react';
import { Box, Typography, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, useTheme, useMediaQuery } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from "dayjs";
import { Collection_msg } from '../constant';
import { ReceiptProps, DataRow } from '../Interfaces/CollectionModalFormData';
import calandericon from "../assets/calendar-number-outline.png"
function createData(name: string, value: string | number): DataRow {
  return { name, value };
}
const InterestReceivedReceipt: React.FC<ReceiptProps> = ({
  receiptNumbers,
  totalAmount,
  interest_received,
  selectedDate,
  setSelectedDate,
  handleDateChange
}) => {
  const rows: DataRow[] = [
   
    createData('Received Interest Amount', interest_received?interest_received:0),
    createData('Total Interest Received', totalAmount?totalAmount:0)
  ];
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobileSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const height = isMobileSmall
  ? "135vh"
  : isTablet
  ? "135vh"
  : "65vh";
  const today = dayjs();
  const twoDaysAgo = dayjs().subtract(2, 'day');
  
  return (
    <Box
      sx={{
        borderRadius: '0 20px 20px 0',
        margin: '0px 0',
      }}
    >
      <div style={{ padding: "20px"}}>
        <Typography variant="h6" gutterBottom align='center' style={{ fontWeight: "bold" }}>
          Interest Received
        </Typography>
        <div style={{ marginBottom: "40px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label={Collection_msg.DATE}
                format="DD/MM/YYYY"
                value={selectedDate}
                onChange={handleDateChange}
                maxDate={today}
                slotProps={{
                  field: {
                    readOnly: true
                  }
                }}
                sx={{ width: '100%', marginBottom: '40px' }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        {!selectedDate ? (
          <div style={{ textAlign: "center" }}>
            <img
              src={calandericon}
              alt="calendar icon"
              style={{ display: "block", margin: "0 auto" }}
            />
            <Typography variant="body1" align='center' style={{ marginTop: "10px", fontFamily: "Inter" }} >
              {Collection_msg.SELECT_DATE}
            </Typography>
            <Typography variant="body1" align='center' style={{ fontFamily: "Inter" }} >
              {Collection_msg.SELECT_DATE2}
            </Typography>
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: isMobileSmall ? 100 : 300 }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell sx={{fontWeight:700}}>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
       
      </div>
    </Box>
  );
};
export default InterestReceivedReceipt;