import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TextareaAutosize,
  Button,
  Grid,
  Box,
  IconButton,
  Typography,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TEXT_MESSAGES } from "../constant";
import themestyle from "../theme";
import { ModalFormData } from "../Interfaces/ModalFormdataProps";
import PhoenixModalProps from "../Interfaces/PheonixModalProps";
import { useMediaQuery, useTheme } from "@mui/material";
import PheonixTextarea from "./PheonixTextArea";
interface ExtendedPhoenixModalProps extends PhoenixModalProps {
  context: "Borrower" | "Shareholder" | "Depositor" | "Receipt";
}

const PhoenixModal: React.FC<ExtendedPhoenixModalProps> = ({
  open,
  onClose,
  onAdd,
  fields,
  formData,
  setFormData,
  fieldErrors,
  setFieldErrors,
  title,
  context,
  requiredFields,
  editable = false,
}) => {
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [initialFormData, setInitialFormData] = useState<ModalFormData>({
    ...formData,
  });
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (open) {
      setInitialFormData({ ...formData });
      if (context === "Depositor" && !formData["Interest Rate"]) {
        setFormData((prevData: any) => ({
          ...prevData,
          "Interest Rate": "1",
        }));
      }
  }
  }, [open]);

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => error);
    const requiredFields: (keyof ModalFormData)[] =
      context === "Borrower"
        ? ["Borrower Name","Primary Number"]
        : context === "Shareholder"
        ? ["Shareholder Name","Primary Number"]
        : ["Depositor Name","Interest Rate", "Primary Number"];
    const hasEmptyRequiredFields = requiredFields.some((key) => !formData[key]);
    const isFormDataChanged =
      JSON.stringify(initialFormData) !== JSON.stringify(formData);

    setIsConfirmDisabled(
      hasErrors || hasEmptyRequiredFields || (!isFormDataChanged && !editable)
    );
  }, [formData, fieldErrors, requiredFields, initialFormData, editable]);

  const handleChange = (label: keyof ModalFormData, value: string) => {
    setFormData((prevData: any) => ({ ...prevData, [label]: value }));
    validateField(label, value);
  };

  const handleBlur = (label: keyof ModalFormData) => {
    const value = formData[label];
    validateField(label, value);
  };
const validateField = (label: keyof ModalFormData, value: string) => {
    let error = "";
    const requiredFields: (keyof ModalFormData)[] =
      context === "Borrower"
        ? ["Borrower Name","Primary Number", ]
        : context === "Shareholder"
        ? ["Shareholder Name","Primary Number", ]
        : ["Depositor Name", "Interest Rate","Primary Number", ];

    if (!value && requiredFields.includes(label)) {
      error = `${label} is required`;
    } else if (
      (label === "Primary Number" || (label === "Secondary Number" && value)) &&
      !/^\d{10}$/.test(value)
    ) {
      error = `${label} must be a 10-digit number without special characters`;
    } else if (label === "Deposit Amount" && value) {
        if (/[a-zA-Z]/.test(value)) {
          error = TEXT_MESSAGES.AMT_ERR1;
        } else if (/[^0-9.]/.test(value)) {
          error = TEXT_MESSAGES.AMT_ERR2;
        } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
          error = TEXT_MESSAGES.AMT_ERR3;
        }
      } 
      else if (label === "Share Amount" && value) {
        if (/[a-zA-Z]/.test(value)) {
          error = TEXT_MESSAGES.AMT_ERR1;
        } else if (/[^0-9.]/.test(value)) {
          error = TEXT_MESSAGES.AMT_ERR2;
        } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
          error = TEXT_MESSAGES.AMT_ERR3;
        }
      }else if (label === "Interest Rate" && value) {
      if (!/^\d+(\.\d{1,2})?%?$/.test(value)) {
       error = TEXT_MESSAGES.INVALID_INTEREST_RATE;
        } else {
       console.log(TEXT_MESSAGES.VALID_INPUT);
        }
      }
      else if (
       (label === "Borrower Name" ||
        label === "Shareholder Name" ||
        label === "Depositor Name" ||
        label === "Occupation") &&
      /\d/.test(value)
    ) {
      error = `${label} cannot contain numbers`;
    } else if (
      (label === "Secondary Number" &&
        value &&
        value === formData["Primary Number"]) ||
      (label === "Primary Number" &&
        value &&
        value === formData["Secondary Number"])
    ) {
      error = TEXT_MESSAGES.PRIMARY_VALID;
    } else {
      error = "";
    }
 setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: error }));
  };
 const isFieldRequired = (label: keyof ModalFormData): boolean => {
    const requiredFields: (keyof ModalFormData)[] =
      context === "Borrower"
        ? ["Borrower Name", "Primary Number"]
        : context === "Shareholder"
        ? ["Shareholder Name","Primary Number"]
        : ["Depositor Name", "Interest Rate","Primary Number"];
    return requiredFields.includes(label);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            style={{
              fontWeight: 700,
              fontSize: isMobileSmall ? "18px" : "24px",
              fontFamily: "Inter",
            }}
          >
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="form">
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid
                item
                xs={12}
                sm={field.label.includes("Address") ||field.label.includes("Shareholder Name")|| field.label.includes("Remarks") || field.label.includes("Deposit Amount")|| field.label.includes("Share Amount") ? 12 : 6}
                key={index}
              >
                {field.label.includes("Address") ? (
                  <TextareaAutosize
                    minRows={3}
                    maxRows={6}
                    placeholder={isFieldRequired(field.label as keyof ModalFormData) ? `${field.label} *` : field.label}
                    value={formData[field.label as keyof ModalFormData] as string}
                    onChange={(e) =>
                      handleChange(
                        field.label as keyof ModalFormData,
                        e.target.value
                      )
                    }
                    onBlur={() => handleBlur(field.label as keyof ModalFormData)}
                    style={{
                      width: isMobileSmall ? "93%" : "97%",
                      resize: "vertical",
                      padding: "8px",
                      borderRadius: "5px",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      border: `1px solid ${
                        fieldErrors[field.label as keyof ModalFormData]
                          ? themestyle.colors.error
                          : themestyle.colors.disabled
                      }`,
                      color: fieldErrors[field.label as keyof ModalFormData]
                        ? themestyle.colors.error
                        : themestyle.colors.Primary2,
                    }}
                    readOnly={editable}
                  />
                ) : field.label.includes("Remarks") ? ( 
                  <PheonixTextarea
                  label={field.label} 
                  value={
                    formData[field.label as keyof ModalFormData] as string
                  }
                  onChange={(e) =>
                    handleChange(
                      field.label as keyof ModalFormData,
                      e.target.value
                    )
                  }
                  onBlur={() => handleBlur(field.label as keyof ModalFormData)}
                  error={!!fieldErrors[field.label as keyof ModalFormData]}
                  helperText={
                    fieldErrors[field.label as keyof ModalFormData]
                  }
                  editable={editable}

                />
                ): (
                  <TextField
                    fullWidth
                   label={
    <span>
      {isFieldRequired(field.label as keyof ModalFormData) ? (
        <>
          {field.label} <span style={{ color: themestyle.colors.error }}>*</span>
        </>
      ) : (
        field.label
      )}
    </span>
  }
     type={field.type}
                    variant="outlined"
                    value={formData[field.label as keyof ModalFormData] as string}
                    onChange={(e) =>
                      handleChange(
                        field.label as keyof ModalFormData,
                        e.target.value
                      )
                    }
                    onBlur={() => handleBlur(field.label as keyof ModalFormData)}
                    error={!!fieldErrors[field.label as keyof ModalFormData]}
                    helperText={fieldErrors[field.label as keyof ModalFormData]}
                    style={{ marginTop: "5px" }}
                    
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: fieldErrors[
                            field.label as keyof ModalFormData
                          ]
                            ? themestyle.colors.error
                            : themestyle.colors.disabled,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: fieldErrors[
                            field.label as keyof ModalFormData
                          ]
                            ? themestyle.colors.error
                            : themestyle.colors.primary,
                        },
                        "& input": {
                          color: fieldErrors[field.label as keyof ModalFormData]
                            ? themestyle.colors.error
                            : themestyle.colors.Primary2,
                        },
                        "&.Mui-focused input": {
                          color: fieldErrors[field.label as keyof ModalFormData]
                            ? themestyle.colors.error
                            : themestyle.colors.Primary2,
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: fieldErrors[field.label as keyof ModalFormData]
                          ? themestyle.colors.error
                          : themestyle.colors.Primary2,
                        "&.Mui-focused": {
                          color: fieldErrors[field.label as keyof ModalFormData]
                            ? themestyle.colors.error
                            : themestyle.colors.primary,
                        },
                      },
                    }}
                    InputProps={{
                      readOnly: editable,
                      endAdornment:
                        field.label === 'Interest Rate' ? (
                          <InputAdornment position="end" sx={{ backgroundColor: theme.palette.primary.main, fontSize: "14px",
                             color: "white",
                             width: "auto", height: "100%", padding: '16px 15px', marginRight: "-13px",marginTop:"0.7px",marginBottom:"0.7px", 
                              '& .MuiTypography-root': { color: 'white !important'}, }}>%</InputAdornment>
                        ) : undefined,
                      sx: field.label === 'Interest Rate' ? { textAlign: 'right' } : undefined,
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{
            backgroundColor: "#ffffff",
            color: "#208BEE",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            marginBottom: "15px",
            marginRight: "15px",
            padding: "6px 16px",
            width: "76px",
            height: "36px",
          }}
        >
          {TEXT_MESSAGES.CLOSE}
        </Button>
        {!editable && (
          <Button
            onClick={onAdd}
            disabled={isConfirmDisabled}
            style={{
              backgroundColor: isConfirmDisabled ? "lightgray" : "#208BEE",
              fontWeight: 500,
              textTransform: "uppercase",
              fontSize: "14px",
              marginBottom: "15px",
              marginRight: "15px",
              padding: "6px 16px",
              width: "76px",
              height: "36px",
              color: "#ffffff",
            }}
            variant="contained"
          >
            {TEXT_MESSAGES.SAVE}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PhoenixModal;
