import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid";
import PheonixTable from "../Components/PheonixTable";
import PheonixPaper from "../Components/PheonixPaper";
import { CollectionModalFormData } from "../Interfaces/CollectionModalFormData";
import { GET_PAID_PAYMENTS, GET_PAIDPAYMENT_RECEIPT } from "../graphql/queries";
import InterestPaidReceipt from "./InterestPaidReceipt";
import dayjs, { Dayjs } from "dayjs";
import { useSidebar } from "./SidebarContext";

const InterestPaid: React.FC = () => {
  const { isOpen } = useSidebar();
  const today = dayjs();
  const [tableData, setTableData] = useState<CollectionModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dataLoading, setdataLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("InterestpaidpaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });

  useEffect(() => {
    localStorage.setItem(
      "InterestpaidpaginationModel",
      JSON.stringify(paginationModel)
    );
    return () => {
      localStorage.removeItem("InterestpaidpaginationModel");
    };
  }, [paginationModel]);
  const [searchQuery, setSearchQuery] = useState<string>("");
 
  const {
    loading: collectionLoading,
    error: collectionError,
    data: collectionData,
    refetch: paymentRefetch,
  } = useQuery(GET_PAID_PAYMENTS, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery || "",
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, payments } = data.getInterestpaidDetails;
      setTableData(payments);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });
  useEffect(() => {
    paymentRefetch({
      variables: {
        page: paginationModel.page,
        perPage: paginationModel.pageSize,
        searchQuery: searchQuery || "",
      },
    });
  }, [paginationModel, searchQuery, paymentRefetch]);
  const [selectedDate1, setSelectedDate1] = useState<Dayjs | null>(null);
  const {
    loading: collectionreceiptLoading,
    error: collectionreceiptError,
    data: collectionreceiptData,
    refetch: refetchpaymentreceipt,
  } = useQuery(GET_PAIDPAYMENT_RECEIPT, {
    variables: {
      date: selectedDate1
        ? selectedDate1.format("DD-MM-YYYY")
        : today.format("DD-MM-YYYY"),
    },
    onCompleted: (data) => {
      setReceiptData1({
        date: selectedDate1,
        receipt_number: data.getPaidpaymentReceipt.payment_numbers,
        total_amount_paid: data.getPaidpaymentReceipt.total_amount_paid,
      });
    },
  });
  
  useEffect(() => {
    refetchpaymentreceipt({
      date: selectedDate1 ? selectedDate1.format("DD-MM-YYYY") : today.format("DD-MM-YYYY"),
    });
  }, [selectedDate1, refetchpaymentreceipt, today]);

  useEffect(() => {
    paymentRefetch();
  }, [paymentRefetch]);

  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate1(newDate);
    refetchpaymentreceipt({
      date: newDate ? newDate.format("DD-MM-YYYY") : today.format("DD-MM-YYYY"),
    });
  };
  const [receiptData1, setReceiptData1] = useState<{
    date: Dayjs | null;
    receipt_number: string[];
    total_amount_paid: number;
  }>({
    date: today,
    receipt_number: [],
    total_amount_paid: 0,
  });

  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel: any) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      paymentRefetch({ variables: { searchQuery: {} } });
    } else {
      paymentRefetch();
    }
  };

  const columns: GridColDef[] = [
    { field: "date", headerName: "Date", width: 150 },
    { field: "payment_id", headerName: "Payment No", width: 150 },
    { field: "depositor_id", headerName: "Depositor No", width: 150 },
    { field: "depositorname", headerName: "Name", width: 200 },
    { field: "remarks", headerName: "Particulars", width: 250 },
    { field: "amount", headerName: "Amount", flex: 1, minWidth: 150 },
    { field: "totalAmount", headerName: "Total Amount", width: 150 },
  ];
  const theme = useTheme();
  const Laptopsmall = useMediaQuery(theme.breakpoints.between("md","lg"));
  const isWideScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div>
      <Box style={{ paddingBottom: "5px" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: isWideScreen ? "row" : "column",
            gap: "20px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <PheonixPaper
            style={{
              width:Laptopsmall? "500px": isWideScreen ? "750px" : "100%",
              height: "100%",
              borderRadius: "20px 0 0 20px",
            }}
          >
            <div style={{ overflowX: "auto" }}>
              <PheonixTable
                columns={columns}
                rows={tableData}
                rowCount={tableCount}
                paginationMode="server"
                paginationModel={paginationModel}
                loading={dataLoading}
                onPaginationModelChange={handlePaginationChange}
                isCollectionScreen={true}
                searchQuery={searchQuery}
                onSearchQueryChange={handleSearchQueryChange}
                screen="ledger"
              />
            </div>
          </PheonixPaper>

          <PheonixPaper
            style={{
              width: isWideScreen ? "535px" : "100%",
              height: "auto",
              borderRadius: "0 20px 20px 0",
            }}
          >
            <div>
              <InterestPaidReceipt
                selectedDate={selectedDate1}
                setSelectedDate={setSelectedDate1}
                receiptNumbers={receiptData1.receipt_number}
                totalAmount={receiptData1.total_amount_paid}
                handleDateChange={handleDateChange}
                isOpen={isOpen}
              />
            </div>
          </PheonixPaper>
        </Box>
      </Box>
    </div>
  );
};

export default InterestPaid;
