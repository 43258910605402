import React from 'react';
import { Grid, Typography } from '@mui/material';
import { DetailRowProps } from '../Interfaces/QuickViewCardProps';

const BorrowerDetailRow: React.FC<DetailRowProps> = ({ label, value, iconSrc }) => {
    // Ensure value is a string; otherwise, set to an empty string.
    const displayValue = typeof value === 'string' ? value : String(value);

    return (
        <Grid item xs={12} sm={6} md={label === "Loan Number" ? 12 : 4} style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {iconSrc && <img src={iconSrc} alt="" style={{ marginRight: 8, width: 24, height: 24 }} />}
                <Typography variant="h5" style={{ fontFamily: "Roboto", fontWeight: 600, fontSize: "16px", color: "rgba(27, 36, 53, 1)", lineHeight: "24.01px" }}>
                    {label}
                </Typography>
            </div>
            <Typography style={{ fontFamily: "Roboto", fontWeight: 400, fontSize: "14px", color: "rgba(27, 36, 53, 1)", marginTop: 4, marginLeft: iconSrc ? 32 : 0 }}>
                {displayValue.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        {index < displayValue.split('\n').length - 1 && <br />} 
                    </React.Fragment>
                ))}
            </Typography>
        </Grid>
    );
};

export default BorrowerDetailRow;
