import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery,useTheme } from '@mui/material';
import { TableRowData, CustomTableProps } from '../Interfaces/AccountsProps';
import SkeletonLoader from './PheonixSkeletonLoader';
const AccountsTable: React.FC<CustomTableProps> = ({ data,loading }) => {
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <TableContainer
      sx={{
        height: isMobileSmall? 250 : "300",
        maxHeight: data.length > 5 ? 320 : 'auto',
        overflowY: data.length > 5 ? 'auto' : 'visible',
        marginBottom:"20px"
      }}
    >
      <Table stickyHeader={data.length > 5}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '10%', fontWeight: 700 }}>S.No</TableCell>
            <TableCell sx={{ width: '20%', fontWeight: 700 }}>Date</TableCell>
            <TableCell sx={{ width: '40%', fontWeight: 700 }}>Particular</TableCell>
            <TableCell sx={{ width: '20%', paddingRight: '8px', paddingLeft: '8px', fontWeight: 700 }}>Receipt</TableCell>
            <TableCell sx={{ width: '20%', paddingRight: '8px', paddingLeft: '8px', fontWeight: 700 }}>Payment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell sx={{ width: '5%' }}>{index + 1}</TableCell>
                <TableCell sx={{ width: '15%' }}>{row.date}</TableCell>
                <TableCell sx={{ width: '40%' }}>{row.particular}</TableCell>
                <TableCell sx={{ width: '20%', paddingRight: '8px', paddingLeft: '8px' }}>
                  {row.receipt ? row.receipt.toLocaleString() : ''}
                </TableCell>
                <TableCell sx={{ width: '20%', paddingRight: '8px', paddingLeft: '8px' }}>
                  {row.payment ? row.payment.toLocaleString() : ''}
                </TableCell>
                
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} sx={{ textAlign: 'center', padding: '16px' }}>
                <Typography variant="body1" color="textSecondary">
                  No records found
                </Typography>
              </TableCell>
              
            </TableRow>
          )}
        </TableBody>
        {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          <SkeletonLoader />
        </Box>
      )}
      </Table>
   
    </TableContainer>
  );
};

export default AccountsTable;
