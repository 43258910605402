import React, { useState,useEffect } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { useQuery, useMutation,useLazyQuery } from '@apollo/client';
import { GridColDef } from '@mui/x-data-grid';
import PheonixTable from '../Components/PheonixTable';
import PheonixPaper from '../Components/PheonixPaper';
import PhoenixModal from "../Components/PheonixModal";
import { TEXT_MESSAGES } from '../constant';
import { ModalFormData } from '../Interfaces/ModalFormdataProps';
import { GET_ALL_DEPOSITORS, GET_DEPOSITORS,GET_DEPOSITOR_BY_ID } from '../graphql/queries';
import EditIcon from "../assets/EditFilled.png";
import VisibleIcon from "../assets/visibility.png";
import DeleteIcon from '@mui/icons-material/Delete';
import { useMediaQuery, useTheme } from '@mui/material';
import themestyle from '../theme';
import { ADD_DEPOSITOR, EDIT_DEPOSITOR,DELETE_DEPOSITOR } from '../graphql/mutation';
import PheonixSnackBar from "../Components/PheonixSnackBar";
import DeleteModal from '../Components/DeleteModal';
import { useLocation, useNavigate } from 'react-router-dom';
const Depositors: React.FC = () => {
  const location = useLocation();
  let pagedetails = location.state?.page;
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isTextEditable,setIsTextEditable]=useState(false);
  const [selectedDepositorId, setSelectedDepositorId] = useState<string | null>(null);
  const [tableData, setTableData] = useState<ModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [title, setTitle] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('error');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dataLoading, setdataLoading] = useState(false); 
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null); 
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("DepositorpaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });

  useEffect(() => {
    localStorage.setItem("DepositorpaginationModel", JSON.stringify(paginationModel));
    return () => {
      localStorage.removeItem("DepositorpaginationModel");
    };
  }, [paginationModel]);
  
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const isWideScreen = useMediaQuery('(min-width:600px)');
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [succMessage, setSuccMessage] = useState('');
  const [addDepositorMutation] = useMutation(ADD_DEPOSITOR);
  const [editDepositorMutation] = useMutation(EDIT_DEPOSITOR);
  const [deleteDepositorMutation] = useMutation(DELETE_DEPOSITOR);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate()
  const { loading: customerLoading, error: customerError, data: customerData, refetch: depositorRefetch } = useQuery(GET_DEPOSITORS, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery || ""
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, depositor } = data.getDepositorperPage;
      const startIndex = (paginationModel.page) * paginationModel.pageSize + 1;
      const depositorsWithSerialNo = depositor.map((depositor: ModalFormData, index: number) => ({
        ...depositor,
        serial_no: (startIndex + index).toString()
      }));
      setTableData(depositorsWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });
  useEffect(() => {
    if (pagedetails) {
      setPaginationModel({
        pageSize: pagedetails.pageSize,
        page: pagedetails.page,
      });
    }
  }, [pagedetails]);
  useEffect(() => {
    setdataLoading(true);
    if (!customerLoading && !customerError && customerData) {
      const { totalPages, totalCount, depositor } = customerData.getDepositorperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const depositorsWithSerialNo = depositor.map((depositor: ModalFormData, index: number) => ({
        ...depositor,
        serial_no: (startIndex + index).toString()
      }));
      setTableData(depositorsWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [customerData, customerLoading, customerError, paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    depositorRefetch();
  }, [depositorRefetch]);
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel:any) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const handleDeleteClick1 = async (depositorId: string) => {
    try {
      const result = await deleteDepositorMutation({
        variables: {
          id: depositorId,
        },
      });
  
      if (result && result.data && result.data.deleteDepositor) {
        setSnackbarMessage(TEXT_MESSAGES.DELETE_SUCCESS);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        depositorRefetch();
      }
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };

  const [entityToDisplay, setEntityToDisplay] = useState("");
  const handleDeleteClick = (depositorId: string,collectionNo: string) => {
    setEntityToDelete(depositorId);
    setEntityToDisplay(collectionNo);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!entityToDelete) return;

    try {
      const result = await deleteDepositorMutation({
        variables: {
          id: entityToDelete,
        },
      });

      if (result && result.data && result.data.deleteDepositor) {
        setSnackbarMessage(TEXT_MESSAGES.DELETE_SUCCESS);
        setSnackbarSeverity('error');
        depositorRefetch();
      }
    } catch (error) {
      setSnackbarMessage(TEXT_MESSAGES.DELETE_ERROR);
      setSnackbarSeverity('error');
      console.error("Error during deletion:", error);
    } finally {
      setSnackbarOpen(true);
      setDeleteModalOpen(false);
      setEntityToDelete(null);
    }
  };
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      depositorRefetch({ variables: { searchQuery: {} } }); 
    } else {
      depositorRefetch();
    }
  };
  const handleOpenModal = (selectedRowData: ModalFormData | null = null) => {
 setModalOpen(true);
    setFieldErrors({});
    if (selectedRowData) {
      setSelectedDepositorId(selectedRowData.id);
      setIsEditing(true);
      setIsTextEditable(false)
      setDepositorFormData1({
        "Depositor Name": selectedRowData.depositor_name,
        Occupation: selectedRowData.occupation,
      "Interest Rate": selectedRowData.interest_rate !== null && selectedRowData.interest_rate !== undefined
  ? selectedRowData.interest_rate
  : '',

        "Primary Number": selectedRowData.phone1,
        "Secondary Number": selectedRowData.phone2,
        "Address 1": selectedRowData.address1,
        "Address 2": selectedRowData.address2,
        "Remarks":selectedRowData.remarks,
      });
      setTitle(TEXT_MESSAGES.EDIT_DEPOSITOR_title);
    } else {
      setSelectedDepositorId(null);
      setIsEditing(false);
      setIsTextEditable(false)
      setDepositorFormData1({
        "Depositor Name": "",
        Occupation: "",
        "Interest Rate":"",
        "Primary Number": "",
        "Secondary Number": "",
        "Address 1": "",
        "Address 2": "",
        "Remarks":""
      });
      setTitle(TEXT_MESSAGES.ADD_DEPOSITOR_title);
    }
  };
  const handleViewModal = (selectedRowData: ModalFormData | null = null) => {
    setModalOpen(true);
    setFieldErrors({});
    if (selectedRowData) {
      setSelectedDepositorId(selectedRowData.id);
      setIsEditing(true);
      setIsTextEditable(true)
      setDepositorFormData1({
        "Depositor Name": selectedRowData.depositor_name,
        Occupation: selectedRowData.occupation,
        "Interest Rate": selectedRowData.interest_rate,
        "Primary Number": selectedRowData.phone1,
        "Secondary Number": selectedRowData.phone2,
        "Address 1": selectedRowData.address1,
        "Address 2": selectedRowData.address2,
        "Remarks":selectedRowData.remarks,
      });
      setTitle(TEXT_MESSAGES.VIEW_DEPOSITOR_TITLE);
    }
  }

  const handleCloseModal = () => {
    setFieldErrors({});
    setDepositorFormData1({
      "Depositor Name": "",
      Occupation: "",
      "Interest Rate":"",
      "Primary Number": "",
      "Secondary Number": "",
      "Address 1": "",
      "Address 2": "",
      "Remarks":""
    });
    setModalOpen(false);
  };
  const handleAdd = async () => {
    setFieldErrors({});
    const InterestRate = parseFloat(depositorFormData1["Interest Rate"]) || 0;
    const depositorInput = {
      depositor_name: depositorFormData1["Depositor Name"],
      occupation: depositorFormData1.Occupation,
      phone1: depositorFormData1["Primary Number"],
      phone2: depositorFormData1["Secondary Number"],
      address1: depositorFormData1["Address 1"],
      address2: depositorFormData1["Address 2"],
      interest_rate: InterestRate,
      remarks: depositorFormData1["Remarks"],
    };
  
    try {
      if (isEditing && selectedDepositorId) {
        await editDepositorMutation({
          variables: {
            id: selectedDepositorId,
            ...depositorInput,
          },
        });
        setSnackbarMessage(TEXT_MESSAGES.DIPOSITORUPDATE);
        setSnackbarSeverity('success');
      await getDepositorByIdDetails({ variables: { id: selectedDepositorId } });
      } else {
        await addDepositorMutation({
          variables: depositorInput,
        });
        setSnackbarMessage(TEXT_MESSAGES.DIPOSITORCREATE);
        setSnackbarSeverity('success');
      }
      setSnackbarOpen(true);
      handleCloseModal();
      depositorRefetch()
    } catch (error:any) {
      setSnackbarMessage(error.message || TEXT_MESSAGES.ADD_EDIT_ERROR);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error(error);
    }
  };
const requiredFields = ['Depositor Name', 'Primary Number ','Secondary Number',"Interest Rate"] as (keyof ModalFormData)[];
const [selectedDepositor, setSelectedDepositor] = useState<ModalFormData | null>(null);
const [getDepositorByIdDetails, { loading: detailsLoading, data: detailsData }] = useLazyQuery(GET_DEPOSITOR_BY_ID);
const handleViewDetails = async (depositor_id: string) => {
  try {
 const response = await getDepositorByIdDetails({
      variables: { depositor_id: depositor_id },
      fetchPolicy: 'network-only', 
    });

    if (response.data && response.data.getDepositorbyIDDetails) {
      const depositorDetails = response.data.getDepositorbyIDDetails;
      setSelectedDepositor(depositorDetails);
 navigate('/depositordetails', { state: { depositor: depositorDetails ,paginationModel} });
    }
  } catch (err) {
    console.error('Error fetching depositor details:', err);
  }
};
const fields = [
  { label: 'Depositor Name', type: 'text' },
  { label: 'Interest Rate', type: 'text' },
  { label: 'Primary Number', type: 'text' },
  { label: 'Secondary Number', type: 'text' },
  { label: 'Address 1', type: 'text' },
  { label: 'Address 2', type: 'text' },
  { label: 'Remarks', type: 'text' },
];
const columns: GridColDef[] = [
    { field: 'serial_no', headerName: 'S.No', width: 100 },
    {
      field: 'depositor_id',
      headerName: 'Depositor ID',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Typography
          variant="body2"
          color="primary"
          style={{ cursor: 'pointer',textAlign:"start", display:"flex",
            justifyContent: "start", 
            alignItems: "center", 
            height: "100%", textDecoration: 'underline' }}
          onClick={() => handleViewDetails(params.value)}
        >
          {params.value}
        </Typography>
      ),
    },
    { field: 'depositor_name', headerName: 'Name', flex: 1, minWidth: 250 },
    { field: 'phone1', headerName: 'Primary Number', flex: 1, minWidth: 250 },
    { field: 'phone2', headerName: 'Secondary Number', flex: 1, minWidth: 250 },
  { field: 'deposit_amount', headerName: 'Deposit Amount', flex: 2, minWidth: 250 },
     { field: 'interest_rate', headerName: 'Interest Rate', flex: 1, minWidth: 250 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', marginLeft: "-8px" }}>
          <IconButton onClick={() => handleViewModal(params.row)}>
            <img src={VisibleIcon} alt="View" style={{ width: '20px', height: '20px', marginTop: "7px" }} />
          </IconButton>
          <IconButton onClick={() => handleOpenModal(params.row)}>
            <img src={EditIcon} alt="Edit" style={{  marginTop: "7px" }} />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(params.row.id,params.row.depositor_id)}>
            <DeleteIcon style={{ width: '20px', height: '20px', color: themestyle.colors.primary, marginTop: "7px" }} />
          </IconButton>
        </div>
      ),
      align: 'center'
    },
  ];

  const [depositorFormData1, setDepositorFormData1] = useState<ModalFormData>({
    "Depositor Name": "",
    Occupation: "",
    "Interest Rate":"",
    "Primary Number": "",
    "Secondary Number": "",
    "Address 1": "",
    "Address 2": "",
    "Remarks":""
  });
  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <div>
  <Box style={{ padding: '15px' }}>
      <PheonixSnackBar
  open={snackbarOpen}
  message={snackbarMessage}
  severity={snackbarSeverity}
  onClose={() => setSnackbarOpen(false)}
  vertical="top"
  horizontal="center"
/>
        {isWideScreen ? (
          <PheonixPaper style={{ borderRadius: "20px", height: "70px", marginBottom: "20px" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "center", padding: isMobileSmall ? "15px 0" : "15px 30px" }}>
              <Typography style={{ color: themestyle.colors.black, fontWeight: themestyle.fontWeight.sm, fontSize: isMobileSmall ? themestyle.fontSizes.sm : themestyle.fontSizes.medium }}>
                {TEXT_MESSAGES.DEPOSITOR}
              </Typography>
              <Button style={{ background: themestyle.colors.primary,color: themestyle.colors.white, width: '160px' }} onClick={() => handleOpenModal(null)}>
                {TEXT_MESSAGES.ADD_DEPOSITOR}
              </Button>
            </div>
          </PheonixPaper>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "center", padding: isMobileSmall ? "15px 0" : "15px 30px", }}>
            <Typography style={{ color: themestyle.colors.black, marginRight: "10px", fontWeight: themestyle.fontWeight.sm, fontSize: isMobileSmall ? themestyle.fontSizes.sm : themestyle.fontSizes.medium }}>
              {TEXT_MESSAGES.DEPOSITOR}
            </Typography>
            <Button style={{background: themestyle.colors.primary,color: themestyle.colors.white, width: isWideScreen ? "142px" : "160px" }} onClick={() => handleOpenModal(null)}>
              {TEXT_MESSAGES.ADD_DEPOSITOR}
            </Button>
          </div>
        )}
        <PheonixPaper style={{ borderRadius: "20px" }}>
          <div style={{ width: '100%', overflowX: 'auto' }}>
            <PheonixTable
              columns={columns}
              rows={tableData}
              loading={dataLoading}
             rowCount={tableCount}
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              searchQuery ={searchQuery}
              onSearchQueryChange={handleSearchQueryChange}

            />
          </div>
        </PheonixPaper>
        <PhoenixModal
          open={modalOpen}
          onClose={handleCloseModal}
          onAdd={handleAdd}
          fields={fields}
          title={title}
          formData={depositorFormData1}
          setFormData={setDepositorFormData1}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
          requiredFields={requiredFields}
          context='Depositor'
          editable={isTextEditable}
        />
        
        <DeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          entity={entityToDisplay}
          entityname="Depositor"
        />
      </Box>
    </div>
  );
};
export default Depositors;