import React,{useLayoutEffect,useState} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ApolloProvider, InMemoryCache, ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import baseUrl from "./Api";
import App from './App';
import Dashboard from './Pages/dashboard';
import Layout from './Pages/Layout';
import Borrowers from './Pages/Borrowers';
import Login from './Pages/login';
import Depositors from './Pages/Depositors';
import Shareholders from './Pages/Shareholders';
import Voucher from './Pages/Voucher';
import Collection from './Pages/Collection';
import Report from './Pages/Report';
import ReceiptScreen from './Pages/Receipt';
import Payment from './Pages/Payment';
import Loanledger from './Pages/Loanledger';
import Generalledger from './Pages/Generalledger';
import Accounts from './Pages/Accounts';
import { SidebarProvider } from './Pages/SidebarContext';
import DepositorDetails from './Pages/DepositorDetails';
import BorrowerDetails from './Pages/BorrowerDetails';
import DailyLoanDetails from "./Pages/DailyLoanDetails";
import MonthlyLoanDetails from './Pages/monthlyLoanDetails';
import DepoistorPayment from './Pages/DepoistorPayment';
import { SessionContextProvider,useSession } from './context/sessioncontext';
import { useNavigate,useLocation } from 'react-router-dom';
import { onError } from "@apollo/client/link/error";
import { ApolloLink,Observable } from "@apollo/client";
const httpLink = createHttpLink({
  uri: baseUrl,
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions?.code === "UNAUTHENTICATED" || err.message.includes("403")) {
        alert("Your session has expired. Please log in again.");
        window.location.href = '/'; 
        return;
      }
    }
  }
  if (networkError) {
    const errorMessage = networkError.message || '';
    if (errorMessage.includes("Response not successful: Received status code 403")) {
      alert("Your session has expired. Please log in again.");
      window.location.href = '/';  
    }
    console.error(`[Network error]: ${networkError}`);
  }
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});
const AppRouter = () => {
  const { isLoggedIn } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  useLayoutEffect(() => {

    if (!isLoggedIn && ['/dashboard', '/borrowers', '/depositors', '/shareholders', '/voucher', '/receipt','/dailycollection','/payment','/report','/loanledger','/generalledger','/accounts','/depositordetails','/borrowerdetails']
        .some(route => location.pathname.startsWith(route))) {
        navigate('/', { replace: true });
    }

}, [isLoggedIn, location.pathname, navigate]);
return (
    <Routes>
      <Route path="/" element={<App />} />
      {isLoggedIn ? (
      <>
      <Route path="/dashboard" element={<Layout title="Dashboard"><Dashboard /></Layout>} />
      <Route path="/borrowers" element={<Layout title="Borrowers"><Borrowers /></Layout>} />
      <Route path="/depositors" element={<Layout title="Depositors"><Depositors /></Layout>} />
      <Route path="/shareholders" element={<Layout title="Shareholders"><Shareholders /></Layout>} />
      <Route path="/voucher" element={<Layout title="Voucher"><Voucher /></Layout>} />
      <Route path="/receipt" element={<Layout title="Receipt"><ReceiptScreen /></Layout>} />
      <Route path="/dailycollection" element={<Layout title="Daily Collection"><Collection /></Layout>} />
      <Route path="/payment" element={<Layout title="Payment"><Payment /></Layout>} />
      <Route path="/report" element={<Layout title="Report"><Report /></Layout>} />
      <Route path="/loanledger" element={<Layout title="Loan Ledger"><Loanledger /></Layout>} />
      <Route path="/generalledger" element={<Layout title="General Ledger"><Generalledger /></Layout>} />
      <Route path="/accounts" element={<Layout title="Accounts"><Accounts /></Layout>} />
      <Route path="/depositordetails" element={<Layout title="Depositor Details"><DepositorDetails /></Layout>} />
      <Route path="/borrowerdetails" element={<Layout title="Borrower Details"><BorrowerDetails /></Layout>} />
      <Route path="/daily/:loan_no" element={<Layout title="Daily loan Details"><DailyLoanDetails /></Layout>} />
      <Route path="/monthly/:loan_no" element={<Layout title="Monthly loan Details"><MonthlyLoanDetails /></Layout>} />
      <Route path="/dephistory/:DepositerNO" element={<Layout title="Depositer History">< DepoistorPayment/></Layout>} />
   </>) : (
        <Route path="*" element={<App />} />
      )}

    </Routes>
  );
};
const rootElement = document.getElementById('root');
document.body.style.backgroundColor = "white";
if (rootElement) {
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Router>
        <SessionContextProvider>
          <SidebarProvider>
            <AppRouter />
          </SidebarProvider>
       </SessionContextProvider>
        </Router>
      </ApolloProvider>
    </React.StrictMode>,
    rootElement
  );
}
