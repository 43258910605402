import { gql } from "@apollo/client";

export const GET_BORROWERS = gql`
  query GetBorrowerperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getBorrowerperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      borrower {
        id
        borrower_name
        borrower_id
        phone1
        phone2
        address1
        address2
        occupation
        remarks
      }
    }
  }
`;
export const GET_ALL_BORROWERS = gql`
  query GetAllBorrowers {
    getAllBorrowers {
      id
      borrower_name
      borrower_id
      address1
      address2
    }
  }
`;
export const GET_ALL_DEPOSITORS = gql`
  query GetAllDepositors {
    getAllDepositors {
      id
      depositor_name
      depositor_id
      address1
      address2
    }
  }
`;
export const GET_ALL_SHAREHOLDERS = gql`
  query GetAllShareholder {
    getAllShareholder {
      id
      shareholder_name
      shareholder_id
      address1
      address2
    }
  }
`;

export const GET_BORROWERS_VOUCHER = gql`
  query GetAllBorrowers {
    getAllBorrowers {
      id
      borrower_name
      borrower_id
    }
  }
`;
export const GET_DEPOSITORS = gql`
  query GetDepositorperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getDepositorperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      depositor {
        id
        depositor_name
        depositor_id
        phone1
        phone2
        interest_rate
        deposit_amount
        address1
        address2
        occupation
        remarks
      }
    }
  }
`;
export const GET_BORROWER_BY_ID = gql`
  query GetBorrowerDetails($id: ID!) {
    getBorrowerDetails(id: $id) {
      message
      borrowerDetails {
        borrower {
          id
          borrower_name
          borrower_id
          phone1
          phone2
          address1
          address2
          occupation
          remarks
        }
        totals {
          total_loan_nos
          total_loan_amt
          total_paid_amt
          total_pending_amt
          total_interest_paid
          total_penalty_interest
          total_principal_paid
          total_penalty_unpaid
        }
      }
    }
  }
`;

export const GET_LOAN_DETAILS = gql`
  query GetLoanDetails($loan_no: Int!) {
    getLoanDetails(loan_no: $loan_no) {
      message
      receipt_nos2
      loanDetails {
        loan {
          loan_type
          loan_no
          loan_amt
          loan_start_date
          interest_rate
          interest_amount
          loan_end_date
          number_of_days
          current_day_count
          excess_day_count
          pending_months
          paid_amount
          pending_amount
          paid_interest
          pending_interest
          monthly_interest
          penaltyPaid
          penaltyUnPaid
          pendingmonthly_interest
        }
        others {
          guarantee_name
          guarantee_address1
          guarantee_address2
          guarantee_phone1
          guarantee_phone2
          document_details
        }
      }
    }
  }
`;

export const GET_DEPOSITOR_BY_ID = gql`
  query GetDepositorbyIDDetails($depositor_id: String!) {
    getDepositorbyIDDetails(depositor_id: $depositor_id) {
      id
      depositor_name
      depositor_id
      phone1
      phone2
      interest_rate
      deposit_amount
      address1
      address2
      occupation
      remarks
      total_interest_paid
    }
  }
`;

export const GET_SHAREHOLDERS = gql`
  query GetShareholderperPage(
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getShareholderperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      shareholder {
        id
        shareholder_name
        shareholder_id
        phone1
        phone2
        share_amount
        address1
        address2
        remarks
      }
    }
  }
`;
export const GET_ALL_VOUCHERS = gql`
  query GetAllVouchers {
    getAllVouchers {
      id
      borrower_name
      borrower_id
      loan_no
      date
    }
  }
`;

export const GET_VOUCHERS_LOANTYPE = gql`
  query GetBorrowersbyLoanType(
    $page: Int!
    $perPage: Int!
    $searchQuery: String
    $loan_type: String
  ) {
    getBorrowersbyLoanType(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
      loan_type: $loan_type
    ) {
      totalPages
      totalCount
      voucher {
        id
        borrower_name
        borrower_id
        address1
        address2
        voucher_no
        date
        loan_type
        loan_no
        loan_amt
        interest_rate
        interest_amount
        guarantee_name
        guarantee_phone1
        guarantee_phone2
        guarantee_address1
        guarantee_address2
        document_details
        remarks
        status
        balance_amount
        dailybalance_amount
    }
    }
  }
`;
export const GET_ALL_LOANNOS = gql`
  query GetAllLoanNumbers {
    getAllLoanNumbers
  }
`;
export const GET_LOAN_NUMBERS_BY_BORROWER = gql`
  query GetLoanNumbersByBorrower($borrowerId: String!) {
    getLoanNumbersByBorrower(borrower_id: $borrowerId) {
      loan_no
      loan_type
    }
  }
`;
export const GET_LOAN_NUMBERS_BY_BORROWER_RECEIPTTYPE = gql`
  query GetLoanNumbersByBorrowerAndReceiptType(
    $borrowerId: String!
    $receipt_type: String!
  ) {
    getLoanNumbersByBorrowerandReceipttype(
      borrower_id: $borrowerId
      receipt_type: $receipt_type
    ) {
      loan_no
      loan_type
    }
  }
`;
export const GET_AMOUNT_BY_LOANNO = gql`
  query GetAmountByBorrowerAndLoanNo($borrowerId: String!, $loanNo: Int!) {
    getAmountByBorrowerAndLoanNo(borrower_id: $borrowerId, loan_no: $loanNo) {
      amount
    }
  }
`;
export const GET_BORROWER_BY_LOAN_NO = gql`
  query GetBorrowerByLoanNumbers($loanNo: Int!) {
    getBorrowerByLoanNumbers(loan_no: $loanNo) {
      borrower_name
      borrower_id
    }
  }
`;
export const GET_VOUCHERS = gql`
  query GetVoucherperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getVoucherperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      voucher {
        id
        borrower_name
        borrower_id
        address1
        address2
        voucher_no
        date
        loan_type
        loan_no
        loan_amt
        interest_rate
        interest_amount
        guarantee_name
        guarantee_phone1
        guarantee_phone2
        guarantee_address1
        guarantee_address2
        document_details
        remarks
      }
    }
  }
`;
export const GET_RECEIPTS = gql`
  query GetReceiptperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getReceiptperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      receipt {
        id
        name
        name_id
        uptodate
        receipt_no
        loan_no
        receipt_type
        amount
        remarks
        role_type
        date
      }
    }
  }
`;
export const GET_INTERST_RECEIPTS = gql`
  query GetInterestReceived($page: Int!, $perPage: Int!, $searchQuery: String) {
    getInterestReceived(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      receipt {
        id
        name
        name_id
        uptodate
        receipt_no
        loan_no
        receipt_type
        amount
        remarks
        role_type
        date
        totalAmount
      }
    }
  }
`;
export const GET_COLLECTION = gql`
  query GetCollectionperPage(
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getCollectionperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      collection {
        id
        borrower_name
        borrower_id
        collection_no
        loan_no
        date
        amount
      }
    }
  }
`;
export const GET_COLLECTION_RECEIPT = gql`
  query GetCollectionReceipt($date: String!) {
    getCollectionReceipt(date: $date) {
      collection_receipt_number
      loan_nos
      total_amount_collected
      DownloadReceipt {
        details {
          loan_no
          name
          amount
        }
      }
    }
  }
`;
export const GET_PAIDPAYMENT_RECEIPT = gql`
  query GetPaidpaymentReceipt($date: String!) {
    getPaidpaymentReceipt(date: $date) {
      payment_numbers
      total_amount_paid
      }
  }
`;
export const GET_INTERESTRECEIVED_RECEIPT = gql`
query GetInterestReceivedReceipt($date: String!) {
    getInterestReceivedReceipt(date: $date) {
      received_interest
      total_int_received
      }
  }
`;
export const GET_PAYMENTS = gql`
  query GetPaymentperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getPaymentperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      payment {
        id
        category_name
        sub_category
        sub_category_name
        depositor_id
        payment_id
        amount
        date
        remarks
      }
    }
  }
`;
export const GET_PAID_PAYMENTS = gql`
  query GetInterestpaidDetails(
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getInterestpaidDetails(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      payments {
        id
        payment_id
        receipt_no
        depositorname
        amount
        depositor_id
        date
        totalAmount
        remarks
      }
    }
  }
`;
export const GET_ALL_CATEGORIES = gql`
  query GetAllCategories {
    getAllCategories {
      sub_category_name
    }
  }
`;
export const GET_DAILY_LOAN_HISTORY = gql`
  query GetAllCollectionhistoryByLoan($loan_no: Int!, $searchQuery: String) {
    getAllCollectionhistoryByLoan(loan_no: $loan_no, searchQuery: $searchQuery) {
      collections {
        id
        loan_no
        borrower_name
        borrower_id
        collection_no
        date
        amount
        voucherDetails {
          collectedAmount
          remainingAmount
          totalAmountofcollectionandremaining
          totalcollectedamount
        }
      }
      receipts {
        id
        name
        name_id
        role_type
        receipt_no
        loan_no
        loan_id
        receipt_type
        uptodate
        date
        amount
        remarks
        voucherDetails {
          collectedAmount
          remainingAmount
          totalAmountofcollectionandremaining
          totalcollectedamount
        }
      }
    }
  }
`;
export const GET_ALL_SHAREHOLDERS_AMOUNT = gql`
query GetAllReceipt {
  getAllReceipt {
      id
      name
      name_id
      role_type
      receipt_no
      loan_no
      loan_id
      receipt_type
      uptodate
      date
      amount
      remarks
  }
}
`;

export const GET_MONTHLY_LOAN_HISTORY = gql`
  query GetReceiptMonthlyHistory($loan_no: Int!,$searchQuery: String) {
    getReceiptMonthlyHistory(loan_no: $loan_no,searchQuery: $searchQuery) {
      id
      loan_no
      role_type
      borrower_id
      date
      receipt_id
      remarks
      principalAmount
      interestAmount
      voucherDetails {
        collectedAmount
        remainingAmount
        totalAmountofcollectionandremaining
      }
    }
  }
`;
export const GET_ALL_PAYMENTS = gql`
  query GetAllPayments {
    getAllPayments {
      id
      sub_category
      sub_category_name
      depositor_id
      payment_id
      amount
      date
      remarks
      category_name
    }
  }
`;

export const GET_LOAN_AMOUNT = gql`
  query GetloandetailsperPage(
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getloandetailsperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
     totalPages
        totalCount
        receipts {
            receipt_no
            amount
            loan_no
            date
            loan_amount
            balance_amount
            remarks
        }
        outstandingAmount
    }
  }
`;

export const GET_LOAN_BALANCE = gql`
  query GetAmount {
    getAmount {
      message
        totalAmount {
            id
            totalAmount
        }
    }
  }
`;
export const GET_DEPOSITOR_LEDGER = gql`
  query GetDepositLedgerperPage(
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getDepositLedgerperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
     totalPages
        totalCount
        receipt {
            payment_amount
            deposit_id
            date
            deposit_amount
            balance_amount
            depositor_name
            interest_amount
        }
    }
  }
`;
export const GET_DAILY_LOAN_HISTORY_PER_PERSON = gql`
  query GetAllBorrowerhistoryByLoan($loan_no: Int!) {
    getAllBorrowerhistoryByLoan(loan_no: $loan_no) {
      borrower_name
      borrower_id
      address1
      address2
      loan_type
      loan_amt
      phoneNUmber
      pending_amt
      interest_amount
      date
      endDate
      dailyAmount
      days
      occupation
      secondaryNumber
    }
  }
`;
export const GET_MONTHLY_LOAN_HISTORY_PER_PERSON = gql`
  query GetMonthlyBorrowerhistoryByLoan($loan_no: Int!) {
    getMonthlyBorrowerhistoryByLoan(loan_no: $loan_no) {
      borrower_name
      borrower_id
      address1
      address2
      loan_type
      loan_amt
      phoneNUmber
      pending_amt
      interest_amount
      date
      occupation
      secondaryNumber
    }
  }
`;
export const GET_DEPOISTER_HISTORY_PER_PERSON = gql`
 query GetAllDepoisterIDPaymentHistory($page: Int!, $perPage: Int!,$depoist_no: String!, $searchQuery: String) {
  getAllDepoisterIDPaymentHistory(
      page: $page
      perPage: $perPage
      depoist_no: $depoist_no
      searchQuery: $searchQuery
  ) {
      totalPages
      totalCount
      data {
          date
          receiptNo
          particulars
          interestAmount
          depositAmount
          depositReturn
          balanceAmount
      }
  }
}
`;