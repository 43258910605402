import React, { useEffect, useState } from "react";
import { TEXT_MESSAGES } from "../constant";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Tab,
  Tabs,
  Grid,
  Button,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import themestyle from "../theme";
import PheonixPaper from "../Components/PheonixPaper";
import {
  GET_ALL_PAYMENTS,
  GET_ALL_SHAREHOLDERS_AMOUNT,
} from "../graphql/queries";
import LoanScreen from "./LoanScreen";
import DepositorScreen from "./DepositorScreen";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import homepeople from "../assets/Vector.png";
import InterestPaid from "./InterestPaid";
import InterestReceived from "./InterestReceived";
interface PaymentInfo {
  totalAmount: number;
  latestDate?: string; 
}
interface Payment {
  id: string;
  category_name: string;
  sub_category: string;
  amount: number;
  date: string | number | Date; 
  remarks?: string; 
}

const Generalledger: React.FC = () => {
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [filteredPayments, setFilteredPayments] = useState<any[]>([]);
  const [finalData, setFinalData] = useState<any[]>([]);
  const {
    loading: loadingPayments,
    error: errorPayments,
    data: dataPayments,
    refetch: refetchPayments,
  } = useQuery(GET_ALL_PAYMENTS,{
    skip: selectedTab !== 4,  
  });
  useEffect(() => {
    if (selectedTab === 4) {
      refetchPayments();
    }
  }, [selectedTab, refetchPayments]);
  const {
    loading: loadingShareholders,
    error: errorShareholders,
    data: dataShareholders,
    refetch: refetchShareholders,
  } = useQuery(GET_ALL_SHAREHOLDERS_AMOUNT, {
    skip: selectedTab !== 1,  
  });
  useEffect(() => {
    if (selectedTab === 1) {
      refetchShareholders();
    }
  }, [selectedTab, refetchShareholders]);
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPagination = localStorage.getItem("GeneralLoanpaginationModel");
    return storedPagination
      ? JSON.parse(storedPagination)
      : { pageSize: 5, page: 0 };
  });

  useEffect(() => {
    localStorage.setItem("GeneralLoanpaginationModel", JSON.stringify(paginationModel));
    return () => {
      localStorage.removeItem("GeneralLoanpaginationModel");
    };
  }, [paginationModel]);

  const [latestAdvance, setLatestAdvance] = useState<PaymentInfo | null>(null);
const [latestExpense, setLatestExpense] = useState<PaymentInfo | null>(null);
useEffect(() => {
  if (dataPayments) {
    const filtered = dataPayments.getAllPayments.filter(
      (payment: Payment) =>
        payment.category_name === "Expenses" &&
        (payment.sub_category === "Advance" || payment.sub_category === "Furniture")
    );

    const totalAdvance = filtered
      .filter((payment: { sub_category: string; }) => payment.sub_category === "Advance")
      .reduce((total: number, payment: { amount: number }) => total + payment.amount, 0);

    const totalFurniture = filtered
      .filter((payment: { sub_category: string; }) => payment.sub_category === "Furniture")
      .reduce((total: number, payment: { amount: number }) => total + payment.amount, 0);

    const parseDate = (dateString: string) => {
      const [day, month, year] = dateString.split("/").map(Number);
      return new Date(year, month - 1, day); 
    };

    const latestAdvDate = filtered
      .filter((payment: { sub_category: string; }) => payment.sub_category === "Advance")
      .sort((a: { date: string; }, b: { date: string; }) => parseDate(b.date).getTime() - parseDate(a.date).getTime())[0]?.date || null;

    const latestExpDate = filtered
      .filter((payment: { sub_category: string; }) => payment.sub_category === "Furniture")
      .sort((a: { date: string; }, b: { date: string; }) => parseDate(b.date).getTime() - parseDate(a.date).getTime())[0]?.date || null;

    setLatestAdvance({
      totalAmount: totalAdvance,
      latestDate: latestAdvDate ? parseDate(latestAdvDate).toLocaleDateString("en-GB") : undefined, 
    });
    
    setLatestExpense({
      totalAmount: totalFurniture,
      latestDate: latestExpDate ? parseDate(latestExpDate).toLocaleDateString("en-GB") : undefined, 
    });
  }
}, [dataPayments, errorPayments]);

  useEffect(()=> {
    const storedTab = localStorage.getItem("selectedTab")
    if (storedTab) {
      setSelectedTab(parseInt(storedTab, 10));
    }
  },[])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedTab",newValue.toString());
  };

  const truncateText = (text: string, maxLength: number): string => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (dataShareholders) {
      const shareholderData =
        dataShareholders.getAllReceipt.filter(
          (item: any) => item.role_type === "Shareholder"
        ) || [];

      const groupedShareholderData = shareholderData.reduce(
        (acc: any, curr: any) => {
          const existingShareholder = acc.find(
            (item: any) => item.name === curr.name
          );

          if (existingShareholder) {
            existingShareholder.amount += curr.amount;
          } else {
            acc.push({ ...curr });
          }
          return acc;
        },
        []
      );

      const totalAmount = groupedShareholderData.reduce(
        (sum: number, item: any) => sum + item.amount,
        0
      );
      const lastUpdateDate = shareholderData.reduce(
        (latest: string, item: any) => {
          return new Date(item.date) > new Date(latest) ? item.date : latest;
        },
        "1970-01-01T00:00:00.000Z"
      );

      const finalDataPrepared = [
        {
          name: "Total Capital Account",
          amount: totalAmount,
          uptodate: `${new Date(lastUpdateDate).toLocaleDateString()}`,
          date: lastUpdateDate,
        },
        ...groupedShareholderData,
      ];

      setFinalData(finalDataPrepared);
    }
  }, [dataShareholders]);

  const renderCapitalAccount = (finalData: any) => {
    return (
      <Grid container spacing={2}>
        {finalData.map((item: any, index: any) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="auto"
              padding="24px 16px"
              gap="16px"
              border="1px solid #208BEE"
              borderRadius="16px"
              sx={{
                backgroundColor: "#F2FCFF",
                color: "#208BEE",
                "&:hover": {
                  borderColor: "#208BEE",
                  opacity: 0.9,
                },
              }}
            >
              <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
              >
                {item.name === "Total Capital Account" ? (
                  <BusinessCenterOutlinedIcon
                    sx={{ color: "#000000", marginRight: "8px" }}
                  />
                ) : (
                  <Box
                    component="img"
                    src={homepeople}
                    alt="Home Icon"
                    sx={{
                      color: "#000000",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                )}
                <Typography
                  variant="h4"
                  color="#1B2434"
                  fontSize={"16px"}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  fontWeight={"600"}
                  sx={{
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
              <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  variant="h3"
                  color="#1B2434"
                  fontSize={"20px"}
                  fontWeight={"800"}
                >
                  {item.amount}
                </Typography>
                <Typography
                  variant="h6"
                  color="#1B2434"
                  fontSize={"14px"}
                  fontWeight={"400"}
                >
                  Updated on <br /> {item.uptodate}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderCards = (latestAdvance: PaymentInfo | null, latestExpense: PaymentInfo | null) => {
    return (
      <Grid container spacing={2}>
        {latestAdvance && (
          <Grid item xs={12} sm={6} md={3} key="advance">
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="auto"
              minHeight="115px"
              padding="24px 16px"
              gap="16px"
              border="1px solid #208BEE"
              borderRadius="16px"
              sx={{
                backgroundColor: "#F2FCFF",
                color: "#208BEE",
                "&:hover": {
                  borderColor: "#208BEE",
                  opacity: 0.9,
                },
              }}
            >
              <Typography
                variant="h4"
                color="#1B2434"
                fontSize={"16px"}
                fontWeight={"600"}
                sx={{
                  textAlign: "left",
                  width: "100%",
                }}
              >
                Advance
              </Typography>
              <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  variant="h3"
                  color="#1B2434"
                  fontSize={"20px"}
                  fontWeight={"800"}
                >
                  {latestAdvance.totalAmount}
                </Typography>
                <Typography
                  variant="h6"
                  color="#1B2434"
                  fontSize={"14px"}
                  fontWeight={"400"}
                >
                  Added on <br /> {latestAdvance.latestDate}
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
  
        {latestExpense && (
          <Grid item xs={12} sm={6} md={3} key="furniture">
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="auto"
              minHeight="115px"
              padding="24px 16px"
              gap="16px"
              border="1px solid #208BEE"
              borderRadius="16px"
              sx={{
                backgroundColor: "#F2FCFF",
                color: "#208BEE",
                "&:hover": {
                  borderColor: "#208BEE",
                  opacity: 0.9,
                },
              }}
            >
              <Typography
                variant="h4"
                color="#1B2434"
                fontSize={"16px"}
                fontWeight={"600"}
                sx={{
                  textAlign: "left",
                  width: "100%",
                }}
              >
                Furniture
              </Typography>
              <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  variant="h3"
                  color="#1B2434"
                  fontSize={"20px"}
                  fontWeight={"800"}
                >
                  {latestExpense.totalAmount}
                </Typography>
                <Typography
                  variant="h6"
                  color="#1B2434"
                  fontSize={"14px"}
                  fontWeight={"400"}
                >
                  Added on <br /> {latestExpense.latestDate}
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return <LoanScreen />
      case 1:
        return  renderCapitalAccount(finalData);
      case 2:
        return <InterestPaid/>
      case 3:
        return <InterestReceived/>
      case 4:
        return renderCards(latestAdvance, latestExpense);
      case 5:
        return <DepositorScreen />
      default:
        return <Typography>Select a tab to view content</Typography>;
    }
  };
  return (
    <Box style={{ padding: "15px" }}>
      <PheonixPaper
        style={{ borderRadius: "5px", height: "60px", marginBottom: "20px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            padding: isMobileSmall ? "15px 10px" : "10px 30px",
          }}
        >
          <Typography
            style={{
              color: themestyle.colors.black,
              fontWeight: themestyle.fontWeight.sm,
              fontSize: isMobileSmall
                ? themestyle.fontSizes.sm
                : themestyle.fontSizes.medium,
            }}
          >
            {TEXT_MESSAGES.GENERAL_LEDGER}
          </Typography>
        </div>
      </PheonixPaper>
      <PheonixPaper style={{ borderRadius: "5px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            padding: isMobileSmall ? "15px 10px" : "10px 30px",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="tabs"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: { backgroundColor: "primary", height: "3px" },
            }}
            sx={{
              width: "100%",
              "& .MuiTab-root": {
                flexGrow: 1,
                maxWidth: "none",
              },
            }}
          >
            {[
              "Loan",
              "Capital Account",
              "Interest Paid",
              "Interest Received",
              "Asset",
              "Depositor Ledger",
            ].map((label, index) => (
              <Tab
                key={label}
                label={label}
                sx={{
                  color: "black",
                  fontSize: "18px",
                  fontWeight: "bold",
                  textTransform: "none",
                  "&.Mui-selected": {
                    color: "black",
                  },
                }}
              />
            ))}
          </Tabs>
        </div>
      </PheonixPaper>
      
{selectedTab === 2 || selectedTab === 3 ?
     ( <div
        style={{
          borderRadius: "5px",
          height: "60vh",
        }}
      >
        {renderTabContent()}
      </div>):(
        <PheonixPaper
        style={{
          borderRadius: "5px",
          padding: "15px",
          overflowX: "auto",
          overflowY: "auto",
          height: "55vh",
          marginTop:"20px"
        }}
      >
        {renderTabContent()}
      </PheonixPaper>
      )}
    </Box>
  );
};
export default Generalledger;
