import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import PheonixPaper from "../Components/PheonixPaper";
import { VoucherFormData } from "../Interfaces/VoucherFormdataProps";
import {
  GET_DAILY_LOAN_HISTORY,
  GET_DAILY_LOAN_HISTORY_PER_PERSON,
} from "../graphql/queries";
import PheonixTable from "../Components/PheonixTable";
import themestyle from "../theme";
import { useNavigate, useLocation } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import backarrow from "../assets/backarrow.svg";
import { TEXT_MESSAGES } from "../constant";
import DetailRow from "./DetailsRow";
interface BorrowerDetails {
  borrower_name: string;
  borrower_id: string;
  address1: string;
  address2: string;
  loan_type: string;
  loan_amt: string;
  phoneNUmber: string;
  pending_amt: string;
  interest_amount: string;
  date: string;
  endDate: string;
  dailyAmount: string;
  days: string;
  occupation:  string;
  secondaryNumber: string;
}
const DailyLoanDetails: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [tableData, setTableData] = useState<VoucherFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [dataLoading, setdataLoading] = useState(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [LoanNo, setLoanNo] = useState<number | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const location = useLocation();
  useEffect(() => {
    if (location.state?.loan_no) {
      setLoanNo(location.state.loan_no);
    }
  }, [location.state]);
  const [borrowerdetails, setBorrowerdetails] = useState<BorrowerDetails[]>([]);
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      loanHistoryRefetch({ loan_no: LoanNo, searchQuery: null });
    } else {
      loanHistoryRefetch({ loan_no: LoanNo, searchQuery: newQuery });
    }
  };
  const [borrowerData, setBorrowerData] = useState<BorrowerDetails | null>(
    null
  );

  const {
    loading: loanHistoryLoadings,
    error: loanHistoryErrors,
    data: loanHistoryDatas,
    refetch: loanHistoryRefetchs,
  } = useQuery(GET_DAILY_LOAN_HISTORY_PER_PERSON, {
    variables: { loan_no: LoanNo },
    skip: LoanNo === null || isNaN(LoanNo),
    onCompleted: (data) => {
      const borrowerHistory = data.getAllBorrowerhistoryByLoan;

      const borrowerData: BorrowerDetails = {
        borrower_name: borrowerHistory.borrower_name,
        borrower_id: borrowerHistory.borrower_id,
        address1: borrowerHistory.address1,
        address2: borrowerHistory.address2,
        loan_type: borrowerHistory.loan_type,
        loan_amt: borrowerHistory.loan_amt,
        phoneNUmber: borrowerHistory.phoneNUmber,
        pending_amt: borrowerHistory.pending_amt,
        interest_amount: borrowerHistory.interest_amount,
        date: borrowerHistory.date,
        endDate: borrowerHistory.endDate,
        dailyAmount: borrowerHistory.dailyAmount,
        days: borrowerHistory.days,
        occupation: borrowerHistory.occupation,
        secondaryNumber:  borrowerHistory.secondaryNumber,
      };

      setBorrowerData(borrowerData);
    },
  });



  const {
    loading: loanHistoryLoading,
    error: loanHistoryError,
    data: loanHistoryData,
    refetch: loanHistoryRefetch,
  } = useQuery(GET_DAILY_LOAN_HISTORY, {
    variables: { loan_no: LoanNo, searchQuery },
    skip: LoanNo === null,
    onCompleted: (data) => {
      if (data && data.getAllCollectionhistoryByLoan) {
        const { collections, receipts } = data.getAllCollectionhistoryByLoan;

        const parseDate = (dateStr: string) => {
          const [day, month, year] = dateStr.split("/").map(Number);
          return new Date(year, month - 1, day);
        };
  
        const reversedCollections = collections.slice().reverse();
        const collectionRows = reversedCollections.map(
          (
            item: {
              id: any;
              collection_no: any;
              date: any;
              amount: any;
              voucherDetails: {
                interestAmount: any;
                collectedAmount: any;
                remainingAmount: any;
                totalAmountofcollectionandremaining: any;
                totalcollectedamount: any;
              };
            },
            index: number
          ) => ({
            id: item.id,
            serial_no: (index + 1).toString(),
            date: parseDate(item.date),
            dates: item.date,
            receipt_no: item.collection_no,
            amount: item.amount,
            remainingAmount: item.voucherDetails.remainingAmount,
            totalcollectedamount: item.voucherDetails.totalcollectedamount,
            interestamount: 0,
          })
        );
  
        const receiptRows = receipts.map(
          (
            receipt: {
              id: any;
              receipt_no: any;
              loan_no: any;
              date: any;
              amount: any;
              remarks: any;
              voucherDetails: {
                interestAmount: any;
                collectedAmount: any;
                remainingAmount: any;
                totalAmountofcollectionandremaining: any;
                totalcollectedamount: any;
              };
            },
            index: number
          ) => ({
            id: receipt.id,
            serial_no: (index + 1).toString(),
            receipt_no: receipt.receipt_no,
            loan_no: receipt.loan_no,
            dates:receipt.date,
            date: parseDate(receipt.date),
            amount: 0,
            interestamount: receipt.amount ?? 0,
            remarks: receipt.remarks,
            remainingAmount: receipt.voucherDetails.remainingAmount,
            totalcollectedamount: receipt.voucherDetails.totalcollectedamount,
          })
        );
  
        const combinedRows = [
          ...collectionRows,
          ...receiptRows,
        ];

        combinedRows.sort((a, b) => b.date - a.date);
        const finalRows = combinedRows.map((item, index) => ({
          ...item,
          serial_no: (index + 1).toString(), 
        }));
  
        setTableData(finalRows); 
        setTableCount(finalRows.length);
      }
    },
  });

  useEffect(() => {
  if(LoanNo){
  loanHistoryRefetch();
   }
  }, [ LoanNo,loanHistoryRefetch]);

  useEffect(() => {
    if (LoanNo !== null) {
      setColumns([
        { field: "serial_no", headerName: "S.No", width: 100 },
        { field: "dates", headerName: "Date", flex: 1, minWidth: 150 },
        {
          field: "receipt_no",
          headerName: "Receipt No",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "interestamount",
          headerName: "Interest Amount",
          flex: 1,
          minWidth: 200,
        },
        { field: "amount", headerName: "Collection Amount", flex: 1, minWidth: 200 },
        {
          field: "totalcollectedamount",
          headerName: "Total Collected Amount",
          flex: 2,
          minWidth: 200,
        },
        {
          field: "remainingAmount",
          headerName: "Loan Outstanding Amount",
          flex: 2,
          minWidth: 200,
        },
      ]);
    }
  }, [LoanNo]);

  const handleBack = () => {
    navigate("/loanledger", { state: { tab: 1 } });
  };

  return (
    <Box style={{ padding: "15px" }}>
      {isWideScreen ? (
        <PheonixPaper
          style={{
            borderRadius: "10px",
            height: "70px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color: themestyle.colors.black,
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {`${TEXT_MESSAGES.LOAN_DETAILS_HEADER} - ${LoanNo}`}
            </Typography>
            <div>
              <img src={backarrow} alt="arrow" />
              <Button
                variant="text"
                onClick={handleBack}
                style={{
                  marginBottom: "10px",
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#208BEE",
                  textDecoration: "underline",
                }}
              >
                {TEXT_MESSAGES.DAILY_BACK_BUTTON}
              </Button>
            </div>
          </div>
        </PheonixPaper>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            padding: isMobileSmall ? "1px " : "15px 30px",
          }}
        >
          <Typography
            style={{
              color: themestyle.colors.black,
              marginRight: "10px",
              fontWeight: themestyle.fontWeight.sm,
              fontSize: isMobileSmall
                ? themestyle.fontSizes.sm
                : themestyle.fontSizes.medium,
            }}
          >
            {`${TEXT_MESSAGES.LOAN_DETAILS_HEADER} - ${LoanNo}`}
          </Typography>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img
              src={backarrow}
              alt="arrow"
              style={{
                width: "16px",
                height: "16px",
              }}
            />
            <Button
              variant="text"
              onClick={handleBack}
              style={{
                marginBottom: "10px",
                textTransform: "none",
                fontSize: "13px",
                fontWeight: 500,
                color: "#208BEE",
                textDecoration: "underline",
              }}
            >
              {TEXT_MESSAGES.DAILY_BACK_BUTTON}
            </Button>
          </div>
        </div>
      )}
      <Box
        style={{
          padding: isMobileSmall ? "10px" : "0px 20px 20px 0px",
          borderRadius: "8px",
          maxHeight: isMobileSmall ? "65vh" : isTablet ? "60vh" : "65vh",
          overflowX: "hidden",
          overflowY: "auto",
          backgroundColor: themestyle.colors.white,
        }}
      >
        <Box
          style={{
            padding: "20px",
            backgroundColor: themestyle.colors.white,
          }}
        >
          {borrowerData && (
            <Grid container spacing={2}>
               <DetailRow
                label="Loan No"
                value={LoanNo || ''}
              />
              <DetailRow
                label="Borrower Name"
                value={borrowerData.borrower_name}
              />
              <DetailRow label="Borrower ID" value={borrowerData.borrower_id} />
              <DetailRow
                label="Address"
                value={borrowerData.address1 || "N/A"}
              />
              <DetailRow label="Occupation" value={borrowerData.occupation  || "N/A"} />
              <DetailRow label="Loan Type" value={borrowerData.loan_type} />
              <DetailRow
                label="Phone Number"
                value={borrowerData.phoneNUmber || "N/A"}
              />
               <DetailRow
                label="Secondary Number"
                value={borrowerData.secondaryNumber || "N/A"}
              />
              <DetailRow label="Loan Amount" value={borrowerData.loan_amt} />
              <DetailRow
                label="Interest Amount"
                value={borrowerData.interest_amount}
              />
              <DetailRow label="Start Date" value={borrowerData.date} />
              <DetailRow label="End Date" value={borrowerData.endDate} />
              <DetailRow
                label="Daily Amount"
                value={borrowerData.dailyAmount}
              />
              <DetailRow label="Days" value={borrowerData.days} />
            </Grid>
          )}
        </Box>
        <PheonixTable
          columns={columns}
          rows={tableData}
          rowCount={tableCount}
          paginationMode="server"
          paginationModel={paginationModel}
          loading={loanHistoryLoadings}
          searchQuery={searchQuery}
          onSearchQueryChange={handleSearchQueryChange}
        />
      </Box>
    </Box>
  );
};
export default DailyLoanDetails;
