import React from 'react';
import { Box, Typography, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, useTheme, useMediaQuery } from '@mui/material';
import PheonixPaper from '../Components/PheonixPaper';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from "dayjs";
import { Collection_msg } from '../constant';
import { ReceiptProps, DataRow } from '../Interfaces/CollectionModalFormData';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import dfinancelogo from "../assets/df33.png";
import dicon from "../assets/dicon.png"
import calandericon from "../assets/calendar-number-outline.png"
import logo from "../assets/Dlogo.svg"
function createData(name: string, value: string | number): DataRow {
  return { name, value };
}
const CollectionReceipt: React.FC<ReceiptProps> = ({
  receiptNumber,
  loanNumbers,
  totalAmount,
  selectedDate,
  setSelectedDate,
  handleDateChange, DownloadReceipt,isOpen
}) => {
  const rows: DataRow[] = [
    createData('Collection Receipt Number', receiptNumber?receiptNumber:0),
    createData("Loan No's", loanNumbers ? loanNumbers.join(', '):""),
    createData('Total Amount Collected', totalAmount),
  ];
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobileSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const height = isMobileSmall
  ? "135vh"
  : isTablet
  ? "135vh"
  : "65vh";
  const today = dayjs();
  const twoDaysAgo = dayjs().subtract(2, 'day');
  const handleDownload = () => {
    const doc = new jsPDF();
    const pngImage = new Image();
    pngImage.src = logo;
    pngImage.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = pngImage.width;
      canvas.height = pngImage.height;
      if (ctx) {
        ctx.drawImage(pngImage, 0, 0, canvas.width, canvas.height);
      }
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdfImageWidth = 50;
      const pdfImageHeight = (pngImage.height / pngImage.width) * pdfImageWidth;
 doc.addImage(imgData, 'PNG', 14, 10, pdfImageWidth, pdfImageHeight);
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(14);
      doc.text("Loan Collection Statement", 130, 20);
      const iconImage = new Image();
      iconImage.src = dicon;
      const marginBottom = 10;
      const nextElementY = 35 + marginBottom;
      iconImage.onload = () => {
        doc.addImage(iconImage, 'PNG', 90, nextElementY - 5, 6, 7);
        doc.setLineWidth(0.5);
        doc.line(14, 35, 196, 35);
        doc.setFontSize(14);
        doc.setFontSize(14);
        doc.setFont('Helvetica', 'Bold');
        doc.text(`Daily Loan`, 100, nextElementY);
        doc.setFont("Helvetica", "normal");
        doc.text("Date: ", 14, 52);
        doc.setFont("Helvetica", "bold");
        doc.text(dayjs(selectedDate).format('D MMM YYYY'), 28, 52);
        doc.setFont("Helvetica", "normal");
        doc.text("Collection Receipt No: ", 14, 62);
        doc.setFont("Helvetica", "bold");
        doc.text(`${receiptNumber}`, 65, 62);
        doc.setFont("Helvetica", "normal");
        doc.text("Total Amount: ", 155, 53);
        doc.setFont("Helvetica", "bold");
        doc.text(totalAmount.toLocaleString(), 155, 63);
        const tableColumn: string[] = ["Borrower Loan No", "Name", "Amount"];
        const tableRows: (string | number)[][] = [];
        DownloadReceipt && DownloadReceipt.details.forEach(item => {
          const rowData: (string | number)[] = [
            item.loan_no,
            item.name,
            item.amount.toLocaleString()
          ];
          tableRows.push(rowData);
        });
        (doc as any).autoTable({
          startY: 70,
          head: [tableColumn],
          body: tableRows,
          theme: 'plain',
          styles: {
            halign: 'left',
            font: 'Helvetica',
            fontStyle: 'normal',
            textColor: [0, 0, 0],
            fontSize: 12,
            fillColor: [240, 240, 240]
          },
          alternateRowStyles: {
            fillColor: [255, 255, 255]
          },
          headStyles: {
            fillColor: [240, 240, 240],
            font: 'Helvetica',
            fontStyle: 'Bold',
            textColor: [0, 0, 0],
            fontSize: 14
          },
          tableLineColor: [255, 255, 255],
          tableLineWidth: 0,
        });
        doc.save(Collection_msg.collection_receipt);
      };
    };
  };
  return (
    <Box
      sx={{
        borderRadius: '0 20px 20px 0',
        width: isTablet? '80%':'100%',
        height: "65vh",
        maxWidth: '500px',
        margin: '0px 0',
      }}
    >
      <PheonixPaper style={{ padding: "20px", height,width:isTablet ? (isOpen ? '72.5vh' : '98vh') : ''}}>
        <Typography variant="h6" gutterBottom align='center' style={{ fontWeight: "bold" }}>
          {Collection_msg.Collection_Receipt}
        </Typography>
        <div style={{ marginBottom: "40px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label={Collection_msg.DATE}
                format="DD/MM/YYYY"
                value={selectedDate}
                onChange={handleDateChange}
                minDate={twoDaysAgo}
                maxDate={today}
                slotProps={{
                  field: {
                    readOnly: true
                  }
                }}
                sx={{ width: '100%', marginBottom: '40px' }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        {!selectedDate ? (
          <div style={{ textAlign: "center" }}>
            <img
              src={calandericon}
              alt="calendar icon"
              style={{ display: "block", margin: "0 auto" }}
            />
            <Typography variant="body1" align='center' style={{ marginTop: "10px", fontFamily: "Inter" }} >
              {Collection_msg.SELECT_DATE}
            </Typography>
            <Typography variant="body1" align='center' style={{ fontFamily: "Inter" }} >
              {Collection_msg.SELECT_DATE1}
            </Typography>
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: isMobileSmall ? 100 : 300 }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {selectedDate && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                marginTop: isMobileSmall ? "20px" : '40px',
              }}
              onClick={handleDownload}
            >
              {Collection_msg.Download}
            </Button>
          </div>
        )}
      </PheonixPaper>
    </Box>
  );
};
export default CollectionReceipt;