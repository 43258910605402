import React, { ReactNode, CSSProperties } from 'react';
import Paper from '@mui/material/Paper';
import { PheonixPaperProps } from "../Interfaces/PheonixPaperProps";

const PheonixPaper: React.FC<PheonixPaperProps> = ({
  children,
  elevation,
  padding,
  height,
  marginLeft,
  marginRight,
  marginTop,
  width,
  borderRadius,
  style,
}) => {
  return (
    <Paper
      elevation={elevation}
      style={{
        padding,
        height,
        marginLeft,
        marginRight,
        marginTop,
        width,
        borderRadius,
        ...style,
      }}
    >
      {children}
    </Paper>
  );
};
export default PheonixPaper;