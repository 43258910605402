import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Box,
  IconButton,
  Typography,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextFieldProps } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { TEXT_MESSAGES } from "../constant";
import themestyle from "../theme";
import CollectionModalProps, {
  CollectionModalFormData,
} from "../Interfaces/CollectionModalFormData";
const CollectionModal: React.FC<CollectionModalProps> = ({
  open,
  onClose,
  onAdd,
  fields,
  formData,
  setFormData,
  fieldErrors,
  setFieldErrors,
  title,
  editable,
  selectedDate,
  setSelectedDate,
  refetchBorrowerName,
  borrowerName = [],
  handleLoanNoChange,
  loanNos = [],
}) => {
  const requiredFields = [
    "Loan No",
    "Amount",
  ] as (keyof CollectionModalFormData)[];
  const today = dayjs();
  const twoDaysAgo = dayjs().subtract(2, "day");
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [initialFormData, setInitialFormData] =
    useState<CollectionModalFormData>({
      ...formData,
    });
  useEffect(() => {
    if (open) {
      const updatedFormData = { ...formData };
      setInitialFormData(updatedFormData);
      setFormData(updatedFormData);
    }
  }, [open]);

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => error);
    const requiredFields: (keyof CollectionModalFormData)[] = [
      "Loan No",
      "Amount",
    ];
    const hasEmptyRequiredFields = requiredFields.some((key) => !formData[key]);
    const normalizeData = (data: CollectionModalFormData) => ({
      ...data,
      Amount:
        typeof data.Amount === "string" ? parseFloat(data.Amount) : data.Amount,
      Date: data.Date || "",
    });

    const initialNormalizedData = normalizeData(initialFormData);
    const currentNormalizedData = normalizeData({ ...formData });
    const isFormDataChanged =
      JSON.stringify(initialNormalizedData) !==
      JSON.stringify(currentNormalizedData);

    setIsConfirmDisabled(
      hasErrors || hasEmptyRequiredFields || !isFormDataChanged
    );
  }, [formData, fieldErrors, requiredFields, initialFormData]);

  const handleChange = (
    label: keyof CollectionModalFormData,
    value: string
  ) => {
    setFormData((prevData) => ({ ...prevData, [label]: value }));
    validateField(label, value);
  };
  const handleDateChange = (newDate: Dayjs | null) => {
    if (setSelectedDate) {
      setSelectedDate(newDate);
    }
    const formattedDate = newDate ? newDate.format("DD-MM-YYYY") : "";
    handleChange("Date", formattedDate);
  };
  const handleBlur = (label: keyof CollectionModalFormData) => {
    const value = formData[label];
    validateField(label, value);
  };

  const validateField = (
    label: keyof CollectionModalFormData,
    value: string
  ) => {
    let error = "";
    if (!value && requiredFields.includes(label)) {
      error = `${label} is required`;
    } else if (label === "Amount" && value) {
      if (/[a-zA-Z]/.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR1;
      } else if (/\./.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR3;
      } else if (!/^\d+(\d{1,2})?$/.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR2;
      }
    } else {
      error = "";
    }

    setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: error }));
  };
  const borrowerDisplay = borrowerName
    .map((borrower) => `${borrower.borrower_id} - ${borrower.borrower_name}`)
    .join(", ");
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h4"
            style={{
              fontWeight: 700,
              fontSize: "24px",
              fontFamily: "Inter",
            }}
          >
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="form">
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid
                item
                xs={12}
                sm={
                  field.label.includes("Borrower Name") ||
                  field.label.includes("Amount")
                    ? 12
                    : 6
                }
                key={index}
              >
                {field.label === "Loan No" ? (
                  <Autocomplete
                    options={loanNos}
                    getOptionLabel={(option) => option}
                    value={formData["Loan No"] || ""}
                    onChange={(event, newValue) => {
                      handleChange("Loan No", newValue || "");
                    }}
                    onBlur={() => handleBlur("Loan No")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Loan No *"
                        variant="outlined"
                        error={!!fieldErrors["Loan No"]}
                        helperText={fieldErrors["Loan No"]}
                        style={{ marginTop: "8px" }}
                        
                      />
                    )}
                    disabled={editable}
                  />
                ) : field.label === "Date" ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="Date"
                        format="DD/MM/YYYY"
                        value={selectedDate}
                        onChange={handleDateChange}
                        minDate={twoDaysAgo}
                        maxDate={today}
                        disabled={editable}
                        slotProps={{
                          field: {
                            readOnly: true,
                          },
                        }}
                        sx={{
                          width: "100%",
                          marginBottom: "40px",
                          "& .MuiInputBase-root": { minWidth: "unset" },
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":{
                            color:'black'
                          },
                          "& .Mui-error": {
                            color: "#bfbfbf",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-error fieldset": {
                              borderColor: "inherit",
                            },
                          },
                          "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error":{
                            color: "#bfbfbf",
                          },     
                                             
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                ) : field.label === "Borrower Name" ? (
                  <TextField
                    fullWidth
                    label={`${field.label} *`}
                    value={borrowerDisplay}
                    onChange={(e) =>
                      handleChange(
                        field.label as keyof CollectionModalFormData,
                        e.target.value
                      )
                    }
                    onBlur={() =>
                      handleBlur(field.label as keyof CollectionModalFormData)
                    }
                    error={
                      !!fieldErrors[
                        field.label as keyof CollectionModalFormData
                      ]
                    }
                    helperText={
                      fieldErrors[field.label as keyof CollectionModalFormData]
                    }
                    variant="outlined"
                    style={{ marginTop: "5px" }}
                    InputLabelProps={{ shrink: !!formData["Loan No"] }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <TextField
                    fullWidth
                    label={`${field.label} *`}
                    value={
                      formData[
                        field.label as keyof CollectionModalFormData
                      ] as string
                    }
                    onChange={(e) =>
                      handleChange(
                        field.label as keyof CollectionModalFormData,
                        e.target.value
                      )
                    }
                    onBlur={() =>
                      handleBlur(field.label as keyof CollectionModalFormData)
                    }
                    error={
                      !!fieldErrors[
                        field.label as keyof CollectionModalFormData
                      ]
                    }
                    helperText={
                      fieldErrors[field.label as keyof CollectionModalFormData]
                    }
                    variant="outlined"
                    style={{ marginTop: "5px" }}
                    InputProps={{
                      readOnly: editable,
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{
            backgroundColor: !editable
              ? themestyle.colors.white
              : themestyle.colors.primary,
            color: !editable
              ? themestyle.colors.primary
              : themestyle.colors.white,
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            marginBottom: "15px",
            marginRight: "15px",
            padding: "6px 16px",
            width: "76px",
            height: "36px",
          }}
        >
          {TEXT_MESSAGES.CLOSE}
        </Button>
        {!editable && (
          <Button
            onClick={onAdd}
            disabled={isConfirmDisabled}
            style={{
              backgroundColor: isConfirmDisabled ? "lightgray" : "#208BEE",
              color: "#ffffff",
              fontWeight: 500,
              textTransform: "uppercase",
              fontSize: "14px",
              marginBottom: "15px",
              marginRight: "15px",
              padding: "6px 16px",
              width: "76px",
              height: "36px",
            }}
            variant="contained"
          >
            {TEXT_MESSAGES.SAVE}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default CollectionModal;
